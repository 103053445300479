import * as Application from 'expo-application';
import { VStack, Center, Button, Image, Text } from 'native-base';

import { useAuth } from '~/hooks/useAuth';

const logo = require('../../../assets/logo/app_logo_chumly.png');

export const LoginScreen: React.FC = () => {
  const { handleLogin } = useAuth();

  return (
    <Center height="100%" backgroundColor="white">
      <VStack alignItems="center" space={2}>
        <Image source={logo} width={128} height={128} alt="プロフィール画像" />
        <Button variant="link" onPress={handleLogin}>
          Chumly IDでログイン
        </Button>
        <Text fontSize="2xs" color="gray.500">
          {Application.applicationName} {Application.nativeApplicationVersion}({Application.nativeBuildVersion})
        </Text>
      </VStack>
    </Center>
  );
};
