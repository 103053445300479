import { useLinkTo, useNavigation } from '@react-navigation/native';
import { HStack, VStack, Text, Box } from 'native-base';
import { Pressable, Platform } from 'react-native';

import { useStreamChatContext } from '~/contexts/StreamChatContext';

import { ChatCustomChannelAvatar } from './ChatCustomChannelAvatar';

interface Props {
  channel: object;
}

export const ChannelSearchResultItem: React.FC<Props> = ({ channel }) => {
  const navigation = useNavigation();
  const { setAppChannel } = useStreamChatContext();
  const linkTo = useLinkTo();
  return (
    <Pressable
      onPress={() => {
        if (Platform.OS === 'web') {
          // @ts-expect-error TS(2339): Property 'data' does not exist on type 'object'.
          if (channel.data?.isDM) {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('DMNavigator', {
              screen: 'DirectMessageList',
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
              params: { channelId: channel.id },
            });
          } else {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
            linkTo(`/chat/${channel.id}`);
          }
        } else {
          // @ts-expect-error TS(2345): Argument of type 'object' is not assignable to par... Remove this comment to see the full error message
          setAppChannel(channel);
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', { channelId: channel.id });
        }
      }}
    >
      <VStack space={2} margin={2}>
        <HStack space={2}>
          <Text fontWeight={500} color="gray.400">
            {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'object'. */}
            {channel.data?.member_count}名のメンバー
          </Text>
        </HStack>
        <HStack space={2}>
          <VStack space={1}>
            <HStack w="310">
              <Box padding={2}>
                <ChatCustomChannelAvatar channel={channel} />
              </Box>
              <VStack>
                <Text fontSize="md" adjustsFontSizeToFit padding={1}>
                  {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'object'. */}
                  {channel.data?.name}
                </Text>
                <Text fontSize="xs" adjustsFontSizeToFit padding={1}>
                  {/* @ts-expect-error TS(2339): Property 'state' does not exist on type 'object'. */}
                  {channel.state.messages.length > 0 && channel.state.messages[channel.state.messages.length - 1].text}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};
