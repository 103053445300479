import { HStack, VStack, Text, Badge, Image, Flex } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

interface Props {
  eventLabel: string;
  authorLabel: string;
  description: string;
  dateLabel: string;
  isFirst?: boolean;
  isLast?: boolean;
  avatarUrl?: string;
  onPress?: () => void;
}

export const EventTimelineItem: React.FC<Props & React.PropsWithChildren> = ({
  description,
  dateLabel,
  isFirst,
  isLast,
  eventLabel,
  authorLabel,
  avatarUrl,
  onPress,
  children,
}) => {
  return (
    <HStack flex={1} space={3}>
      <Flex direction="row">
        <Text minWidth={10} textAlign="right" paddingTop={2}>
          {dateLabel}
        </Text>
      </Flex>
      <Flex direction="row">
        <VStack alignItems="center">
          <Flex direction="column" width={1} height={2} backgroundColor={isFirst ? null : 'blue.500'} />
          <Flex direction="column" width={6} height={6} backgroundColor="blue.500" borderRadius={60} />
          <Flex
            direction="column"
            width={1}
            height="100%"
            backgroundColor={isLast ? null : 'blue.500'}
            flexDirection="column"
          />
        </VStack>
      </Flex>
      <Flex direction="row">
        <VStack flexShrink={1}>
          <TouchableOpacity onPress={onPress}>
            <VStack flex={1} paddingTop={2} paddingBottom={4} space={3}>
              <HStack space={4} alignContent="center">
                <Badge variant="outline" borderRadius={10}>
                  {eventLabel}
                </Badge>
              </HStack>
              <HStack space={1} alignItems="center" paddingLeft={1}>
                {avatarUrl ? <Image source={{ uri: avatarUrl }} size="2xs" borderRadius={20} alt="" /> : null}
                <Text flexShrink={4} fontSize="xs">
                  {authorLabel}
                </Text>
              </HStack>
              {description ? <Text paddingLeft={1}>{`${description}`}</Text> : null}
              {children}
            </VStack>
          </TouchableOpacity>
        </VStack>
      </Flex>
    </HStack>
  );
};
