import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { HStack, Text, Button, Icon, Spinner, Box } from 'native-base';
import React, { useMemo, useState } from 'react';

import { usePostAppointmentsSlotsMutation, useDeleteAppointmentsSlotsByIdMutation } from '~/api/uFeedApi';
import { useCustomToast } from '~/hooks';

interface Props {
  slot: {
    id: number;
    date: string;
    start_at: string;
    status: string;
    booked_at: any | null;
    appointment_service: any | null;
    provider: {
      id: number;
      name: string;
      avatar: any | null;
      account: {
        id: number;
        name: string;
      };
      appointment_services: {
        id: number;
        name: string;
        description: string;
        duration_minutes: number;
      }[];
    };
    booked_by: any | null;
  };
}

const LoadingIcon = <Spinner />;
const HappyIcon = <Icon as={Ionicons} name="happy-outline" fontSize="2xl" color="green.600" />;

const isEditableSlot = (slot: any) => {
  const startAt = new Date(slot.date + ' ' + slot.start_at);
  const now = new Date();

  return startAt.valueOf() > now.valueOf();
};

export const MyAppointmentSlotListItem: React.FC<Props> = ({ slot }) => {
  const [post, { isLoading, isSuccess, reset: resetPost }] = usePostAppointmentsSlotsMutation();
  const [destroy, { isLoading: isDestroying, isSuccess: isDestroySuccess, reset: resetDestroy }] =
    useDeleteAppointmentsSlotsByIdMutation();
  const { show } = useCustomToast();
  const [errorMessages, setErrorMessages] = useState<string[] | []>([]);

  const isEditable = useMemo(() => isEditableSlot(slot), [slot]);

  useDidUpdate(() => {
    resetPost();
    resetDestroy();
  }, [slot.id]);

  const onEmptySlotPress = () => {
    post({
      body: {
        appointment_slot: {
          start_at: `${slot.date} ${slot.start_at}`,
        },
      },
    })
      .unwrap()
      .catch((error) => {
        const errorMessage = `予約枠の作成に失敗しました: ${slot.start_at}`;
        setErrorMessages([errorMessage]);
        show(errorMessage);
        console.error(errorMessage, error);
      });
  };

  const onDeleteSlotPress = (id: number) => {
    destroy({ id })
      .unwrap()
      .catch((error) => {
        const errorMessage = `予約枠の削除に問題が発生しました`;
        setErrorMessages([errorMessage]);
        show(errorMessage);
        console.error(errorMessage, error);
      });
  };

  return (
    <HStack alignItems="center" space={6} testID="MyAppointmentSlotListItem">
      <Box width="65">
        <Text bold fontSize="lg" textAlign="right" color="gray.400">
          {slot.start_at}
        </Text>
      </Box>
      {isLoading || isDestroying ? (
        <Button variant="unstyled" leftIcon={LoadingIcon}>
          変更中
        </Button>
      ) : isSuccess || isDestroySuccess ? (
        <Button leftIcon={HappyIcon} variant="unstyled">
          <Text color="green.600">完了</Text>
        </Button>
      ) : slot.id ? (
        <HStack alignItems="center" justifyContent="space-around" space={2}>
          <Box paddingX={4} paddingY={2} borderRadius={5} backgroundColor="#fbe25c">
            <Text bold>予約可能</Text>
          </Box>
          {isEditable ? (
            <Button
              variant="link"
              onPress={() => onDeleteSlotPress(slot.id)}
              borderRadius={40}
              testID="MyAppointmentSlotListItemCancel"
            >
              取り消す
            </Button>
          ) : (
            <Text paddingX={4} color="gray.400">
              変更できません{slot.date}
            </Text>
          )}
        </HStack>
      ) : (
        <HStack alignItems="center" justifyContent="space-around" space={2}>
          <Box paddingX={4} paddingY={2} borderRadius={5} backgroundColor="#f4f4f4">
            <Text bold>予約不可</Text>
          </Box>
          {isEditable ? (
            <Button variant="link" onPress={onEmptySlotPress} borderRadius={40} testID="MyAppointmentSlotListItemapply">
              予約可能にする
            </Button>
          ) : (
            <Text paddingX={4} color="gray.400">
              変更できません
            </Text>
          )}
        </HStack>
      )}
    </HStack>
  );
};
