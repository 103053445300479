import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { useRoute, useLinkTo } from '@react-navigation/native';
import { parse } from 'date-fns';
import { Icon } from 'native-base';
import * as React from 'react';
import { useState, useMemo } from 'react';
import { CalendarProvider, ExpandableCalendar, CalendarUtils } from 'react-native-calendars';

import { useGetAppointmentsSlotsQuery } from '~/api/uFeedApi';
import { MyAppointmentSlotList } from '~/components/MyAppointmentSlotList';
import { DateUtil } from '~/utils/DateUtils';
import { generateTimeArray } from '~/utils/generateTimeArray';

const getDate = (date: any) => {
  return CalendarUtils.getCalendarDateString(date ? parse(date, 'yyyyMMdd', new Date()) : new Date());
};

const createSlots = (queryData: any, date: any) => {
  const slotsByStartAt = queryData.reduce((acc: any, cur: any) => {
    const start_at = DateUtil.tohmm(new Date(cur?.start_at));

    return {
      ...acc,
      [start_at]: cur,
      date,
    };
  }, {});

  return generateTimeArray(7, 19, 30).map((time) =>
    slotsByStartAt[time]
      ? {
          ...slotsByStartAt[time],
          start_at: time,
          date,
        }
      : {
          id: undefined,
          start_at: time,
          date,
        }
  );
};

const renderArrow = (direction: any) => {
  return direction === 'left' ? (
    <Icon as={Ionicons} name="chevron-back-outline" fontSize="2xl" />
  ) : (
    <Icon as={Ionicons} name="chevron-forward-outline" fontSize="2xl" />
  );
};

export const MyAppointmentScheduleListScreen: React.FC = () => {
  const route = useRoute();
  // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
  const [currentDate, setCurrentDate] = useState(getDate(route?.params?.date));
  // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
  const initialDate = useMemo(() => getDate(), []);
  const linkTo = useLinkTo();

  useDidUpdate(() => {
    // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
    setCurrentDate(getDate(route?.params?.date));
    // @ts-expect-error TS(2339): Property 'date' does not exist on type 'object'.
  }, [route?.params?.date]);

  const getAppointmentsSlotsQuery = useGetAppointmentsSlotsQuery();

  const filterSlotsByDate = (slot: any) => {
    const dateStr = DateUtil.toYYYYMMDD(new Date(slot.start_at), '-');
    return dateStr === currentDate;
  };

  const slots = useMemo(
    () => createSlots(getAppointmentsSlotsQuery.data?.filter(filterSlotsByDate) || [], currentDate),
    [getAppointmentsSlotsQuery.data, currentDate]
  );

  const onDayPress = React.useCallback((day: any) => {
    linkTo(`/provider/appointments/schedules/${day.dateString.replaceAll('-', '')}`);
  }, []);

  return (
    <CalendarProvider date={initialDate}>
      <ExpandableCalendar
        firstDay={1}
        renderArrow={renderArrow}
        disableArrowLeft={false}
        disableArrowRight={false}
        markedDates={{
          [currentDate]: {
            selected: true,
          },
        }}
        onDayPress={onDayPress}
      />
      <MyAppointmentSlotList slots={slots} padding={4} backgroundColor="white" />
    </CalendarProvider>
  );
};
