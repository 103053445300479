import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import {
  useGetAdditivesByAdditiveIdQuery,
  useDeleteAdditivesByAdditiveIdMutation,
  useGetCurrentUserQuery,
  useGetGroupsByGroupIdQuery,
} from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

const { useState } = React;

export const AdditiveDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const { chatClient } = useStreamChatAuthContext();
  const [chatChannelId, setChatChannelId] = useState(undefined);

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams?.groupId;
  // @ts-expect-error TS(2339): Property 'additiveId' does not exist on type '{}'.
  const queryResult = useGetAdditivesByAdditiveIdQuery({ additiveId: queryParams?.additiveId });
  const group = useGetGroupsByGroupIdQuery({ groupId });
  const userQueryResult = useGetCurrentUserQuery({});
  const [deleteAdditive, deleteAdditiveResult] = useDeleteAdditivesByAdditiveIdMutation();

  useDidUpdate(
    () => {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      group.data && setChatChannelId(group.data?.chat_channel_id);
    },
    [group],
    true
  );

  return (
    <BaseScreenBuilder>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetAdditi... Remove this comment to see the full error message
        queryResult={queryResult}
        emptyMessage="添加剤追加履歴がありません"
        onEditPress={
          userQueryResult.data?.id === queryResult.data?.user?.id
            ? () =>
                Platform.OS === 'web'
                  ? linkTo(
                      // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                      `/farms/${queryParams.farmId}/groups/${queryParams.groupId}/additives/${queryParams.additiveId}/edit`
                    )
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('AdditiveEdit', { additiveId: queryParams?.additiveId })
            : undefined
        }
        onSharePress={async () => {
          const channel = chatClient?.channel('team', chatChannelId);
          // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
          const message = `${U_FEED_URL_BASE}/farms/${queryParams?.farmId}/groups/${queryParams?.groupId}/additives/${queryParams?.additiveId}`;
          if (Platform.OS === 'web') {
            linkTo(`/chat/${channel.id}?message=${encodeURIComponent(message)}`);
          } else {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('ChatChannelMessages', { channelId: channel.id, message });
          }
        }}
        // @ts-expect-error TS(2322): Type '(() => MutationActionCreatorResult<MutationD... Remove this comment to see the full error message
        onDeletePress={
          userQueryResult.data?.id === queryResult.data?.user?.id
            ? () => {
                return deleteAdditive({
                  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                  additiveId: queryResult.data?.id,
                });
              }
            : undefined
        }
        fields={[
          {
            key: 'memo',
            label: '内容',
          },
          {
            key: 'date',
            type: 'date',
            label: '追加日',
          },
          {
            key: 'user',
            type: 'user',
            label: '担当者',
          },
        ]}
      />
    </BaseScreenBuilder>
  );
};
