import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useToast, Skeleton } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import {
  usePostAppointmentsServicesMutation,
  usePatchAppointmentsServicesByIdMutation,
  useGetAppointmentsServicesByIdQuery,
} from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

const fields = [
  {
    key: 'name',
    label: 'タイトル',
    rules: {
      required: {
        value: true,
        message: 'サービスのタイトルを入力してください',
      },
    },
  },
  {
    key: 'description',
    type: 'textarea',
    label: '説明',
    rules: {
      required: {
        value: true,
        message: '説明を入力してください',
      },
    },
  },
  {
    key: 'notification_text',
    type: 'textarea',
    label: '開始通知メッセージ',
    rules: {},
  },
];

export const MyAppointmentServiceCreateEditScreen: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const [selected, setSelected] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [post, { isLoading: isPostLoading, error: postError }] = usePostAppointmentsServicesMutation();
  const [patch, { isLoading: isPatchLoading, error: patchError }] = usePatchAppointmentsServicesByIdMutation();

  const route = useRoute();
  const { show } = useToast();
  // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
  const isPost = !route?.params?.id;
  const appointmentServiceQuery = useGetAppointmentsServicesByIdQuery(
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
    route?.params?.id ? { id: route?.params?.id } : skipToken
  );

  const onCancel = () => {
    navigation.goBack();
  };
  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    const { assigned_user_id, content, deadline } = sendingData;
    const { name, description, duration_minutes, notification_text } = sendingData;

    if (isPost) {
      post({
        body: {
          appointment_service: {
            name,
            description,
            duration_minutes: 30,
            notification_text,
          },
        },
      })
        .unwrap()
        .then((resp) => {
          navigation.goBack();
        })
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
        });
    } else {
      patch({
        id: sendingData.id,
        body: {
          appointment_service: {
            name,
            description,
            duration_minutes,
            notification_text,
          },
        },
      })
        .unwrap()
        .then((resp) => {
          navigation.goBack();
        })
        .catch((error) => {
          console.error('patch failed', error);
          setErrorMessages(errorMessageBuilder(error, fields));
        });
    }
  };

  if (!isPost && appointmentServiceQuery?.isLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (!isPost && appointmentServiceQuery?.isError) {
    return <BaseScreenBuilder isError errorMessage="存在しないサービスです" />;
  }

  return (
    <BaseScreenBuilder title="サービス作成">
      <BaseFormBuilder
        isUpdating={isPostLoading || isPatchLoading}
        error={false}
        defaultValues={
          isPost
            ? {}
            : {
                id: appointmentServiceQuery?.data?.id,
                name: appointmentServiceQuery?.data?.name,
                description: appointmentServiceQuery?.data?.description,
                duration_minutes: appointmentServiceQuery?.data?.duration_minutes,
              }
        }
        errorMessage={errorMessages}
        // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
        fields={fields}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </BaseScreenBuilder>
  );
};
