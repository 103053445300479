import { Ionicons } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Button, Divider, VStack, Icon } from 'native-base';
import * as React from 'react';

import { ItemList, ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

interface Data {
  label: string;
  value: string;
}

type ParamList = {
  Detail: {
    data: Data[];
    value: string;
    onConfirm: (result: string) => void;
    onCancel: () => void;
  };
};

export const SelectScreen: React.FC = () => {
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<ParamList, 'Detail'>>();

  return (
    <ScreenWidthModal title="選択">
      <BaseScreenBuilder title="選択">
        <VStack minHeight={220} space={2}>
          <ItemList>
            {params?.data?.map((item, i) => (
              <ListItem
                key={i}
                title={item.label}
                onPress={() => {
                  params?.onConfirm(item.value);
                  navigation.goBack();
                }}
                right={
                  params?.value == item.value ? <Icon as={Ionicons} name="checkmark-outline" size="md" /> : undefined
                }
              />
            ))}
          </ItemList>
          <Divider my={2} />
          <Button
            variant="outline"
            onPress={() => {
              navigation.goBack();
            }}
          >
            キャンセル
          </Button>
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
