import { Ionicons } from '@expo/vector-icons';
import { useRoute, useNavigation, useIsFocused } from '@react-navigation/native';
import { Fab, Icon } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdVisitsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const VisitsListScreen: React.FC = () => {
  const { params } = useRoute();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  //const queryResult = useGetGroupsByGroupIdVisitsQuery(queryParams);
  const groupId = queryParams.groupId;
  const queryResult = useGetGroupsByGroupIdVisitsQuery({ groupId });

  return (
    <BaseScreenBuilder title="">
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetGroups... Remove this comment to see the full error message
        queryResult={queryResult}
        getTitle={(data) => data.date}
        onSelect={(item) =>
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('VisitsDetail', {
            queryParams: { groupId, visitsId: item.id },
          })
        }
        emptyMessage="訪問履歴データがありません"
      />

      {isFocused ? (
        <Fab
          onPress={() =>
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('VisitsCreate', {
              queryParams,
            })
          }
          placement="bottom-right"
          bottom={Platform.OS === 'web' ? 5 : 120}
          right={5}
          size="lg"
          backgroundColor="#0EC9E5"
          icon={<Icon as={Ionicons} name="add-outline" backgroundColor="#0EC9E5" size="md" />}
        />
      ) : null}
    </BaseScreenBuilder>
  );
};
