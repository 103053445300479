import { HStack, Text } from 'native-base';

interface Props {
  label?: string;
  isRequired?: boolean;
}

export const FormFieldLabel: React.FC<Props> = ({ isRequired, label }) => {
  return (
    <HStack space={2} alignItems="center">
      {label ? (
        <Text fontSize="sm" fontWeight="medium" color="gray.500">
          {label}
        </Text>
      ) : null}
      {isRequired ? (
        <Text fontSize="md" fontWeight="medium" color="red.400">
          *
        </Text>
      ) : null}
    </HStack>
  );
};
