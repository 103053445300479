const regexPatterns = {
  additivesList: /^\/farms\/\d+\/groups\/\d+\/additives$/,
  additiveDetail: /^\/farms\/\d+\/groups\/\d+\/additives\/\d+$/,
  taskList: /^\/farms\/\d+\/groups\/\d+\/tasks$/,
  taskDetail: /^\/farms\/\d+\/groups\/\d+\/tasks\/\d+$/,
  appliedFeedDesignList: /^\/farms\/\d+\/groups\/\d+\/applied_feed_designs$/,
  appliedFeedDesignDetail: /^\/farms\/\d+\/groups\/\d+\/applied_feed_designs\/\d+$/,
  visitsList: /^\/farms\/\d+\/groups\/\d+\/visits$/,
  visitsDetail: /^\/farms\/\d+\/groups\/\d+\/visits\/\d+$/,
  reminderList: /^\/farms\/\d+\/groups\/\d+\/reminders$/,
  reminderDetail: /^\/farms\/\d+\/groups\/\d+\/reminders\/\d+$/,
  groupFeedDesignList: /^\/farms\/\d+\/groups\/\d+\/group_feed_designs$/,
  groupFeedDesignDetail: /^\/farms\/\d+\/groups\/\d+\/group_feed_designs\/\d+$/,
  groupList: /^\/farms\/\d+\/groups$/,
  groupDetail: /^\/farms\/\d+\/groups\/\d+$/,
};

export const generateNavigateParameterFromPath = (path: string | undefined) => {
  const pathArray = path?.split('/');
  let screenName = '';
  let params = {};

  //https://github.com/u-motion/u-feed-app/blob/main/docs/specs/UrlScheme.md
  switch (true) {
    // 添加剤一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.additivesList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'AdditiveList';
      break;
    //添加剤詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.additiveDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4], additiveId: pathArray[6] };
      screenName = 'AdditiveDetail';
      break;
    // タスク一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.taskList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'TaskList';
      break;
    //タスク詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.taskDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4], id: pathArray[6] };
      screenName = 'TaskDetail';
      break;
    // 飼料適用一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.appliedFeedDesignList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'AppliedFeedDesignList';
      break;
    //　飼料適用詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.appliedFeedDesignDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4], id: pathArray[6] };
      screenName = 'AppliedFeedDesignDetail';
      break;
    // 訪問履歴一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.visitsList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'VisitsList';
      break;
    //　訪問履歴詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.visitsDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4], visitsId: pathArray[6] };
      screenName = 'VisitsDetail';
      break;
    // 申し送り一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.reminderList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'ReminderList';
      break;
    //　申し送り詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.reminderDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4], reminderId: pathArray[6] };
      screenName = 'ReminderDetail';
      break;
    // グループ飼料設計一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupFeedDesignList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'GroupFeedDesignList';
      break;
    //　グループ飼料設計詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupFeedDesignDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupFeedDesignId: pathArray[6] };
      screenName = 'GroupFeedDesignDetail';
      break;
    // グループ一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], farmId: pathArray[2] };
      screenName = 'GroupList';
      break;
    //　グループ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'GroupDetail';
      break;
    //　活動量グラフ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/graphs\/activity\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const queryParamStr = pathArray[9].indexOf('?') ? pathArray[9].substring(pathArray[9].indexOf('?') + 1) : '';
      const queryParams = queryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        range: queryParams.find((param) => param.key === 'range')?.value,
        type: queryParams.find((param) => param.key === 'type')?.value,
      };
      screenName = 'ActivityGraphs';

      break;
    //　搾乳量グラフ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/graphs\/milk_amount\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const milkAmountQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const milkAmountQueryParams = milkAmountQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        range: milkAmountQueryParams.find((param) => param.key === 'range')?.value,
        type: milkAmountQueryParams.find((param) => param.key === 'type')?.value,
      };
      screenName = 'MilkAmountGraphs';

      break;
    //　活動量ダッシュボード詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/dashboards\/activity\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const activityDashboardsQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const activityDashboardsQueryParams = activityDashboardsQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        target: activityDashboardsQueryParams.find((param) => param.key === 'target')?.value,
      };
      screenName = 'ActivityDashboard';

      break;
    //　搾乳量ダッシュボード詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/dashboards\/milk_amount\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const milkAmoutDashboardsQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const milkAmoutDashboardsQueryParams = milkAmoutDashboardsQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        target: milkAmoutDashboardsQueryParams.find((param) => param.key === 'target')?.value,
      };
      screenName = 'MilkAmoutDashboard';

      break;
    //　飼料設計一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/feed_designs$/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2] };
      screenName = 'FeedDesignList';
      break;
    //　飼料設計詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/feed_designs\/\d+$/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], feedDesignId: pathArray[4] };
      screenName = 'FeedDesignDetail';
      break;
    //　企業詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/accounts\/\d+$/.test(path):
      params = {};
      screenName = 'AccountDetail';
      break;
    // チャットチャンネル画面
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/(chat|dm)\/[0-9a-zA-Z\-_]+\/[0-9a-zA-Z\-_]+$/.test(path):
      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        channelId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        messageId: pathArray[3],
      };
      screenName = 'ChatChannelMessages';
      break;
    // チャンネルのみのURL
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/(chat|dm)\/[0-9a-zA-Z\-_]+$/.test(path):
      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        channelId: pathArray[2],
      };
      screenName = 'ChatChannelMessages';
      break;
    default:
      break;
  }

  return { screenName, params };
};
