import { VStack, Text } from 'native-base';
import React from 'react';

import { useGetGroupsByGroupIdRemindersAndReminderIdQuery } from '~/api/uFeedApi';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

interface Props {
  groupId: number;
  reminderId: number;
}

export const PreviewReminder: React.FC<Props> = React.memo(({ groupId, reminderId }) => {
  const reminder = useGetGroupsByGroupIdRemindersAndReminderIdQuery({ groupId, reminderId });

  if (reminder.isLoading || reminder.isError) {
    return null;
  }

  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="gray.100"
      overflow="hidden"
    >
      <Text marginBottom={2}>申し送り</Text>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '({ data?: undefined; error?: undefined; stat... Remove this comment to see the full error message
        queryResult={reminder}
        emptyMessage="申し送りのデータがありません"
        space={2}
        fields={[
          {
            key: 'memo',
            label: '内容',
          },
          {
            key: 'user',
            type: 'user',
            label: '登録者',
          },
        ]}
      />
    </VStack>
  );
});
