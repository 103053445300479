import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import { Text, Button, HStack, Box, Icon, Spinner } from 'native-base';
import React, { useState } from 'react';

import {
  usePatchAppointmentsByAppointmentSlotIdMutation,
  usePatchAppointmentsByAppointmentSlotIdCancelMutation,
} from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { APPLICABLE_SLOT_OFFSET_MS } from '~/constants';
import { useCustomToast } from '~/hooks';

interface Slot {
  id: number;
  date: string;
  start_at: string;
  status: string;
  booked_at: any | null;
  appointment_service: any | null;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
    appointment_services: {
      id: number;
      name: string;
      description: string;
      duration_minutes: number;
    }[];
  };
  booked_by: any | null;
}
interface Props {
  slot: Slot;
}

const isApplicableSlot = (slot: Slot) => {
  const startAt = new Date(slot.date + ' ' + slot.start_at);
  const now = new Date();

  return startAt.valueOf() > now.valueOf() + APPLICABLE_SLOT_OFFSET_MS;
};

export const AppointmentSlotListItem: React.FC<Props> = ({ slot }) => {
  const route = useRoute();
  const [patch, { isLoading, isSuccess, reset: resetPatch }] = usePatchAppointmentsByAppointmentSlotIdMutation();
  const [cancel, { isLoading: isLoadingCancel, isSuccess: isCancelSuccess, reset: resetCancel }] =
    usePatchAppointmentsByAppointmentSlotIdCancelMutation();

  const { show } = useCustomToast();
  const [errorMessages, setErrorMessages] = useState<string[] | []>([]);

  useDidUpdate(() => {
    resetPatch();
    resetCancel();
  }, [slot]);

  const onApplyPress = (appointmentSlotId: number) => {
    Alert.alert('確認', `予約を行います`, [
      {
        text: 'キャンセル',
      },
      {
        text: 'OK',
        onPress: async () => {
          patch({
            appointmentSlotId,
            body: {
              // @ts-expect-error TS(2339): Property 'serviceId' does not exist on type 'objec... Remove this comment to see the full error message
              appointment_service_id: route?.params?.serviceId,
            },
          })
            .unwrap()
            .then(() => resetPatch())
            .catch((error) => {
              const errorMessage = `予約枠の作成に失敗しました: ${slot.start_at}`;
              setErrorMessages([errorMessage]);
              show(errorMessage);
              console.error(errorMessage, error);
            });
        },
      },
    ]);
  };

  const onCancelPress = (appointmentSlotId: number) => {
    Alert.alert('確認', `予約をキャンセルします`, [
      {
        text: 'キャンセル',
      },
      {
        text: 'OK',
        onPress: async () => {
          cancel({
            appointmentSlotId,
          })
            .unwrap()
            .then(() => resetCancel())
            .catch((error) => {
              const errorMessage = `予約のキャンセルに失敗しました: ${slot.start_at}`;
              setErrorMessages([errorMessage]);
              show(errorMessage);
              console.error(errorMessage, error);
            });
        },
      },
    ]);
  };

  return (
    <HStack alignItems="center" space={6}>
      <Box width="65">
        <Text bold fontSize="lg" textAlign="right" color="gray.400">
          {slot?.start_at}
        </Text>
      </Box>
      {isLoading || isLoadingCancel ? (
        <Button variant="unstyled" leftIcon={<Spinner />}>
          変更中
        </Button>
      ) : isSuccess || isCancelSuccess ? (
        <Button
          leftIcon={<Icon as={Ionicons} name="happy-outline" fontSize="2xl" color="green.600" />}
          variant="unstyled"
        >
          <Text color="green.600">完了</Text>
        </Button>
      ) : slot?.id && slot?.status === 'booked' ? (
        <HStack alignItems="center" justifyContent="space-around" space={2}>
          <Box paddingX={4} paddingY={2} borderRadius={5} backgroundColor="red.500">
            <Text bold>予約済み</Text>
          </Box>
          <Button variant="link" onPress={() => onCancelPress(slot.id)} borderRadius={40}>
            キャンセルする
          </Button>
        </HStack>
      ) : slot?.id && isApplicableSlot(slot) ? (
        <HStack alignItems="center" justifyContent="space-around" space={2}>
          <Box paddingX={4} paddingY={2} borderRadius={5} backgroundColor="#fbe25c">
            <Text bold>予約可能</Text>
          </Box>
          <Button variant="link" onPress={() => onApplyPress(slot?.id)} borderRadius={40}>
            申し込む
          </Button>
        </HStack>
      ) : (
        <HStack alignItems="center" justifyContent="space-around" space={2}>
          <Box paddingX={4} paddingY={2} borderRadius={5} backgroundColor="#f4f4f4">
            <Text bold>予約不可</Text>
          </Box>
        </HStack>
      )}
    </HStack>
  );
};
