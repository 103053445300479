import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Application from 'expo-application';
import { useTheme, ScrollView, VStack, Button, HStack, Box, Center, Flex, Text } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { StatusBar, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useAuth } from '~/hooks/useAuth';
import { phoneLoginSelector } from '~/slices/phoneLoginSlice';
import { useAppSelector } from '~/store';

import { LogoImageDefault } from './LogoImageDefault';
import { UmotionEmailLoginScreen } from './UmotionEmailLoginScreen';
import { UmotionPhoneLoginScreen } from './UmotionPhoneLoginScreen';

export const UmotionLoginModalScreen = () => {
  const [loginMethod, setLoginMethod] = useState(
    Platform.OS !== 'web' ? undefined : localStorage.getItem('LastLoginMethodStorageKey')
  );
  const theme = useTheme();
  const { handleLogin } = useAuth();
  const { isSmsSent } = useAppSelector(phoneLoginSelector);

  const onPhoneLoginPress = useCallback(() => {
    setLoginMethod('phone');
  }, []);

  const onEmailLoginPress = useCallback(() => {
    setLoginMethod('email');
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const init = async () => {
        const lastLoginMethod = await AsyncStorage.getItem('LastLoginMethodStorageKey');
        if (lastLoginMethod === 'phone' || lastLoginMethod === 'email') {
          setLoginMethod(lastLoginMethod);
        } else {
          setLoginMethod('phone');
        }
      };

      init();
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        // @ts-expect-error TS(2339): Property 'dark' does not exist on type 'ITheme'.
        backgroundColor: theme?.dark ? 'black' : 'white',
      }}
    >
      {/* @ts-expect-error TS(2786): 'StatusBar' cannot be used as a JSX component. */}
      <StatusBar barStyle={theme?.dark ? 'light-content' : 'dark-content'} />
      <ScrollView keyboardShouldPersistTaps="handled" flex={1}>
        <Flex paddingX={4} paddingY={8}>
          <Center>
            <LogoImageDefault />
          </Center>

          <Center>
            <Box background="#f6f6f6" padding={4} flex={1} maxWidth={360}>
              <Center>
                <VStack space={8}>
                  {isSmsSent ? null : (
                    <HStack space={2}>
                      <Button
                        variant="unstyled"
                        borderBottomColor="#005fff"
                        borderBottomWidth={loginMethod === 'phone' ? 2 : 0}
                        flexShrink={1}
                        onPress={onPhoneLoginPress}
                        width="50%"
                      >
                        電話番号でログイン
                      </Button>
                      <Button
                        variant="unstyled"
                        borderBottomColor="#005fff"
                        borderBottomWidth={loginMethod === 'email' ? 2 : 0}
                        flexShrink={1}
                        onPress={onEmailLoginPress}
                        width="50%"
                      >
                        メールでログイン
                      </Button>
                    </HStack>
                  )}

                  {loginMethod === 'email' ? <UmotionEmailLoginScreen /> : <UmotionPhoneLoginScreen />}
                </VStack>
              </Center>
            </Box>
          </Center>
        </Flex>
        {isSmsSent ? null : (
          <VStack space={8} justifyItems="center" paddingX={4}>
            <Center>
              <Button variant="outline" onPress={handleLogin} borderRadius={30} width={300}>
                Chumly IDをお持ちの方はこちら
              </Button>
            </Center>
            {Platform.OS === 'web' ? null : (
              <Center>
                {/* @ts-expect-error TS(2322): Type '{ color: { 50: string; 100: string; 200: str... Remove this comment to see the full error message */}
                <Text style={{ color: theme?.colors?.text || 'black' }}>
                  {`${
                    Application.applicationName
                  }: ${`${Application.nativeApplicationVersion}(${Application.nativeBuildVersion})`}`}
                </Text>
              </Center>
            )}
          </VStack>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};
