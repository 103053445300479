import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Box, HStack, Text, Skeleton, Icon, VStack, Button, StyledProps } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';

import { GraphAlertBox } from '../GraphAlertBox';

import { useGetFarmsByFarmIdUmCattleGroupsQuery, useGetGroupsByGroupIdMilkingsStatisticQuery } from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useGroupMilkAmountByRange } from '~/hooks/useGroupMilkAmountByRange';
import { DateUtil } from '~/utils/DateUtils';

import { MilkAmountPanel } from './MilkAmountPanel';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
}

export const MilkAmountDashboard: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    queryParameters,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    onTypeChange,
    ...props
  }) => {
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    const { chatClient } = useStreamChatAuthContext();

    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    const { milkingsByGroup, milkingsByGroupMA } = useGroupMilkAmountByRange(groupId, endDate, range);
    const milkingsStatisticQuery = useGetGroupsByGroupIdMilkingsStatisticQuery(
      groupId
        ? {
            groupId,
          }
        : skipToken
    );
    const [cowGroupNames, setCowGroupNames] = useState({});
    const cowGroupsQuery = useGetFarmsByFarmIdUmCattleGroupsQuery({
      // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      farmId,
    });

    useDidUpdate(
      () => {
        setCowGroupNames(
          cowGroupsQuery.data?.reduce((acc, cur) => {
            return {
              ...acc,
              // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
              [cur?.id]: cur?.name,
            };
          }, {}) ?? {}
        );
      },
      [cowGroupsQuery],
      true
    );

    if (milkingsByGroup?.isLoading || milkingsByGroupMA?.isLoading || milkingsStatisticQuery?.isLoading) {
      return <Skeleton height={height} padding={4} />;
    }

    if (milkingsByGroup?.isError || milkingsByGroupMA?.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkingsByGroup.refetch();
            milkingsByGroupMA.refetch();
          }}
        />
      );
    }

    const data = milkingsByGroup.data?.map((byGroup, index) => ({
      // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
      label: cowGroupNames ? cowGroupNames[byGroup?.um_cattle_group_id] : '',
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      amount: byGroup.data.slice(-1)[0].amount,
      um_cattle_group_id: byGroup?.um_cattle_group_id,
    }));

    const dataMA = milkingsByGroupMA.data?.reduce(
      (acc, byGroup) => ({
        ...acc,
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
        [byGroup?.um_cattle_group_id]: {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          amount: byGroup.data.slice(-1)[0].amount,
        },
      }),
      {}
    );

    const lastMilking = milkingsByGroup.data?.[0]?.data?.slice(-1)?.[0];

    function chunk<T extends any[]>(arr: T, size: number) {
      if (!arr) {
        return [];
      }
      return arr.reduce((newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]), [] as T[][]);
    }

    return (
      <Box paddingX="auto" paddingBottom={4} {...props}>
        <VStack>
          {/* @ts-expect-error TS(2345): Argument of type '{ label: any; amount: number | u... Remove this comment to see the full error message */}
          {chunk(data, 4)?.map((outerChunks: any) => (
            <HStack
              key={`outer-milk-chunk-${outerChunks?.[0]?.um_cattle_group_id}`}
              flexWrap="wrap"
              mr={{ base: -2, md: -5 }}
            >
              {chunk(outerChunks, 2)?.map((innerChunks: any, index: any) => (
                <HStack
                  key={`inner-mink-chunk-${innerChunks?.[0]?.um_cattle_group_id ?? index}`}
                  flex={1}
                  space={{ base: 2, md: 5 }}
                  minW={320}
                  mb={{ base: 2, md: 5 }}
                  mr={{ base: 2, md: 5 }}
                >
                  {innerChunks.map((d: any, innerIndex: any) => (
                    <MilkAmountPanel
                      key={(d.um_cattle_group_id ?? index.toString()) + innerIndex.toString()}
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      label={cowGroupNames ? cowGroupNames[d.um_cattle_group_id] : ''}
                      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                      targetAmount={dataMA[d.um_cattle_group_id].amount}
                      amount={d.amount}
                      isPreview={isPreview}
                    />
                  ))}
                  {innerChunks.length === 1 && <Box flex={1} px={3} />}
                </HStack>
              ))}
              {outerChunks.length <= 2 && <Box flex={1} minW={320} mr={{ base: 2, md: 5 }} />}
            </HStack>
          ))}
        </VStack>
        {!isPreview ? (
          <VStack space={2} marginTop={2}>
            <HStack space={1}>
              <Text bold>最終更新 </Text>
              <Text>{milkingsStatisticQuery?.data?.updated_at}</Text>
            </HStack>
          </VStack>
        ) : null}
        {!isPreview ? (
          <HStack mt={2} justifyContent="flex-end">
            <Button
              variant="unstyled"
              onPress={async () => {
                const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
                const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/dashboards/milk_amount/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?target=all`;
                const channel = chatClient?.channel('team', chatChannelId);
                if (Platform.OS === 'web') {
                  linkTo(`/chat/${channel?.id}?message=${encodeURIComponent(message)}`);
                } else {
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('ChatChannelMessages', {
                    channelId: channel.id,
                    message,
                  });
                }
              }}
              leftIcon={<Icon as={Ionicons} name="share-outline" size="md" />}
              testID="milkamount-dashboard-share-button"
            >
              <Text bold>チャットで共有</Text>
            </Button>
          </HStack>
        ) : null}
      </Box>
    );
  }
);
