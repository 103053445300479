import { useRichTextEditorContext } from '@mantine/tiptap';
import { Editor } from '@tiptap/react';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { UserResponse } from 'stream-chat';
import {
  DefaultSuggestionListHeader,
  DefaultSuggestionListItem,
  ThemeVersion,
  useChatContext,
  useComponentContext,
  useMessageInputContext,
} from 'stream-chat-react';
import { useYomi } from '~/hooks/useYomi';
import { StreamChatGenerics } from '~/types';
import { CustomSuggestionItem, CustomSuggestionListProps } from './types';

const autocompleteTriggers = {
  '@': 'mention',
  '＠': 'mention',
  ':': 'emoji',
} as const;
const triggerQueryRegex = () => {
  const triggerCharacters = Object.keys(autocompleteTriggers);
  return new RegExp(String.raw`([${triggerCharacters}])(\S*)$`);
};

const scrollToItem = (container?: HTMLElement, item?: HTMLElement) => {
  if (!item || !container) return;

  const itemHeight = parseInt(getComputedStyle(item).getPropertyValue('height'), 10);
  const containerHight = parseInt(getComputedStyle(container).getPropertyValue('height'), 10) - itemHeight;
  const actualScrollTop = container.scrollTop;
  const itemOffsetTop = item.offsetTop;

  if (itemOffsetTop < actualScrollTop + containerHight && actualScrollTop < itemOffsetTop) {
    return;
  }
  container.scrollTop = itemOffsetTop;
};

type Props = CustomSuggestionListProps & {
  themeVersion: ThemeVersion;
  editor: Editor | null;
  text: string;
  setText: (text: string) => void;
  onSelectUser: (user: UserResponse) => void;
  mentionedUsers: UserResponse[];
  closeMentionsList: () => void;
};

export const CustomSuggestionListWithContext = ({
  className,
  currentTrigger,
  getTextToReplace,
  Header: PropHeader,
  itemClassName,
  itemStyle,
  style,
  SuggestionItem: PropSuggestionItem,
  value: propValue,
  values,
  themeVersion,
  editor,
  text,
  onSelectUser,
  mentionedUsers,
  closeMentionsList,
}: Props) => {
  const { AutocompleteSuggestionHeader, AutocompleteSuggestionItem } = useComponentContext('SuggestionList');
  const SuggestionItem: any = PropSuggestionItem || AutocompleteSuggestionItem || DefaultSuggestionListItem;
  const SuggestionHeader: any = PropHeader || AutocompleteSuggestionHeader || DefaultSuggestionListHeader;
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const [calculating, setCalculating] = useState<boolean>(true);
  const [suggestionList, setSuggestionList] = useState<any[]>([]);
  const itemsRef: any[] = [];
  let containerRef: HTMLElement | undefined = undefined;
  const { emojiSearchIndex } = useMessageInputContext<StreamChatGenerics>('DefaultTriggerProvider');
  const { yomiList } = useYomi();

  const mouseHoverEnabled = useRef<boolean>(true);
  const lastHoveredValueIndex = useRef<number>(-1);

  const getSuggestionAttr = useCallback(() => {
    const textBeforeCursor = (editor?.state.selection.$anchor.nodeBefore?.text as string) || '';
    const match = textBeforeCursor.match(triggerQueryRegex());
    const triggerChar = (match ? match[1] : '') as keyof typeof autocompleteTriggers;
    const query = match ? match[2] : '';
    const suggestionType = autocompleteTriggers[triggerChar] ?? '';
    return { textBeforeCursor, match, query, suggestionType };
  }, [editor]);

  const { query, suggestionType } = getSuggestionAttr();

  const isSelected = useCallback(
    (item: CustomSuggestionItem) => {
      switch (suggestionType) {
        case 'mention':
          return selectedItemIndex === suggestionList.findIndex((value) => getId(value) === getId(item));
        case 'emoji':
          return selectedItemIndex === suggestionList.findIndex((emoji) => emoji.id === item.id);
        default:
          return false;
      }
    },
    [selectedItemIndex, suggestionList, suggestionType]
  );

  const getId = useCallback(
    (item: CustomSuggestionItem) => {
      const textToReplace = getTextToReplace(item);
      if (textToReplace.key) {
        return textToReplace.key;
      }
      if (typeof item === 'string' || !item.key) {
        return textToReplace.text;
      }
      return item.key;
    },
    [getTextToReplace]
  );

  const modifyText = useCallback(
    (selectedItemIndex: number) => {
      const { match, suggestionType } = getSuggestionAttr();
      if (!match) return;

      if (suggestionType === 'mention') {
        const selectedItem = suggestionList[selectedItemIndex];
        if (!selectedItem) return;
        const removedText = match[0] ?? '';
        const pos = editor?.state.selection.$anchor.pos ?? 0;
        const from = pos - removedText.length;
        const to = pos;
        editor
          ?.chain()
          .insertContentAt(
            { from, to },
            `<strong><span data-mention="${selectedItem.name}">@${selectedItem.name}</span></strong> `
          )
          .focus()
          .run();
      } else if (suggestionType === 'emoji') {
        const selectedItem = suggestionList[selectedItemIndex];
        if (!selectedItem) return;
        const cursorPosition = editor?.state.selection.$anchor.pos
          ? editor?.state.selection.$anchor.pos - match[0].length + 2
          : undefined;
        const emoji = selectedItem.skins?.[0].native;
        const html = editor?.getHTML() ?? '';
        const replaced = html.replace(match[0], emoji);
        editor?.commands.clearContent();
        editor?.chain().insertContent(replaced).focus(cursorPosition).run();
      }

      closeMentionsList();
    },
    [text, editor, closeMentionsList, suggestionList]
  );

  const attachYomiToUsers = useCallback(
    (values: CustomSuggestionItem[]) => {
      const yomiMapByChatId = yomiList.reduce((acc: any, yomi) => {
        if (!yomi.chatUserId) return acc;
        acc[yomi.chatUserId] = yomi;
        return acc;
      }, {});
      const res = values.map((value) => {
        const yomi = yomiMapByChatId[value.id];
        return {
          ...value,
          hiraYomi: yomi?.hiraYomi,
          kanaYomi: yomi?.kanaYomi,
        };
      });
      return res;
    },
    [yomiList]
  );

  useEffect(() => {
    const fn = async () => {
      mouseHoverEnabled.current = true;

      if (suggestionType === 'mention') {
        if (!query) {
          setSuggestionList(values);
        } else {
          const users = attachYomiToUsers(values);
          setSuggestionList(
            users.filter((user) => {
              // For case name is undefined, written with optional chain.
              return (
                user.name?.toLowerCase().includes(query.toLowerCase()) ||
                user.hiraYomi?.includes(query) ||
                user.kanaYomi?.includes(query)
              );
            })
          );
        }
      } else if (suggestionType === 'emoji') {
        const emojis = (await emojiSearchIndex?.search(query)) ?? [];
        // カスタム emoji が候補に入ってしまうケースがあるのでフィルタする
        // https://github.com/u-motion/u-motion-apps/issues/1739
        const list = emojis.filter((emoji) => !!emoji.skins?.[0].native);
        setSuggestionList(list);
      }
    };
    fn();
  }, [values, editor?.state, mentionedUsers, suggestionType, yomiList]);

  const handlePress = useCallback(
    (index: number) => {
      modifyText(index);
      setSelectedItemIndex(index);
      const mentionedUser = suggestionList[index];
      if (!mentionedUser) {
        return;
      }
      const isExist = mentionedUsers.some((user) => user?.id === mentionedUser?.id);
      // 重複しないように
      if (!isExist) {
        onSelectUser(mentionedUser);
      }
    },
    [modifyText, mentionedUsers]
  );

  const selectItem = useCallback(
    (item: CustomSuggestionItem) => {
      if (suggestionType === 'mention') {
        const index = suggestionList.findIndex((value) => (value.id ? value.id === item.id : value.name === item.name));
        setSelectedItemIndex(index);
      } else if (suggestionType === 'emoji') {
        const index = suggestionList.findIndex((emoji) => emoji.id === item.id);
        setSelectedItemIndex(index);
      } else {
        setSelectedItemIndex(null);
      }
    },
    [suggestionList, suggestionType]
  );

  const convertIdx = (prevIdx: number, prevList: CustomSuggestionItem[], nextList: CustomSuggestionItem[]) => {
    const item = prevList[prevIdx];
    return nextList.findIndex((value) => (value.id ? value.id === item?.id : value.name === item?.name));
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        setSelectedItemIndex((prevSelected) => {
          if (prevSelected == undefined) return 0;
          if (suggestionType === 'mention') {
            const prevSelectedSuggestion = convertIdx(prevSelected, suggestionList, suggestionList);
            const newID = prevSelectedSuggestion <= 0 ? suggestionList.length - 1 : prevSelectedSuggestion - 1;
            scrollToItem(containerRef, itemsRef[newID]);
            return convertIdx(newID, suggestionList, suggestionList);
          } else if (suggestionType === 'emoji') {
            const prevSelectedSuggestion = convertIdx(prevSelected, suggestionList, suggestionList);
            const newID = prevSelectedSuggestion <= 0 ? suggestionList.length - 1 : prevSelectedSuggestion - 1;
            scrollToItem(containerRef, itemsRef[newID]);
            return convertIdx(newID, suggestionList, suggestionList);
          } else {
            return 0;
          }
        });
        mouseHoverEnabled.current = false;
      }

      if (event.key === 'ArrowDown') {
        setSelectedItemIndex((prevSelected) => {
          if (prevSelected == undefined) return 0;
          if (suggestionType === 'mention') {
            const prevSelectedSuggestion = convertIdx(prevSelected, suggestionList, suggestionList);
            const newID = prevSelectedSuggestion >= suggestionList.length - 1 ? 0 : prevSelectedSuggestion + 1;
            scrollToItem(containerRef, itemsRef[newID]);
            return convertIdx(newID, suggestionList, suggestionList);
          } else if (suggestionType === 'emoji') {
            const prevSelectedSuggestion = convertIdx(prevSelected, suggestionList, suggestionList);
            const newID = prevSelectedSuggestion >= suggestionList.length - 1 ? 0 : prevSelectedSuggestion + 1;
            scrollToItem(containerRef, itemsRef[newID]);
            return convertIdx(newID, suggestionList, suggestionList);
          } else {
            return 0;
          }
        });
        mouseHoverEnabled.current = false;
      }

      if (event.key === 'Tab' && selectedItemIndex != null) {
        event.preventDefault();
        handlePress(selectedItemIndex);
        return;
      }

      if (event.key === 'Enter' && selectedItemIndex != null && editor) {
        if (!editor.state.doc.textContent.trim().length) {
          // content is cleared
          return;
        }

        // remove line break
        editor.commands.deleteRange({
          from: editor.state.selection.$anchor.pos - 2,
          to: editor.state.selection.$anchor.pos,
        });
        handlePress(selectedItemIndex);
      }

      return null;
    },
    [selectedItemIndex, suggestionList, editor, suggestionType]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (suggestionList?.length) {
      if (lastHoveredValueIndex.current != -1) {
        setSelectedItemIndex(lastHoveredValueIndex.current);
      } else {
        selectItem(suggestionList[0]);
      }
    }
  }, [suggestionList, setSelectedItemIndex]);

  const setDropdownRef = (element: HTMLUListElement | null) => {
    if (!element) return;
    containerRef = element;
  };

  useEffect(() => {
    const textareaRef = document.querySelector<HTMLElement>('.str-chat__message-textarea-react-host');
    const suggestionListContainerRef = document.querySelector<HTMLElement>('.str-chat__suggestion-list-container');

    // テキストエリアの高さが変更されたときに suggestion list の bottom を計算して設定する関数
    const adjustSuggestionListPosition = () => {
      if (!textareaRef || !suggestionListContainerRef) {
        return;
      }
      const parentHeight = textareaRef.offsetHeight;
      suggestionListContainerRef.style.bottom = `${parentHeight}px`;
      setCalculating(false);
    };

    // 初期化
    adjustSuggestionListPosition();

    // 親要素の高さが変更されたときに子要素の bottom を再計算する
    window.addEventListener('resize', adjustSuggestionListPosition);

    window.addEventListener('mousemove', () => {
      if (!mouseHoverEnabled.current) {
        mouseHoverEnabled.current = true;
        if (lastHoveredValueIndex.current != -1) {
          setSelectedItemIndex(lastHoveredValueIndex.current);
        }
      }
    });
  }, []);

  if (calculating) {
    return null;
  }

  return suggestionList.length ? (
    <ul
      className={clsx('rta__list', className)}
      style={{
        ...style,
        borderRadius: '10px',
        paddingTop: '10px',
        height: '35vh',
      }}
      ref={(element) => setDropdownRef(element)}
    >
      {themeVersion === '1' && (
        <li className="rta__list-header">
          <SuggestionHeader currentTrigger={currentTrigger} value={propValue} />
        </li>
      )}
      {suggestionList.map((item, i) => (
        <SuggestionItem
          className={itemClassName}
          item={item}
          key={item.id}
          onClickHandler={handlePress}
          onSelectHandler={selectItem}
          ref={(ref: any) => {
            itemsRef[i] = ref;
          }}
          selected={isSelected(item)}
          style={itemStyle}
          value={propValue}
          onPress={() => handlePress(i)}
          onHoverIn={() => {
            const idxInValues = suggestionType === 'mention' ? convertIdx(i, suggestionList, suggestionList) : i;
            if (mouseHoverEnabled.current) {
              setSelectedItemIndex(idxInValues);
            }
            lastHoveredValueIndex.current = idxInValues;
          }}
          onHoverOut={() => {
            lastHoveredValueIndex.current = -1;
          }}
          suggestionType={suggestionType}
        />
      ))}
    </ul>
  ) : null;
};

const MemorizedCustomSuggestionList = memo(CustomSuggestionListWithContext, (prev, next) => {
  // @ts-expect-error TS(2339): Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
  return prev.editor === next.editor && prev.text === next.text && prev.message === next.message;
});

export const CustomSuggestionList = (props: Props) => {
  const { themeVersion } = useChatContext('SuggestionList');

  const { editor } = useRichTextEditorContext();
  const { text, setText, onSelectUser, mentioned_users, closeMentionsList } =
    useMessageInputContext<StreamChatGenerics>('MessageInputV2');

  return (
    <MemorizedCustomSuggestionList
      {...props}
      themeVersion={themeVersion}
      editor={editor}
      text={text}
      setText={setText}
      onSelectUser={onSelectUser}
      mentionedUsers={mentioned_users}
      closeMentionsList={closeMentionsList}
    />
  );
};
