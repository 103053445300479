import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetAppliedFeedDesignsByIdQuery, usePutAppliedFeedDesignsByIdMutation } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { EditFormBuilder } from '~/components/builder/EditFormBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const AppliedFeedDesignEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  const queryResult = useGetAppliedFeedDesignsByIdQuery({ id: queryParams?.id });
  const [update, { isLoading: isUpdating, data }] = usePutAppliedFeedDesignsByIdMutation();

  useDidUpdate(() => {
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'AppliedFee... Remove this comment to see the full error message
    if (queryResult.data?.name) {
      navigation?.setOptions({
        // @ts-expect-error TS(2339): Property 'name' does not exist on type 'AppliedFee... Remove this comment to see the full error message
        title: queryResult.data?.name,
      });
    }
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'AppliedFee... Remove this comment to see the full error message
  }, [queryResult.data?.name]);

  return (
    <ScreenWidthModal>
      <BaseScreenBuilder>
        <EditFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetApplie... Remove this comment to see the full error message
          queryResult={queryResult}
          // @ts-expect-error TS(2322): Type 'MutationTrigger<MutationDefinition<PutApplie... Remove this comment to see the full error message
          update={update}
          updateResult={{ isUpdating, data }}
          emptyMessage="飼料設計適用履歴がありません"
          maximumDate={new Date()}
          fields={[
            {
              key: 'memo',
              label: '内容',
            },
          ]}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
