import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Skeleton, Alert, Heading, ScrollView } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { ActivityDashboard, SectionTitle } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { HeaderRight } from '~/components/HeaderRight';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useDateByRange } from '~/hooks/useDateByRange';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  groupId?: number;
  farmId?: number;
}

const { useState } = React;

export const ActivityDashboardScreen: React.FC<Props> = ({ groupId, farmId }) => {
  const { params } = useRoute();

  const navigation = useNavigation();
  const queryParams = params
    ? Platform.OS === 'web'
      ? // @ts-expect-error TS(2339): Property 'year' does not exist on type 'object'.
        { ...params, endDate: `${params.year}${params.month}${params.date}` }
      : { ...params }
    : {
        farmId,
        groupId,
      };

  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  const group = useGetGroupsByGroupIdQuery({ groupId: queryParams?.groupId });
  const [chatChannelId, setChatChannelId] = useState(undefined);

  const { beginDate, endDate, range, preEndDate, nextEndDate, setEndDate, setRange } = useDateByRange(
    queryParams.endDate ? DateUtil.dateHourToDate(queryParams.endDate) : new Date(),
    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{ endDate... Remove this comment to see the full error message
    Number(queryParams.range) || 30
  );

  navigation?.setOptions({
    headerLeft: () => <HeaderRight label="閉じる" onPress={() => navigation.goBack()} />,
  });

  useDidUpdate(
    () => {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      group.data && setChatChannelId(group.data?.chat_channel_id);
    },
    [group],
    true
  );

  if (group.isLoading) {
    return (
      <ScreenWidthModal>
        <BaseScreenBuilder>
          <Skeleton />
        </BaseScreenBuilder>
      </ScreenWidthModal>
    );
  } else if (group.isError) {
    return (
      <ScreenWidthModal>
        <BaseScreenBuilder>
          <Alert colorScheme="error">
            <Alert.Icon />
            {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'FetchBaseQ... Remove this comment to see the full error message */}
            {group.error.data.error}
          </Alert>
        </BaseScreenBuilder>
      </ScreenWidthModal>
    );
  }

  return (
    <ScreenWidthModal>
      <ScrollView>
        <Heading fontSize="xl" margin={4}>
          {group.data?.name}
        </Heading>
        <SectionTitle title="活動量の変化" padding={4} />
        <ActivityDashboard
          chatChannelId={chatChannelId}
          farmId={queryParams.farmId}
          groupId={queryParams.groupId}
          endDate={endDate}
          range={30}
          paddingX={4}
          paddingTop={0}
          paddingBottom={8}
        />
      </ScrollView>
    </ScreenWidthModal>
  );
};
