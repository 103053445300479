import data from '@emoji-mart/data';
import { Ionicons } from '@expo/vector-icons';
import { HStack, Text, Pressable, Image, Actionsheet, ScrollView, useDisclose, Tooltip } from 'native-base';
import { useId, useState, useMemo, useCallback, useEffect, memo } from 'react';
import { Route, SceneMap, TabBar, TabBarProps, TabView } from 'react-native-tab-view';
import { useMessageContext, useChannelStateContext } from 'stream-chat-react';

import { customEmojis } from '~/utils/emoji';

import type { ReactElement, BaseSyntheticEvent } from 'react';
import type { ReactionResponse } from 'stream-chat';
import type { ReactionsListProps } from 'stream-chat-react';

const ChatCustomReactionsListWebWithContext = ({
  allReactionList,
  ownReactionList,
  isRightAlign,
  handleReaction,
}: ReactionsListProps & {
  allReactionList: ReactionResponse[];
  ownReactionList: ReactionResponse[];
  handleReaction: (reactionType: string, event: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  isRightAlign: boolean;
  messageId: string;
}): ReactElement | null => {
  const id = useId();
  const { isOpen, onOpen, onClose } = useDisclose();

  const [index, setIndex] = useState<number>(0);

  const ownReactionTypeList = ownReactionList.map((reaction) => reaction.type);
  const reactionTypeList = useMemo(() => {
    const reactionTypeListCandidate: { type: string; count: number; isOwnReactioned: boolean }[] = [];

    allReactionList.forEach((reaction) => {
      const index = reactionTypeListCandidate.findIndex((item) => item.type === reaction.type);
      if (index === -1) {
        reactionTypeListCandidate.push({
          type: reaction.type,
          count: 1,
          isOwnReactioned: !!ownReactionTypeList.includes(reaction.type),
        });
        return;
      }
      reactionTypeListCandidate[index].count++;
    });

    return reactionTypeListCandidate;
  }, [allReactionList]);

  const routes = useMemo(() => {
    return reactionTypeList.map((reaction) => ({ key: reaction.type, title: reaction.type }));
  }, [reactionTypeList]);

  const renderTabBar = useCallback(
    (props: TabBarProps<Route>) => {
      return (
        <TabBar
          {...props}
          renderLabel={({ route, focused }) => (
            <HStack alignItems="center" space={2}>
              {/* @ts-expect-error TS(2339): Property 'emojis' does not exist on type 'typeof i... Remove this comment to see the full error message */}
              <Text fontSize={24}>{data.emojis[route.key]?.skins[0]?.native}</Text>
              <Text fontSize={20} color={focused ? '#3478f6' : '#959bb0'}>
                {reactionTypeList.find((reaction) => reaction.type === route.key)?.count}
              </Text>
            </HStack>
          )}
          style={{ backgroundColor: 'rgb(250,250,250)' }}
          indicatorStyle={{ backgroundColor: '#0EC9E5' }}
        />
      );
    },
    [reactionTypeList]
  );

  const renderScene = useMemo(() => {
    const sceneList: { [key: string]: () => ReactElement | null } = {};
    const keyList = routes.map((route) => route.key);
    keyList.forEach((key) => {
      const userList = allReactionList.filter((reaction) => reaction.type === key) ?? [];

      sceneList[key] = () => (
        <ScrollView pt={8} pb={6} px={4} backgroundColor="rgb(250,250,250)">
          {userList.map((userInfo) => (
            <HStack key={`reaction-user-${id}-${userInfo.user_id}`} alignItems="center" space={4} h={4} mb={2}>
              {userInfo.user?.image ? (
                // @ts-expect-error TS(2322): Type '{ uri: unknown; }' is not assignable to type... Remove this comment to see the full error message
                <Image source={{ uri: userInfo.user.image }} alt="" width={10} height={10} rounded="full" />
              ) : (
                <Ionicons name="person-circle-sharp" size={40} style={{ color: '#bbb' }} />
              )}
              <Text fontSize="md" color="gray.500">
                {userInfo.user?.name}
              </Text>
            </HStack>
          ))}
        </ScrollView>
      );
    });

    return SceneMap(sceneList);
  }, [routes, allReactionList]);

  return (
    <>
      <HStack justifyContent={isRightAlign ? 'flex-end' : 'flex-start'} flexWrap="wrap" mt={2}>
        {reactionTypeList.map((reaction) => {
          const foundEmoji =
            reaction.type in customEmojis ? customEmojis[reaction.type as keyof typeof customEmojis].image : null;
          return (
            <Tooltip
              key={`emoji-${id}-${reaction.type}`}
              label={allReactionList
                .filter((rt) => rt.type === reaction.type)
                .map((rt) => rt.user?.name)
                .join(', ')}
            >
              <Pressable
                onPress={(e) => {
                  handleReaction(reaction.type, e);
                }}
              >
                <HStack
                  alignItems="center"
                  h={7}
                  paddingX="8px"
                  borderRadius={20}
                  borderWidth={1}
                  space={1}
                  mb={1}
                  marginLeft={1}
                  bgColor={reaction.isOwnReactioned ? 'cyan.100' : 'gray.100'}
                  borderColor={reaction.isOwnReactioned ? 'cyan.600' : 'gray.100'}
                >
                  {foundEmoji ? (
                    <Image source={foundEmoji} alt={reaction.type} width={5} height={5} />
                  ) : (
                    <>
                      {/* @ts-expect-error TS(2339): Property 'emojis' does not exist on type 'typeof i... Remove this comment to see the full error message */}
                      <Text fontSize={20}>{data.emojis[reaction.type]?.skins[0]?.native}</Text>
                    </>
                  )}
                  <Text fontSize="sm" color="gray.500">
                    {reaction.count}
                  </Text>
                </HStack>
              </Pressable>
            </Tooltip>
          );
        })}
      </HStack>
      {routes.length > 0 && (
        <Actionsheet isOpen={isOpen} onClose={onClose}>
          <Actionsheet.Content>
            <TabView
              navigationState={{
                index,
                routes,
              }}
              onIndexChange={setIndex}
              renderTabBar={renderTabBar}
              renderScene={renderScene}
              style={{ width: '100%' }}
            />
          </Actionsheet.Content>
        </Actionsheet>
      )}
    </>
  );
};

const MemorizedChatCustomReactionsListWeb = memo(
  ChatCustomReactionsListWebWithContext,
  (prev, next) => prev.allReactionList === next.allReactionList
);

const ChatCustomReactionsListWeb = (): ReactElement | null => {
  const { channel } = useChannelStateContext();
  const { message, handleReaction, isMyMessage } = useMessageContext();
  const isRightAlign = useMemo(() => isMyMessage(), [isMyMessage]);
  const [allReactionList, setAllReactionList] = useState<ReactionResponse[]>([]);

  useEffect(() => {
    const getReactions = async () => {
      if (!channel) return;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (message.latest_reactions.length === 0) return;

      // メッセージが最新10件のリアクションデータを持っているので、それで足りる場合はそれを使う
      // https://getstream.io/chat/docs/react-native/send_reaction/#paginating-reactions
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (message.latest_reactions.length < 10) {
        // @ts-expect-error TS(2488): Type 'ReactionResponse<DefaultStreamChatGenerics>[... Remove this comment to see the full error message
        setAllReactionList([...message.latest_reactions].reverse());
        return;
      }
      const response = await channel?.getReactions?.(message.id, { limit: 300 });
      if (response) {
        setAllReactionList([...response.reactions].reverse());
      }
    };

    getReactions();
  }, [channel, message.reaction_counts]);

  return (
    // @ts-expect-error TS(2741): Property 'messageId' is missing in type '{ isRight... Remove this comment to see the full error message
    <MemorizedChatCustomReactionsListWeb
      isRightAlign={isRightAlign}
      allReactionList={allReactionList}
      ownReactionList={message.own_reactions as ReactionResponse[]}
      handleReaction={handleReaction}
    />
  );
};

export default ChatCustomReactionsListWeb;
