import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import data from '@emoji-mart/data';
import { init, SearchIndex } from 'emoji-mart';
import debounce from 'lodash/debounce';
import { Alert, Center, Divider, FlatList, HStack, Spinner, VStack, Box } from 'native-base';
import normalizeUrl from 'normalize-url';
import { ComponentType, useCallback, useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { Message } from 'stream-chat';
import { renderText, ScrollToBottomButton } from 'stream-chat-react';

import { match } from 'ts-pattern';

import { ShowPinMessagesContext, ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useDraft } from '~/hooks/useDraft.web';
import { usePinMessageWeb } from '~/hooks/usePinMessageWeb';
import { usePinnedChannels } from '~/hooks/usePinnedChannels';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import {
  Attachment,
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
  Modal,
  ModalGallery,
} from '~/lib/StreamChatReact';

import type {
  AttachmentProps,
  ChannelPreviewUIComponentProps,
  CustomMessageActionsListProps,
  MessageInputProps,
  UnreadMessagesNotificationProps,
} from '~/lib/StreamChatReact';
import { ChannelSwitcher } from './ChannelSwitcher';
import { ChatCustomThreadInput } from './ChatCustomInput.web';
import { ChatCustomMessage } from './ChatCustomMessage';
import ChatCustomParentMessage from './ChatCustomParentMessage';
import ChatCustomReactionSelectorWeb from './ChatCustomReactionSelectorWeb';
import ChatCustomReactionsListWeb from './ChatCustomReactionsListWeb';
import { anchorComponent } from './CustomAnchorComponent.web';
import { CustomSuggestionItem } from './CustomAutoCompleteTextArea/CustomSuggestionItem';
import { CustomSuggestionList } from './CustomAutoCompleteTextArea/CustomSuggestionList';
import { CustomChannelPreviewMessenger } from './CustomChannelPreviewMessenger.web';
import { CustomDateSeparator } from './CustomDateSeparator';
import { CustomHeader } from './CustomHeader';
import { CustomMessageActionsListWeb } from './CustomMessageActionsListWeb';
import { CustomMessageAvatar } from './CustomMessageAvatar';
import CustomMessageOptions from './CustomMessageOptions.web';
import { CustomMessageTimestamp } from './CustomMessageTimestamp.web';
import { EmptyMessage } from './EmptyMessage';
import { PinMassageListItem } from './PinMassageListItem';
import { UnreadMessageNotification } from './UnreadMessageNotificationWeb';
import type { Channel as StreamChannel } from 'stream-chat';

init({ data });

const CHANNEL_LIST_WIDTH = 300;

interface Props {
  type: ChannelListCategory;
  disableAddChannel?: boolean;
  messageId?: string;
  channelId?: string;
  addChannel?: React.ReactNode;
  defaultValue?: string;
  isDM?: boolean;
}

type ChannelListCategory = 'my' | 'dm';

const sort = { last_message_at: -1 };

const ChannelWindow: React.FC<{
  defaultValue: string;
  pinMessages: Message[];
}> = ({ defaultValue, pinMessages }) => {
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef, uncheckedRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();
  const showPinMessages = useContext(ShowPinMessagesContext);

  const customRenderText = useCallback(
    (text: any, mentionedUsers: any) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  const CustomThreadInputWrapper = useCallback(
    () => (
      <ChatCustomThreadInput
        isChecked={isCheckedRef.current}
        toggleChecked={toggleCheckedRef}
        unchecked={uncheckedRef}
        isThread
      />
    ),
    [isCheckedRef, toggleCheckedRef]
  );
  return (
    <>
      <Window hideOnThread={false}>
        <>
          {showPinMessages ? (
            <Box height="100%">
              <CustomHeader />
              <FlatList
                data={pinMessages}
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                keyExtractor={(item, index) => `${item.message.id}:${index}`}
                ItemSeparatorComponent={() => <Divider borderColor="gray.200" />}
                renderItem={({ item, index }) => <PinMassageListItem index={index} item={item} />}
                ListEmptyComponent={<EmptyMessage emptyMessage="ピンどめされたメッセージはありません" />}
                marginTop={50}
              />
            </Box>
          ) : (
            <>
              <MessageList
                groupStyles={() => 'single'}
                renderText={customRenderText}
                messageLimit={20}
                additionalMessageInputProps={{ mentionQueryParams: { options: { limit: 100 } } }}
              />
              <MessageInput
                overrideSubmitHandler={overrideSubmitHandler}
                shouldSubmit={shouldSubmit}
                grow
                mentionQueryParams={{
                  options: { limit: 100 },
                }}
              />
            </>
          )}
        </>
      </Window>
      <Thread
        Input={CustomThreadInputWrapper}
        additionalMessageInputProps={{
          overrideSubmitHandler,
          shouldSubmit,
          grow: true,
          mentionQueryParams: {
            options: { limit: 100 },
          },
        }}
        additionalParentMessageProps={{
          Message: ChatCustomParentMessage,
        }}
      />
    </>
  );
};

export const ChatChannelListWeb: React.FC<Props> = ({
  disableAddChannel,
  channelId,
  messageId,
  type = 'my',
  addChannel,
  defaultValue,
  isDM,
}) => {
  const { chatUserId, isUserConnected, appChannel } = useStreamChatContext();
  const { pinnedChannels } = usePinnedChannels();
  const [messageAreaWidth, setMessageAreaWidth] = useState(Dimensions.get('window').width - CHANNEL_LIST_WIDTH);

  const { pinMessages } = usePinMessageWeb();

  useDidMount(() => {
    const handleWindowResize = debounce(
      () => {
        setMessageAreaWidth(Dimensions.get('window').width - CHANNEL_LIST_WIDTH);
      },
      200,
      { maxWait: 1000 }
    );
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const dispatch = useContext(ShowPinMessagesDispatchContext);

  const closePinMessage = () => {
    dispatch && dispatch({ type: 'close' });
  };

  useDidUpdate(
    () => {
      closePinMessage();
    },
    [appChannel],
    true
  );

  const channelFilters = match<ChannelListCategory>(type)
    .with('my', () => ({
      members: { $in: [chatUserId] },
      isDM: {
        $nin: [true],
      },
    }))
    .with('dm', () => ({
      members: {
        $in: [chatUserId],
      },
      isDM: {
        $eq: true,
      },
      type: {
        $eq: 'messaging',
      },
    }))
    .exhaustive();

  const emptyAlertMessage = match<ChannelListCategory>(type)
    .with('my', () => '参加しているチャンネル一覧が表示されます。')
    .with('dm', () => '参加中のダイレクト・メッセージが表示されます。')
    .exhaustive();

  const channelRenderFilterFn = (channels: StreamChannel[]) => {
    const pinned: StreamChannel[] = [];
    const others: StreamChannel[] = [];
    const pinnedChannelSet = new Set(pinnedChannels);

    channels
      .filter((channel) => (isDM ? channel.data?.isDM : !channel.data?.isDM))
      .forEach((channel) => {
        // 削除済み・削除予定は取り除く
        if (channel.disconnected || channel.data?.to_be_deleted) return;
        // メンバーIDがchatUserIdに一致するかチェック
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        if (channel.state.members[chatUserId]) {
          // ピン留めされたチャンネルかどうかをチェック
          if (pinnedChannelSet.has(channel.cid)) {
            pinned.push(channel);
          } else {
            others.push(channel);
          }
        }
      });

    return [...pinned, ...others];
  };

  const CustomEditInputWrapper = useCallback(() => <ChatCustomThreadInput isEdit />, []);

  const renderAttachment = useCallback((props: AttachmentProps) => {
    const [index, setIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // Remove duplicate attachments by title and title_link
    const uniqueAttachments = props.attachments.filter(
      (item, index, self) =>
        !item.og_scrape_url || // affects only OGAttachment
        index ===
          self.findIndex(
            (t) =>
              !!t.og_scrape_url && // comparison target must be OGAttachment
              t.title === item.title &&
              (t.title_link ? normalizeUrl(t.title_link) : '') ===
                (item.title_link ? normalizeUrl(item.title_link) : '')
          )
    );

    const checkLink = uniqueAttachments.some((item) => item.type === 'image' && item.hasOwnProperty('title_link'));
    const styles = { '--original-height': '1000000', '--original-width': '1000000' };

    if (!checkLink) {
      if (props.attachments.length <= 4 && props.attachments.length > 2) {
        return (
          <div className="str-chat__attachment-list">
            <div className="str-chat__message-attachment str-chat__message-attachment-dynamic-size str-chat__message-attachment--gallery">
              <div className="str-chat__gallery str-chat__gallery--square str-chat__gallery-two-rows">
                {uniqueAttachments.map((attachment, index) => (
                  <button
                    className="str-chat__gallery-image"
                    data-testid="gallery-image"
                    key={`gallery-image-${index}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setIndex(index);
                    }}
                  >
                    <img
                      data-testid="str-chat__base-image"
                      alt={attachment.fallback}
                      src={attachment.image_url}
                      title={attachment.fallback}
                      className="str-chat__base-image"
                      // @ts-expect-error TS(2559): Type '{ '--original-height': string; '--original-w... Remove this comment to see the full error message
                      style={styles}
                    />
                  </button>
                ))}
                <Modal
                  className="str-chat__gallery-modal"
                  onClose={() => setIsModalOpen((isModalOpen) => !isModalOpen)}
                  open={isModalOpen}
                >
                  <ModalGallery images={props.attachments} index={index} />
                </Modal>
              </div>
            </div>
          </div>
        );
      } else {
        return <Attachment {...props} attachments={uniqueAttachments} />;
      }
    } else {
      return <Attachment {...props} attachments={uniqueAttachments} />;
    }
  }, []);

  const renderPreview = useCallback(
    (props: ChannelPreviewUIComponentProps) => {
      return <CustomChannelPreviewMessenger {...props} isDM={isDM} />;
    },
    [isDM]
  );
  const renderUnreadMessagesSeparatorEmpty = useCallback(() => {
    return null;
  }, []);

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  const renderUnreadMessageNotification = useCallback((props: UnreadMessagesNotificationProps) => {
    if (!props.unreadCount || props.unreadCount <= 0) {
      return null;
    }
    return <UnreadMessageNotification {...props} />;
  }, []);

  return isUserConnected ? (
    <>
      <HStack backgroundColor="white" height="100%">
        <VStack
          flex={1}
          borderRightWidth={1}
          borderColor="gray.300"
          maxWidth={`${CHANNEL_LIST_WIDTH}px`}
          zIndex="unset"
        >
          {!disableAddChannel && (
            <HStack paddingX={5} paddingY={4} width="100%">
              {addChannel}
            </HStack>
          )}
          <ChannelList
            // @ts-expect-error TS(2322)
            filters={channelFilters}
            // @ts-expect-error TS(2322): Type '{ last_message_at: number; }' is not assigna... Remove this comment to see the full error message
            sort={sort}
            channelRenderFilterFn={channelRenderFilterFn}
            EmptyStateIndicator={() => (
              <Alert status="info" margin={4}>
                {emptyAlertMessage}
              </Alert>
            )}
            // @ts-expect-error TS(2322): Type '(props: ChannelPreviewUIComponentProps) => R... Remove this comment to see the full error message
            Preview={renderPreview}
          />
        </VStack>
        <Channel
          channel={appChannel}
          channelQueryOptions={{
            messages: {
              limit: 20,
            },
          }}
          Avatar={CustomMessageAvatar}
          EditMessageInput={CustomEditInputWrapper}
          // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<Omit<any, "ref"> &... Remove this comment to see the full error message
          ReactionSelector={ChatCustomReactionSelectorWeb}
          ReactionsList={ChatCustomReactionsListWeb}
          // @ts-expect-error TS(2322): Type '(props: Props) => React.JSX.Element' is not ... Remove this comment to see the full error message
          AutocompleteSuggestionList={CustomSuggestionList}
          // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<CustomSuggestionIt... Remove this comment to see the full error message
          AutocompleteSuggestionItem={CustomSuggestionItem}
          Message={ChatCustomMessage}
          MessageNotification={ScrollToBottomButton}
          CustomMessageActionsList={renderMessageActionList}
          MessageTimestamp={CustomMessageTimestamp}
          MessageOptions={CustomMessageOptions}
          Input={ChatCustomThreadInput as ComponentType<MessageInputProps>}
          Attachment={renderAttachment}
          HeaderComponent={CustomHeader}
          emojiSearchIndex={SearchIndex}
          DateSeparator={CustomDateSeparator}
          UnreadMessagesSeparator={appChannel?.countUnread() == 0 ? renderUnreadMessagesSeparatorEmpty : undefined}
          UnreadMessagesNotification={renderUnreadMessageNotification}
        >
          <ChannelSwitcher
            channelId={channelId}
            messageId={messageId}
            channelType={
              type === 'dm' || channelId?.match(/^chumly-self-.+/) || channelId?.match(/^user-channel-secret-.+/)
                ? 'messaging'
                : 'team'
            }
          />
          <HStack flex={1} paddingBottom={2} width={`${messageAreaWidth}px`}>
            <ChannelWindow defaultValue={defaultValue || ''} pinMessages={pinMessages} />
          </HStack>
        </Channel>
      </HStack>
    </>
  ) : (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
