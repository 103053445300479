import { StyledProps } from 'native-base';
import * as React from 'react';

import { FarmDetailScreen } from '~/screens';

import { Section } from './Section';

interface Props {
  farmId: number;
}

export const FarmCard: React.FC<Props & StyledProps> = React.memo(({ farmId, ...remainingProps }) => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; opacity?: ResponsiveVal... Remove this comment to see the full error message
    <Section {...remainingProps}>
      <FarmDetailScreen showGroups={false} farmId={farmId} cardMode />
    </Section>
  );
});
