import { useNavigation, useRoute } from '@react-navigation/native';
import { Button, Divider } from 'native-base';
import * as React from 'react';

import { AppointmentServiceDetail } from '~/components/AppointmentServiceDetail';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AppointmentServiceDetailScreen: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();

  return (
    <BaseScreenBuilder>
      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
      <AppointmentServiceDetail id={route?.params.id} />
      <Divider />
      <Button
        onPress={() =>
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('AppointmentScheduleApply', {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
            serviceId: route?.params?.id,
          })
        }
      >
        予約を申し込む
      </Button>
    </BaseScreenBuilder>
  );
};
