import emojiData from '@emoji-mart/data';
import { Ionicons } from '@expo/vector-icons';
import { HStack, Box, VStack, Image, Icon, Text } from 'native-base';
import { memo, useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { CustomMessageText } from '~/components';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { DateUtil } from '~/utils/DateUtils';
import { customEmojis } from '~/utils/emoji';

type Props = {
  id: number;
  sourceType: string;
  sourceChannelName: string;
  sourceUserAvatar?: string;
  sourceUserName?: string;
  sourceEventedAt: string;
  body: string;
  isUnread: boolean;
  sourceChannelId: string;
  sourceChannelType: string;
  sourceMessageId: string;
  isDM?: boolean;
  isDMWeb?: boolean;
  sourceLink?: string;
  handleMessagePress: (
    id: number,
    sourceChannelId: string,
    sourceChannelType: string,
    sourceMessageId: string,
    sourceLink: string,
    isDM: boolean,
    isDMWeb: boolean
  ) => void;
};

const UnMemoizedNotificationItem = ({
  id,
  sourceType,
  sourceChannelName,
  sourceUserAvatar,
  sourceUserName,
  sourceEventedAt,
  body,
  isUnread,
  sourceChannelId,
  sourceChannelType,
  sourceMessageId,
  isDM,
  isDMWeb,
  sourceLink,
  handleMessagePress,
}: Props) => {
  const onPress = useCallback(() => {
    handleMessagePress(id, sourceChannelId, sourceChannelType, sourceMessageId, sourceLink || '', !!isDM, !!isDMWeb);
  }, [id, sourceChannelId, sourceChannelType, sourceMessageId, sourceLink, isDM, isDMWeb, handleMessagePress]);
  const { chatClient, isUserConnected } = useStreamChatContext();

  let emoji, foundEmoji;
  const [messageText, setMessageText] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | undefined>(sourceUserName);
  const [userAvatar, setUserAvatar] = useState<string | undefined>(sourceUserAvatar);
  let reactionNode;
  if (sourceType === 'reaction' && body) {
    emoji = body;
    foundEmoji = emoji in customEmojis ? customEmojis[emoji as keyof typeof customEmojis].image : null;
    if (foundEmoji) {
      reactionNode = <Image source={foundEmoji} alt={emoji} width={10} height={10} />;
    } else {
      // @ts-expect-error TS(2339): Property 'emojis' does not exist on type 'typeof i... Remove this comment to see the full error message
      reactionNode = <Text fontSize={20}>{emojiData.emojis[emoji]?.skins[0]?.native}</Text>;
    }
  }
  useEffect(() => {
    if (sourceType === 'reaction' && sourceMessageId && isUserConnected) {
      chatClient.getMessage(sourceMessageId).then((res) => {
        if (typeof res?.message?.text === 'string') {
          setMessageText(res.message.text);
        }
      });
    }
    if (sourceType === 'chat' && sourceUserName == null) {
      chatClient.getMessage(sourceMessageId).then((res) => {
        if (res.message?.user?.name) {
          setUserName(res.message.user.name);
        }
        if (res.message?.user?.image) {
          setUserAvatar(res.message.user.image);
        }
      });
    }
  }, [chatClient, isUserConnected]);

  return (
    <HStack padding={4} alignItems="center">
      <TouchableOpacity onPress={onPress} style={{ flex: 1 }}>
        <VStack space={2}>
          <HStack space={2}>
            <Text fontWeight={500} color="gray.400">
              {sourceChannelName}
            </Text>
          </HStack>
          <HStack space={2}>
            {userAvatar ? (
              <Image source={{ uri: userAvatar }} size="xs" borderRadius="full" alt="" />
            ) : (
              <Icon as={Ionicons} name="person-circle-outline" size="4xl" />
            )}
            <VStack space={1} flex={1}>
              <HStack alignItems="center" space={2}>
                <Text fontSize="md" bold>
                  {userName}
                </Text>
                {sourceType === 'reaction' && <Text fontSize="md">さんがリアクションしました</Text>}
                <Text color="gray.500">
                  {DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(sourceEventedAt))}{' '}
                  {DateUtil.tohmm(new Date(sourceEventedAt))}
                </Text>
              </HStack>
              {reactionNode ? (
                <>
                  {reactionNode}
                  <Box bgColor="amber.50">
                    <CustomMessageText message={{ text: messageText }} />
                  </Box>
                </>
              ) : (
                <CustomMessageText message={{ text: body }} />
              )}
            </VStack>
          </HStack>
        </VStack>
      </TouchableOpacity>
      {isUnread && sourceType !== 'reaction' && (
        <Box backgroundColor="amber.100" borderRadius={10} padding={1}>
          <Text color="black" fontSize="xs">
            未読
          </Text>
        </Box>
      )}
    </HStack>
  );
};

export const NotificationItem = memo(UnMemoizedNotificationItem) as typeof UnMemoizedNotificationItem;
