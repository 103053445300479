import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, Skeleton, VStack, StyledProps } from 'native-base';
import * as React from 'react';

import { useMilkAmountByRange } from '~/hooks/useMilkAmountByRange';
import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryScatter,
  VictoryLegend,
  VictoryCandlestick,
} from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

import { GraphAlertBox } from './GraphAlertBox';
import { TableChart } from './TableChart';
interface Props {
  endDate: Date;
  range: number;
  showTable: boolean;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
  shareButton?: React.ReactElement;
}

export const MilkAmountCandleChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    showTable,
    chatChannelId,
    groupId,
    farmId,
    isPreview = false,
    width = 420,
    height = 380,
    barWidth = 8,
    onTypeChange,
    shareButton,
    ...remainingProps
  }) => {
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    const { setEndDate, setRange, milkings, preMilkings, milkingsMA } = useMilkAmountByRange(groupId, endDate, range);

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const Chart = React.useMemo(
      () => (
        <VictoryChart
          width={isPreview ? 220 : width}
          height={isPreview ? 198 : height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [10, 10], y: [0, 0] }}
          padding={isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : undefined}
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x, i) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          {isPreview ? null : (
            <VictoryLegend
              orientation="horizontal"
              x={52}
              gutter={60}
              data={[
                { name: '5日間移動平均', symbol: { fill: 'blue' } },
                { name: '増加', symbol: { fill: 'green', type: 'minus' } },
                { name: '減少', symbol: { fill: 'red', type: 'minus' } },
              ]}
            />
          )}
          <VictoryCandlestick
            data={
              milkingsMA.data?.map((cur, index) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  open: index === 0 ? cur.amount : milkingsMA.data?.[index - 1]?.amount,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  close: cur.amount || 0,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  high: cur.amount,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  low: cur.amount,
                };
              }) ?? []
            }
            candleColors={{ positive: 'green', negative: 'red' }}
            style={{
              data: { strokeWidth: 0 },
            }}
          />
          <VictoryLine
            data={
              milkingsMA.data?.map((cur) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  y: cur.amount || 0,
                };
              }) ?? []
            }
            style={{
              data: { stroke: 'blue', strokeWidth: 1 },
            }}
          />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <VictoryScatter
            data={
              milkingsMA.data?.map((cur) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  y: cur.amount || 0,
                };
              }) ?? []
            }
            style={{
              stroke: 'blue',
            }}
            size={isPreview ? 2 : 4}
          />
        </VictoryChart>
      ),
      [milkings, milkingsMA, preMilkings]
    );

    if (milkings?.isLoading) {
      return <Skeleton height={isPreview ? 235 : height} />;
    }

    if (milkings.isError || preMilkings.isError || milkingsMA.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkings.refetch();
            preMilkings.refetch();
            milkingsMA.refetch();
          }}
        />
      );
    }

    if (isPreview) {
      return <VStack>{Chart}</VStack>;
    }

    return (
      <>
        <Box paddingX="auto">{Chart}</Box>
        <TableChart
          // @ts-expect-error TS(2322): Type '{ date_label: string; pre_amount: any; amoun... Remove this comment to see the full error message
          data={milkingsMA.data?.map((cur, index) => {
            // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
            const pre_amount = index === 0 ? cur.amount : milkingsMA.data?.[index - 1]?.amount;
            return {
              date_label: DateUtil.toMMDD(cur.date),
              pre_amount,
              // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
              amount: cur.amount,
              // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
              difference: Math.round((cur.amount - pre_amount) * 10) / 10,
            };
          })}
          headers={['日付', '平均搾乳量(5日間平均)', '前日の平均搾乳量(5日間平均)', '差(kg)']}
          columns={['date_label', 'amount', 'pre_amount', 'difference']}
          padding={4}
          showTable={showTable}
          widthArr={[100, 100, 100, 100]}
        />
        {shareButton ? shareButton : null}
      </>
    );
  }
);
