import { Heading, VStack } from 'native-base';
import { NotificationsScreen } from '../NotificationsScreen';

type Props = {
  width: number;
};

export const NotificationColumn = ({ width: columnWidth }: Props) => {
  return (
    <VStack width={columnWidth} height="100%" flexGrow={0}>
      <Heading size="md">通知</Heading>
      <NotificationsScreen />
    </VStack>
  );
};
