import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
// @ts-expect-error TS(2307): Cannot find module '@u-motion-apps/packages/u-moti... Remove this comment to see the full error message
import { usePostSessionMutation } from '@u-motion-apps/packages/u-motion-api/uMotionApi';
import { Formik } from 'formik';
import { Button, Link, VStack, Text, Box, Input } from 'native-base';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { useNetworkStatus } from '../../hooks/useNetworkStatus';

import { Alert } from '~/components/Alert';
import { setUmotionToken } from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { useAppDispatch } from '~/store';

import { validationSchema } from './validationSchema';

const MAX_ATTEMPTS = 9;
const DISABLE_INTERVAL = 30000;

export const UmotionEmailLoginScreen = ({ theme }: any) => {
  const [isSecure, setIsSecure] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const { isConnectedToInternet } = useNetworkStatus();
  const [postSession] = usePostSessionMutation();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  function hideButton() {
    let icon = <Entypo name="eye-with-line" size={20} />;
    if (isSecure === true) {
      icon = <Entypo name="eye" size={20} />;
    }
    return (
      <TouchableOpacity onPress={switchShowHide}>
        <Box mr={2}>{icon}</Box>
      </TouchableOpacity>
    );
  }

  const switchShowHide = () => {
    setIsSecure(!isSecure);
  };

  const loginError = (actions: any) => {
    setAttempts(attempts + 1);

    if (attempts != MAX_ATTEMPTS) {
      actions.setFieldError('general', 'メールアドレスかパスワードが正しくありません');
      actions.setFieldError('email', '');
      actions.setFieldError('password', '');
    } else {
      actions.setFieldError('general', '30秒間ログインできません');
      setIsDisabled(true);
      setTimeout(() => {
        actions.setFieldError('general', '');
        setIsDisabled(false);
        setAttempts(0);
      }, DISABLE_INTERVAL);
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    if (!isConnectedToInternet) {
      Alert.alert('エラー', 'インターネットを有効にしてください', [{ text: 'OK' }]);
    } else {
      setIsLoading(true);

      try {
        const result = await postSession({
          email: values.email,
          password: values.password,
        }).unwrap();

        if (!result) {
          actions.setFieldError('email', 'ログインできませんでした');
        } else {
          dispatch(
            setUmotionToken({
              sessionToken: result.sessionToken || '',
              refreshToken: result.refreshToken || '',
              expiredAt: result.expiredAt || 0,
            })
          );
          localStorage.setItem('LastLoginMethodStorageKey', 'email');
        }
      } catch (error) {
        console.error('login error => ', error);
        loginError(actions);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', remember: true }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const isButtonDisabled = !formikProps.isValid || isLoading || isDisabled;
        const emailErrors = formikProps?.touched['email'] && formikProps?.errors['email'];
        const passwordErrors = formikProps?.touched['password'] && formikProps?.errors['password'];
        return (
          <>
            <>
              <VStack space={4} flex={1}>
                <Box>
                  <Input
                    type="text"
                    fontSize="sm"
                    bg="white"
                    padding={4}
                    borderWidth={0}
                    placeholder="メールアドレス"
                    onChangeText={formikProps?.handleChange('email')}
                    onBlur={formikProps?.handleBlur('email')}
                    autoFocus
                    autoCapitalize="none"
                    isDisabled={isDisabled}
                    borderColor={emailErrors ? 'red.500' : 'white'}
                  />
                  <Text style={{ color: 'red' }}>
                    <>{emailErrors}</>
                  </Text>
                </Box>
                <Box>
                  <Input
                    type="password"
                    fontSize="sm"
                    bg="white"
                    padding={4}
                    borderWidth={0}
                    placeholder="パスワード"
                    onChangeText={formikProps?.handleChange('password')}
                    onBlur={formikProps?.handleBlur('password')}
                    autoCapitalize="none"
                    isDisabled={isDisabled}
                    secureTextEntry={isSecure}
                    borderColor={passwordErrors ? 'red.500' : 'white'}
                    keyboardType="ascii-capable"
                    InputRightElement={hideButton()}
                  />
                  <Text style={{ color: 'red' }}>
                    <>{passwordErrors}</>
                  </Text>
                </Box>
                <VStack space={4}>
                  <Button
                    // @ts-expect-error TS(2322): Type '(e?: FormEvent<HTMLFormElement> | undefined)... Remove this comment to see the full error message
                    onPress={formikProps.handleSubmit}
                    disabled={isButtonDisabled}
                    color="white"
                    loading={isLoading}
                    backgroundColor={isButtonDisabled ? 'cyan.800' : 'cyan.600'}
                  >
                    ログイン
                  </Button>
                  <Button
                    variant="link"
                    onPress={() =>
                      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('UmotionResetPassword', {
                        resetEmail: formikProps.values['email'],
                      })
                    }
                  >
                    パスワードを忘れた方はこちら
                  </Button>
                  {/* @ts-expect-error TS(2339): Property 'general' does not exist on type 'FormikE... Remove this comment to see the full error message */}
                  {formikProps.errors.general && (
                    <Box
                      alignItems="center"
                      paddingTop={4}
                      paddingBottom={4}
                      style={{ backgroundColor: theme?.dark ? 'black' : '#eee' }}
                    >
                      {/* @ts-expect-error TS(2339): Property 'general' does not exist on type 'FormikE... Remove this comment to see the full error message */}
                      <Text style={{ color: 'red' }}>{formikProps.errors.general}</Text>
                    </Box>
                  )}
                  <Text>
                    「ログイン」をタップすると
                    <Link href=" https://www.desamis.co.jp/u-feed-terms" isExternal>
                      利用規約
                    </Link>
                    と
                    <Link href="https://www.desamis.co.jp/u-feed-privacy-policy" isExternal>
                      プライバシーポリシー
                    </Link>
                    に同意したことになります。
                  </Text>
                </VStack>
              </VStack>
            </>
          </>
        );
      }}
    </Formik>
  );
};
