import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { Text, Alert, HStack } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useUserChannel } from '~/hooks/useUserChannel';
import { useYomi } from '~/hooks/useYomi';

export const ChatChannelCreateEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const [isPost, setIsPost] = useState(false);
  const [groupsOption, setGroupsOption] = useState([]);
  const [membersOption, setMembersOption] = useState<{ id: string; name: string }[]>([]);
  const [selectedChatId, setSelectedChatId] = useState();
  const [selectedChannelType, setSelectedChannelType] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery();
  const { createUserChannel, errorMessages } = useUserChannel();
  const { chatUserId } = useStreamChatAuthContext();

  const { searchYomi } = useYomi();

  const onSubmit = async (sendingData: any) => {
    const createChannelResult = await createUserChannel({
      name: sendingData.name,
      members: sendingData.members,
      chatTeamId: sendingData.chatTeamId,
      description: sendingData.description,
      isPublic: sendingData.channelType === 'public',
      isSecret: sendingData.channelType === 'secret',
    });

    if (createChannelResult) {
      if (!('isError' in createChannelResult) || !createChannelResult.isError) {
        if (Platform.OS === 'web') {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'QueryCh... Remove this comment to see the full error message
          linkTo(`/chat/${createChannelResult.channel.id}`);
        } else {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'QueryCh... Remove this comment to see the full error message
          const { id, type } = createChannelResult.channel;
          navigation.goBack();
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', {
            channelId: id,
          });
        }
      }
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  useDidUpdate(
    () => {
      if (!currentUserGroupsQuery.isSuccess) {
        return;
      }

      const groups = currentUserGroupsQuery.data?.map((g) => {
        const detail =
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          g?.farm?.name === g?.farm?.account?.name ? g?.farm?.name : `${g?.farm?.name} / ${g?.farm?.account?.name}`;
        return {
          value: g?.chat_team_id,
          label: `${g?.name} (${detail})`,
        };
      });

      // @ts-expect-error TS(2345): Argument of type '{ value: string | undefined; lab... Remove this comment to see the full error message
      setGroupsOption(groups);

      const memberIds = currentUserGroupsQuery.data?.[0]?.approved_users
        ?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          name: `${u.name}`,
        }))
        .flat();
      setMembersOption(memberIds ?? []);

      setDefaultValues({
        // @ts-expect-error TS(2345): Argument of type '{ channelType: string; chatTeamI... Remove this comment to see the full error message
        channelType: 'secret',
        chatTeamId: groups?.[0]?.value,
        members: [chatUserId],
      });
    },
    [currentUserGroupsQuery.data],
    true
  );

  useDidUpdate(() => {
    const chatIds = currentUserGroupsQuery.data
      ?.filter((d) => d.chat_team_id === selectedChatId)
      .map((g) =>
        g.approved_users?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          name: `${u.name}`,
        }))
      )
      .flat();
    // @ts-expect-error TS(2345): Argument of type '({ id: any; name: string; } | un... Remove this comment to see the full error message
    setMembersOption(chatIds);
  }, [selectedChatId]);

  if ((groupsOption?.length <= 0 ?? true) || !defaultValues) {
    return null;
  }

  return (
    <ScreenWidthModal title="チャンネル作成">
      <BaseScreenBuilder title="チャンネル作成">
        <BaseFormBuilder
          // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
          queryResult={{}}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onFieldUpdate={(updated) => {
            if (updated.chatTeamId !== selectedChatId) {
              updated.setValue('members', [chatUserId]);
            }
            if (updated.chatTeamId) {
              setSelectedChatId(updated.chatTeamId);
            }
            if (updated.channelType) {
              setSelectedChannelType(updated.channelType);
            }
          }}
          emptyMessage="グループがありません"
          defaultValues={defaultValues}
          fields={[
            // @ts-expect-error TS(2322): Type '{ key: string; label: string; rules: { requi... Remove this comment to see the full error message
            {
              key: 'name',
              label: '名前',
              rules: {
                required: {
                  value: true,
                  message: '名前を入力してください',
                },
              },
              watch: true,
            },
            {
              key: 'description',
              label: '説明',
              type: 'textarea',
            },
            {
              key: 'channelType',
              label: 'チャンネル種別',
              type: 'select',
              options: [
                {
                  label: '公開',
                  value: 'public',
                },
                {
                  label: 'シークレット',
                  value: 'secret',
                },
              ],
              rules: {
                required: {
                  value: true,
                  message: '種別を選択してください',
                },
              },
              watch: true,
            },
            {
              key: 'chatTeamId',
              type: 'select',
              label: '所属グループ',
              options: groupsOption,
              watch: true,
              rules: {
                required: {
                  value: true,
                  message: 'グループを選択してください',
                },
              },
            },
            {
              key: 'members',
              type: 'sectionedMultiselect',
              label: 'メンバー',
              options: membersOption,
              filterFunction: async (query: string) => {
                const matchedYomi = searchYomi(query);
                return membersOption.filter(
                  (o): o is { id: string; name: string } =>
                    typeof o.id !== 'undefined' && matchedYomi.findIndex((m) => m.chatUserId === o.id) >= 0
                );
              },
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              unchangeableItems: [chatUserId],
              watchAndReset: 'chatTeamId',
              rules: {
                required: {
                  value: true,
                  message: 'メンバーを選択してください',
                },
              },
            },
          ]}
        >
          {selectedChannelType === 'secret' ? (
            <Alert marginBottom={4}>
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <Alert.Icon />
                <Text bold>シークレットチャンネル</Text>
              </HStack>
              <Text w="100%">選択したメンバーのみ閲覧可能です。</Text>
            </Alert>
          ) : (
            <Alert marginBottom={4} status="warning">
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <Alert.Icon />
                <Text bold>公開チャンネル</Text>
              </HStack>
              <Text w="100%">同じグループのメンバー全員が閲覧可能です。</Text>
            </Alert>
          )}
        </BaseFormBuilder>
        {errorMessages
          ? errorMessages.map((errorMessage, i) => (
              <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
                {errorMessage}
              </Text>
            ))
          : null}
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
