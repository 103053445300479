import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { FormControl, Box, VStack, HStack, Text, useDisclose, Input, Icon } from 'native-base';
import { Logic } from 'native-base-form-builder';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { TouchableOpacity, Keyboard, Platform } from 'react-native';

const { useState } = React;

export const FormDate: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  isRequired,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const { isOpen, onClose, onToggle } = useDisclose();
  const errorMessage = formState.errors?.[field.name]?.message;
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const navigation = useNavigation();

  const openCalendarScreen = () => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('Calendar', {
      field,
      rules,
    });
  };

  useDidMount(() => {
    const showSubscription = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow',
      (e) => {
        Keyboard.scheduleLayoutAnimation(e);
        setKeyboardHeight(e.endCoordinates.height);
      }
    );
    const hideSubscription = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
      (e) => {
        Keyboard.scheduleLayoutAnimation(e);
        setKeyboardHeight(0);
      }
    );

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  });
  return (
    // @ts-expect-error TS(2322): Type 'string | FieldError | Merge<FieldError, Fiel... Remove this comment to see the full error message
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack space={2} flex={1}>
        <TouchableOpacity onPress={onToggle}>
          <HStack space={2} alignItems="center">
            <Text fontSize="sm" fontWeight="medium" color="gray.500">
              {label}
            </Text>
            {/* @ts-expect-error TS(2339): Property 'value' does not exist on type 'string | ... Remove this comment to see the full error message */}
            {rules?.required?.value ? (
              <Text fontSize="md" fontWeight="medium" color="red.400">
                *
              </Text>
            ) : null}
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={openCalendarScreen}>
          <Input
            variant="outline"
            size="lg"
            borderColor="black"
            value={field.value}
            editable
            showSoftInputOnFocus={false}
            onPressIn={openCalendarScreen}
            px={3}
            py={3}
            placeholder="タップして設定"
            InputLeftElement={
              <Box padding={2}>
                <Icon as={Ionicons} name="calendar-outline" fontSize="lg" />
              </Box>
            }
          />
        </TouchableOpacity>
        {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
      </VStack>
    </FormControl>
  );
};
