import { useNavigation, useRoute } from '@react-navigation/native';
import { Skeleton } from 'native-base';
import * as React from 'react';

import { useGetAdditivesByAdditiveIdQuery, usePutAdditivesByAdditiveIdMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { DateUtil } from '~/utils/DateUtils';

export const AdditiveEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};

  const [errorMessages, setErrorMessages] = React.useState([]);

  // @ts-expect-error TS(2339): Property 'additiveId' does not exist on type '{}'.
  const additiveId = queryParams.additiveId;
  const queryResult = useGetAdditivesByAdditiveIdQuery({ additiveId });
  const [update, { isLoading: isUpdating, data, error: putError }] = usePutAdditivesByAdditiveIdMutation();

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    const { memo, date } = sendingData;
    update({
      additiveId,
      body: {
        additive: {
          memo,
          date,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        navigation.goBack();
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  if (queryResult.isLoading) {
    return <Skeleton.Text />;
  }
  const max = DateUtil.toYYYYMMDD(new Date(), '-');

  const fields = [
    {
      key: 'memo',
      label: '内容',
    },
    {
      key: 'date',
      label: '追加日',
      type: 'date',
      rules: {
        required: {
          value: true,
          message: '追加日を選択してください',
        },
        max,
      },
    },
  ];

  return (
    <ScreenWidthModal title="添加剤編集">
      <BaseScreenBuilder title="添加剤編集">
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type '({ error?: undefined; data?: undefined; fulf... Remove this comment to see the full error message
          queryResult={queryResult}
          isUpdating={isUpdating}
          error={putError}
          onSubmit={onSubmit}
          onCancel={() => navigation.goBack()}
          errorMessage={errorMessages}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; type?: undefi... Remove this comment to see the full error message
          fields={fields}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
