import { Alert, Box, Button, StyledProps } from 'native-base';
import * as React from 'react';

interface Props {
  onPress: () => void;
}

export const GraphAlertBox: React.FC<Props & StyledProps> = React.memo(({ onPress, ...remainingProps }) => {
  return (
    <Box>
      <Alert colorScheme="error">
        <Alert.Icon />
        データの取得に失敗しました。
      </Alert>
      <Button size="sm" onPress={onPress} mt={8} mx="auto">
        再読み込み
      </Button>
    </Box>
  );
});
