import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, Text } from 'native-base';
import { useCallback, useState } from 'react';
import { StreamMessage, useChannelActionContext, useMessageContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { useStreamChatContext } from '~/contexts/StreamChatContext';

export const MessageParentThreadsLink: React.FC = () => {
  const { chatClient } = useStreamChatContext();
  const actions = useChannelActionContext();
  const openThread = actions.openThread;

  const { message, isMyMessage, threadList } = useMessageContext();
  const [parentMessage, setParentMessage] = useState<StreamMessage<DefaultStreamChatGenerics> | undefined>(undefined);

  const handleOpenThread = useCallback(() => parentMessage !== undefined && openThread(parentMessage), [parentMessage]);

  useDidUpdate(
    () => {
      const threadMessage = async () => {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const response = await chatClient.getMessage(message?.parent_id);
        if (!response?.message) {
          return;
        }
        setParentMessage(response?.message);
      };
      if (message?.parent_id) {
        threadMessage();
      }
    },
    [message, chatClient],
    true
  );

  return (
    parentMessage &&
    !threadList && (
      <HStack
        marginY={0}
        marginRight={isMyMessage() ? 0 : 'auto'}
        marginLeft={isMyMessage() ? 'auto' : 0}
        width="fit-content"
      >
        <Text color="#171717">このスレッドに返信：</Text>
        <Text isTruncated maxW="300" color="#005FFF" onPress={handleOpenThread}>
          {parentMessage?.text}
        </Text>
      </HStack>
    )
  );
};
