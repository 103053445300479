import { Dimensions } from 'react-native';

// @ts-expect-error TS(2305): Module '"~/lib/StreamChatReact"' has no exported m... Remove this comment to see the full error message
import { DeepPartial, Theme } from '~/lib/StreamChatReact';

const windowDimensions = Dimensions.get('window');
export const TEXT_WIDTH = windowDimensions.width * 0.7;
const FONT_SIZE = 17;

export const getChatTheme = (isUDFontEnabled = false) => {
  const fontFamilySettings = isUDFontEnabled
    ? {
        fontFamily: 'BIZUDPGothic_400Regular',
      }
    : {};

  return {
    attachmentPicker: {
      durationText: fontFamilySettings,
      errorButtonText: fontFamilySettings,
      errorText: fontFamilySettings,
    },
    channel: {
      selectChannel: fontFamilySettings,
    },
    channelListHeaderErrorIndicator: {
      errorText: fontFamilySettings,
    },
    channelPreview: {
      date: fontFamilySettings,
      message: fontFamilySettings,
      title: fontFamilySettings,
      unreadTitle: fontFamilySettings,
    },
    dateHeader: {
      text: fontFamilySettings,
    },
    emptyStateIndicator: {
      channelDetails: fontFamilySettings,
      channelTitle: fontFamilySettings,
    },
    iconBadge: {
      unreadCount: fontFamilySettings,
    },
    imageGallery: {
      footer: {
        imageCountText: fontFamilySettings,
      },
      grid: {
        handleText: fontFamilySettings,
      },
      header: {
        dateText: fontFamilySettings,
        usernameText: fontFamilySettings,
      },
      videoControl: {
        durationTextStyle: fontFamilySettings,
      },
    },
    inlineDateSeparator: {
      text: fontFamilySettings,
    },
    loadingErrorIndicator: {
      errorText: fontFamilySettings,
      retryText: fontFamilySettings,
    },
    loadingIndicator: {
      loadingText: fontFamilySettings,
    },
    messageInput: {
      cooldownTimer: {
        text: fontFamilySettings,
      },
      editingBoxHeaderTitle: {
        editingBoxHeaderTitle: fontFamilySettings,
      },
      fileUploadPreview: {
        audioAttachment: {
          progressDurationText: fontFamilySettings,
        },
        filenameText: fontFamilySettings,
        fileSizeText: fontFamilySettings,
      },
      giphyCommandInput: {
        giphyText: fontFamilySettings,
      },
      inputBox: {
        ...fontFamilySettings,
        fontSize: FONT_SIZE,
      },
      sendMessageDisallowedIndicator: {
        text: fontFamilySettings,
      },
      showThreadMessageInChannelButton: {
        text: fontFamilySettings,
      },
      suggestions: {
        command: {
          args: fontFamilySettings,
          title: fontFamilySettings,
        },
        emoji: {
          text: fontFamilySettings,
        },
        header: {
          title: fontFamilySettings,
        },
        mention: {
          name: fontFamilySettings,
          tag: fontFamilySettings,
        },
      },
    },
    messageList: {
      container: {
        backgroundColor: 'white',
      },
      errorNotificationText: fontFamilySettings,
      inlineUnreadIndicator: {
        text: fontFamilySettings,
      },
      messageSystem: {
        dateText: fontFamilySettings,
        text: fontFamilySettings,
      },
      scrollToBottomButton: {
        unreadCountNotificationText: fontFamilySettings,
      },
    },
    messageSimple: {
      actions: {
        buttonText: fontFamilySettings,
      },
      avatarWrapper: {
        leftAlign: {
          justifyContent: 'flex-start',
        },
      },
      card: {
        authorName: fontFamilySettings,
        authorNameFooter: fontFamilySettings,
        footer: {
          description: fontFamilySettings,
          title: fontFamilySettings,
        },
        playButtonStyle: {
          durationTextStyle: fontFamilySettings,
        },
      },
      container: {
        alignItems: 'flex-start',
      },
      content: {
        containerInner: {
          backgroundColor: '#f4f4f4',
          maxWidth: TEXT_WIDTH,
          borderWidth: 0,
          padding: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 20,
        },
        deletedMetaText: fontFamilySettings,
        markdown: {
          autolink: fontFamilySettings,
          blockQuoteText: fontFamilySettings,
          br: fontFamilySettings,
          codeBlock: fontFamilySettings,
          del: fontFamilySettings,
          em: fontFamilySettings,
          heading: fontFamilySettings,
          heading1: fontFamilySettings,
          heading2: fontFamilySettings,
          heading3: fontFamilySettings,
          heading4: fontFamilySettings,
          heading5: fontFamilySettings,
          heading6: fontFamilySettings,
          inlineCode: fontFamilySettings,
          listItemBullet: fontFamilySettings,
          listItemNumber: fontFamilySettings,
          listItemText: fontFamilySettings,
          mailTo: fontFamilySettings,
          mentions: fontFamilySettings,
          newline: fontFamilySettings,
          noMargin: fontFamilySettings,
          paragraph: fontFamilySettings,
          paragraphCenter: fontFamilySettings,
          strong: fontFamilySettings,
          tableHeaderCell: fontFamilySettings,
          text: {
            fontSize: FONT_SIZE,
            ...fontFamilySettings,
          },
          u: fontFamilySettings,
        },
        messageUser: {
          fontSize: 13,
          display: 'none',
          ...fontFamilySettings,
        },
        metaContainer: {
          alignContent: 'center',
        },
        metaText: {
          padding: 2,
          ...fontFamilySettings,
        },
        textContainer: {
          maxWidth: TEXT_WIDTH,
        },
      },
      file: {
        fileSize: fontFamilySettings,
        title: fontFamilySettings,
      },
      gallery: {
        moreImagesText: fontFamilySettings,
        image: { objectFit: 'contain' },
      },
      giphy: {
        cancel: fontFamilySettings,
        giphyHeaderText: fontFamilySettings,
        giphyHeaderTitle: fontFamilySettings,
        send: fontFamilySettings,
        shuffle: fontFamilySettings,
        title: fontFamilySettings,
      },
      pinnedHeader: {
        label: fontFamilySettings,
      },
      replies: {
        messageRepliesText: fontFamilySettings,
      },
      status: {
        readByCount: { color: '#F28E18', fontWeight: '500', ...fontFamilySettings },
      },
    },
    overlay: {
      container: {
        marginTop: 80,
      },
      messageActions: {
        title: fontFamilySettings,
        actionContainer: {
          paddingVertical: 5,
          paddingHorizontal: 10,
        },
      },
      reactions: {
        avatarName: fontFamilySettings,
        title: fontFamilySettings,
      },
    },
    reply: {
      container: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 4,
      },
      messageContainer: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 12,
        borderWidth: 0,
      },
      markdownStyles: {
        text: {},
      },
    },
    thread: {
      newThread: {
        text: fontFamilySettings,
      },
    },
    typingIndicator: {
      text: fontFamilySettings,
    },
  };
};

export const myMessageTheme: DeepPartial<Theme> = {
  messageSimple: {
    content: {
      containerInner: {
        backgroundColor: '#fbe25c',
        borderTopLeftRadius: 20,
      },
      messageUser: {
        display: 'none',
      },
    },
  },
  reply: {
    messageContainer: {
      backgroundColor: 'white',
    },
  },
};
