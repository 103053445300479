import { VStack, StyledProps } from 'native-base';
import React from 'react';

import { SectionTitle } from '~/components';

interface Props {
  title?: string;
  moreLabel?: string;
  onMorePress?: (item: any) => void;
  fontSize?: '' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

export const Section: React.FC<Props & React.PropsWithChildren & StyledProps> = ({
  children,
  title,
  moreLabel,
  onMorePress,
  fontSize = 'xl',
  ...remainingProps
}) => {
  return (
    <VStack space={4} {...remainingProps}>
      {/* @ts-expect-error TS(2322): Type '("" | "sm" | "md" | "lg" | "xl" | "2xl") & (... Remove this comment to see the full error message */}
      <SectionTitle title={title} moreLabel={moreLabel} onMorePress={onMorePress} fontSize={fontSize} />
      <VStack>{children}</VStack>
    </VStack>
  );
};
