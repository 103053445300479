import Ionicons from '@expo/vector-icons/Ionicons';
import { useNavigation } from '@react-navigation/native';
import { Icon, Text, Pressable } from 'native-base';

import { ListBuilder } from '~/components/builder/ListBuilder';
import { Card } from '~/components/Card';

interface Props {
  title?: string;
  queryResult: {
    data: [];
    error: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    errorMessage: string;
    refetch: () => void;
  };
  detailLink?: string;
  moreLink?: string;
  moreLabel?: string;
  max?: any;
  descriptionField?: string | null;
  rounded?: boolean;
  shadow?: number;
}

export const SummaryBuilder: React.FC<Props> = ({
  // @ts-expect-error TS(2339): Property 'useQueryResult' does not exist on type '... Remove this comment to see the full error message
  useQueryResult,
  title,
  descriptionField,
  detailLink,
  moreLink,
  moreLabel,
  max = 5,
  rounded,
  shadow,
}) => {
  const navigation = useNavigation();
  const end = Math.min(max, useQueryResult?.data?.length - 1 ?? 0);
  const spliced = {
    ...useQueryResult,
    data: end > 0 ? useQueryResult?.data?.slice(0, end) : [],
  };

  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; title: string | undefin... Remove this comment to see the full error message
    <Card
      title={title}
      rounded={rounded}
      shadow={shadow}
      right={
        useQueryResult?.data?.length > max ? (
          // @ts-expect-error TS(2769): No overload matches this call.
          <Pressable justifyContent="flex-end" onPress={() => moreLink && navigation.navigate(moreLink)}>
            {moreLabel ? (
              <Text fontSize="sm" textAlign="right" color="muted.500">
                {moreLabel}
              </Text>
            ) : (
              <Icon size="xl" as={<Ionicons name="arrow-forward-circle-outline" />} />
            )}
          </Pressable>
        ) : null
      }
    >
      <ListBuilder
        useQueryResult={spliced}
        // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
        descriptionField={descriptionField}
        detailLink={detailLink}
        emptyMessage=""
        max={max}
      />
    </Card>
  );
};
