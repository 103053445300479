import { Alert, Text, Skeleton, Box, StyledProps } from 'native-base';
import { useMemo } from 'react';

import { EventTimeline } from '~/components/EventListCard/EventTimeline';
import { FarmEvent, useEventsQuery } from '~/hooks/useEventsQuery';

interface Props {
  groupId?: number;
  farmId?: number;
  beginDate: Date;
  endDate: Date;
}

const filterByDate = (data: FarmEvent, beginDate: Date, endDate: Date) => {
  const date = new Date(data.date ?? 0);
  return date >= beginDate && date <= endDate;
};

export const EventListCard: React.FC<Props & StyledProps> = ({
  groupId,
  farmId,
  beginDate,
  endDate,
  ...remainingProps
}) => {
  const events = useEventsQuery({
    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    groupId,
  });

  const filteredEvents = useMemo(() => {
    if (events?.data.length < 1) return [];
    return beginDate && endDate ? events.data.filter((d) => filterByDate(d, beginDate, endDate)) : events.data;
  }, [events.data, beginDate, endDate]);

  return (
    <Box {...remainingProps}>
      {events?.isLoading ? (
        <Skeleton.Text height="12" borderRadius={10} />
      ) : events?.data.length > 0 ? (
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        <EventTimeline groupId={groupId} events={filteredEvents} farmId={farmId} />
      ) : (
        <Text>イベントがありません</Text>
      )}
      {events?.isError ? (
        <Alert status="error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'. */}
          <Text>エラーが発生しました: {events?.error?.error}</Text>
        </Alert>
      ) : null}
    </Box>
  );
};
