import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import { VStack } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetFarmsByIdQuery, useGetFarmsByFarmIdGroupsQuery } from '~/api/uFeedApi';
import { FarmDetailCard } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';
import { Section } from '~/components/Section';
import { SectionList } from '~/components/SectionList';

export const AdminFarmDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetFarmsByIdQuery(queryParams);

  return (
    <BaseScreenBuilder
      title=""
      headerRightLabel="編集"
      onHeaderRightPress={() =>
        Platform.OS === 'web'
          ? // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
            linkTo(`/admin/farms/${queryParams?.id}/create_edit`)
          : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('AdminFarmCreateEdit', queryParams)
      }
    >
      <SectionList>
        <DetailsBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFarmsB... Remove this comment to see the full error message
          queryResult={queryResult}
          emptyMessage="牧場データがありません"
          fields={[
            {
              key: 'name',
              label: '名前',
            },
            {
              key: 'memo',
              label: 'メモ',
            },
            {
              key: 'owner_name',
              label: '牧場オーナー',
            },
          ]}
        />

        <Section title="詳細">
          <VStack space={3}>
            {queryResult.data?.details?.map((detail, index) => <FarmDetailCard item={detail} key={index.toString()} />)}
          </VStack>
        </Section>

        <Section
          title="グループ"
          // @ts-expect-error TS(2322): Type '{ children: Element; title: string; addLabel... Remove this comment to see the full error message
          addLabel="追加"
          onAddPress={() =>
            Platform.OS === 'web'
              ? // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                linkTo(`/admin/group/create_edit/farms/${queryParams?.id}/groups`)
              : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('AdminGroupCreateEdit', {
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                  farmId: queryParams?.id,
                })
          }
        >
          <ListBuilder
            // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFarmsB... Remove this comment to see the full error message
            queryResult={useGetFarmsByFarmIdGroupsQuery({
              // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
              farmId: queryParams?.id,
            })}
            onSelect={(item) => {
              Platform.OS === 'web'
                ? // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                  linkTo(`/admin/farms/${queryParams?.id}/groups/${item?.id}`)
                : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('AdminGroupDetail', {
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    farmId: queryParams?.id,
                    groupId: item?.id,
                  });
            }}
            addLabel="グループを追加"
            onAddPress={() =>
              Platform.OS === 'web'
                ? // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                  linkTo(`/admin/group/create_edit/farms/${queryParams?.id}/groups`)
                : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('AdminGroupCreateEdit', {
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    farmId: queryParams?.id,
                  })
            }
            emptyMessage="グループがありません。「追加」をタップしてグループを追加してください。"
          />
        </Section>
      </SectionList>
    </BaseScreenBuilder>
  );
};
