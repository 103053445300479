import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import {
  usePostFeedDesignsMutation,
  useGetCurrentUserGroupsQuery,
  usePostGroupsByGroupIdGroupFeedDesignsMutation,
} from '~/api/uFeedApi';
import { MultiSelectInput } from '~/components';
import { FormFieldLabel, FormFieldList, errorMessageBuilder } from '~/components/builder';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

const { useRef, useState } = React;

export const FeedDesignCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const formRef = useRef();

  const [update, { isLoading: isUpdating, error }] = usePostFeedDesignsMutation();
  const [postGroupFeedDesign, postGroupFeedDesignResult] = usePostGroupsByGroupIdGroupFeedDesignsMutation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const groups = useGetCurrentUserGroupsQuery();
  const groupOptions = groups.data?.map((group) => ({
    name: group.name,
    id: group.id,
  }));

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[] | undefined>();

  const onCancel = () => {
    navigation.goBack();
  };

  const openMultiSelectScreen = () => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('MultiSelect', {
      data: groupOptions,
      selectedItems: selectedGroupIds,
      onConfirm: (result: any) => {
        setSelectedGroupIds(result);
      },
      onCancel: () => {},
    });
  };

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      key: 'memo',
      label: 'メモ',
    },
    {
      key: 'files',
      label: '添付ファイル',
      type: 'files',
    },
  ];

  return (
    <ScreenWidthModal title="飼料設計作成">
      <BaseScreenBuilder title="飼料設計作成">
        <BaseFormBuilder
          ref={formRef}
          isUpdating={isUpdating}
          error={error}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
          onCancel={onCancel}
          onSubmit={async (sendingData) => {
            setErrorMessages((errorMessages) => []);
            const formData = new FormData();
            formData.append('feed_design[name]', sendingData.name);
            formData.append('feed_design[memo]', sendingData.memo ? sendingData.memo : '');
            sendingData.files?.forEach((file: any) => {
              // @ts-expect-error TS(2345): Argument of type '{ type: any; name: any; uri: any... Remove this comment to see the full error message
              formData.append('feed_design[files][]', {
                type: file?.assets?.[0]?.mimeType,
                name: file?.assets?.[0]?.name,
                uri: file?.assets?.[0]?.uri,
              });
            });

            update({
              // @ts-expect-error TS(2741): Property 'feed_design' is missing in type 'FormDat... Remove this comment to see the full error message
              body: formData,
            })
              .unwrap()
              .then(async (resp) => {
                if (!selectedGroupIds) {
                  navigation.goBack();
                  return;
                }
                const addPromises = selectedGroupIds
                  ?.map((id) => {
                    return postGroupFeedDesign({
                      groupId: id,
                      body: {
                        group_feed_design: {
                          // @ts-expect-error TS(2571): Object is of type 'unknown'.
                          feed_design_id: resp.id,
                        },
                      },
                    }).unwrap();
                  })
                  ?.filter((promise) => !!promise);

                const addResult = await Promise.all(addPromises)
                  .then((res) => navigation.goBack())
                  .catch((error) => {
                    setErrorMessages(errorMessageBuilder(error, fields));
                    console.error('publishing feed design to groups failed: ', error);
                  });
              })
              .catch((error) => {
                setErrorMessages(errorMessageBuilder(error, fields));
                console.error('update error: ', error);
              });
          }}
        >
          <FormFieldList>
            <FormFieldLabel label="公開先グループ" />
            <MultiSelectInput
              // @ts-expect-error TS(2322): Type '{ name: string; id: number | undefined; }[] ... Remove this comment to see the full error message
              options={groupOptions}
              // @ts-expect-error TS(2322): Type 'number[] | undefined' is not assignable to t... Remove this comment to see the full error message
              selectedItems={selectedGroupIds}
              // @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<number[] | undefined... Remove this comment to see the full error message
              onSelectedItemsChange={setSelectedGroupIds}
            />
          </FormFieldList>
        </BaseFormBuilder>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
