import { VStack, Text } from 'native-base';
import React from 'react';

import { useGetAdditivesByAdditiveIdQuery } from '~/api/uFeedApi';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

interface Props {
  additiveId: number;
}

export const PreviewAdditive: React.FC<Props> = React.memo(({ additiveId }) => {
  const additive = useGetAdditivesByAdditiveIdQuery({ additiveId });

  if (additive.isLoading || additive.isError) {
    return null;
  }

  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="gray.100"
      overflow="hidden"
    >
      <Text marginBottom={2}>添加剤追加</Text>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '({ data?: undefined; error?: undefined; fulf... Remove this comment to see the full error message
        queryResult={additive}
        emptyMessage="添加剤データがありません"
        space={2}
        fields={[
          {
            key: 'memo',
            label: '内容',
          },
          {
            key: 'date',
            type: 'date',
            label: '追加日',
          },
          {
            key: 'user',
            type: 'user',
            label: '担当者',
          },
        ]}
      />
    </VStack>
  );
});
