import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { Text } from 'native-base';
import { useState } from 'react';
import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { BaseFormBuilder, BaseScreenBuilder } from '~/components/builder';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { queryChannel } from '~/utils/queryChannels';

type ChannelData = { channel_overview: string; name: string };

export const ChatChannelOverviewEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const linkTo = useLinkTo();
  const currentUser = useGetCurrentUserQuery({});

  const { appChannel, setAppChannel, isUserConnected, chatClient, chatUserId } = useStreamChatContext();

  const [channelData, setChannelData] = useState<ChannelData | undefined>(
    // @ts-expect-error TS(2345): Argument of type '{ channel_overview: unknown; nam... Remove this comment to see the full error message
    appChannel ? { channel_overview: appChannel?.data?.channel_overview, name: appChannel?.data?.name } : undefined
  );

  useDidUpdate(
    () => {
      const setChannel = async (id: string) => {
        const newChannel = await queryChannel(chatClient, id);
        setAppChannel(newChannel);
        setChannelData({ channel_overview: newChannel?.data?.channel_overview, name: newChannel?.data?.name });
      };
      // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
      if (params?.channelId && isUserConnected) {
        // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
        setChannel(params?.channelId);

        appChannel?.watch();
      }
    },
    // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
    [params?.channelId, isUserConnected, chatClient],
    true
  );

  const onSubmit = async (sendingData: any) => {
    const { channel_overview, name } = sendingData;
    try {
      await appChannel?.updatePartial({ set: { channel_overview, name } });
      // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
      navigation?.canGoBack() ? navigation.goBack() : linkTo(`/chat/memberlist/${params?.channelId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const errorComponent = <Text>この画面は表示できません</Text>;

  if (appChannel?.data?.isDM || appChannel?.data?.is_my_channel) {
    return errorComponent;
  }

  if (
    // @ts-expect-error TS(2365): Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
    !appChannel?.data?.cid.match(/^team\:u-feed\-group.+/)?.length > 0 &&
    // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
    appChannel?.state.members[chatUserId]?.role !== 'owner' &&
    !currentUser.data?.roles?.includes('group_admin')
  ) {
    return errorComponent;
  }
  return (
    <BaseScreenBuilder title="チャンネル概要編集">
      <BaseFormBuilder
        onSubmit={onSubmit}
        // @ts-expect-error TS(2322): Type 'ChannelData | undefined' is not assignable t... Remove this comment to see the full error message
        queryResult={{ data: channelData }}
        onCancel={() =>
          // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
          navigation?.canGoBack() ? navigation.goBack() : linkTo(`/chat/memberlist/${params?.channelId}`)
        }
        fields={[
          {
            key: 'name',
            label: 'チャンネル名',
            type: 'text',
          },

          {
            key: 'channel_overview',
            label: '概要',
            type: 'textarea',
          },
        ]}
      />
    </BaseScreenBuilder>
  );
};
