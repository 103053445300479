import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, HStack, Text, Icon, VStack, Heading, Button, StyledProps, Hidden } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { SectionTitle, ActivityLineChart, ActivityStackChart } from '~/components/';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
    type: 'line' | 'stack';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  type: 'line' | 'stack';
  showTable?: boolean;
}

export const ActivityChartSwitcher: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    queryParameters,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    type,
    showTable = false,
    ...remainingProps
  }) => {
    const { chatClient } = useStreamChatAuthContext();
    const navigation = useNavigation();
    const [activityChartType, setActivityChartType] = useState<'line' | 'stack'>(type);
    const { width: windowWidth } = useWindowDimensions();
    const linkTo = useLinkTo();
    const shareButton = () => {
      return (
        <Button
          mt={2}
          variant="unstyled"
          onPress={async () => {
            const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
            const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/graphs/activity/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?range=${range}&type=${activityChartType}`;

            const channel = chatClient?.channel('team', chatChannelId);

            if (Platform.OS === 'web') {
              linkTo(`/chat/${channel.id}?message=${encodeURIComponent(message)}`);
            } else {
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('ChatChannelMessages', { channelId: channel.id, message });
            }
          }}
          leftIcon={<Icon as={Ionicons} name="share-outline" size="md" />}
          testID="activity-chart-share-button"
        >
          チャットで共有
        </Button>
      );
    };

    return (
      <VStack space={2} {...remainingProps}>
        <Hidden from="md">
          <>
            <SectionTitle title="活動量" />
            <Button.Group space={6} size="md" flexGrow={1} mb={4}>
              <Button
                variant={activityChartType === 'stack' ? 'link' : 'unstyled'}
                disabled={activityChartType !== 'stack'}
                onPress={() => setActivityChartType('line')}
                px={0}
              >
                <Box
                  borderBottomWidth={2}
                  borderBottomColor={activityChartType === 'stack' ? 'transparent' : '#0EC9E5'}
                  padding={1}
                >
                  <Text color={activityChartType === 'stack' ? 'gray.400' : 'black'} fontSize="md">
                    変化量
                  </Text>
                </Box>
              </Button>
              <Button
                variant={activityChartType === 'line' ? 'link' : 'unstyled'}
                disabled={activityChartType !== 'line'}
                onPress={() => setActivityChartType('stack')}
                px={0}
              >
                <Box
                  borderBottomWidth={2}
                  borderBottomColor={activityChartType === 'line' ? 'transparent' : '#0EC9E5'}
                  padding={1}
                >
                  <Text color={activityChartType === 'line' ? 'gray.400' : 'black'} fontSize="md">
                    内訳
                  </Text>
                </Box>
              </Button>
            </Button.Group>
            <Box paddingX="auto">
              {activityChartType === 'line' ? (
                <ActivityLineChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  shareButton={shareButton()}
                  width={Math.min(windowWidth - 60, 400)}
                  height={Math.min(windowWidth - 60, 400)}
                />
              ) : (
                <ActivityStackChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  shareButton={shareButton()}
                  width={Math.min(windowWidth - 60, 400)}
                  height={Math.min(windowWidth - 60, 400)}
                />
              )}
            </Box>
          </>
        </Hidden>
        <Hidden till="md">
          <HStack flexWrap="wrap" style={{ gap: 32 }}>
            <Box>
              <HStack justifyContent="center">
                <Heading fontSize="lg" alignItems="center" mb={4}>
                  活動量 | 変化量
                </Heading>
              </HStack>
              <ActivityLineChart
                chatChannelId={chatChannelId}
                farmId={farmId}
                groupId={groupId}
                endDate={endDate}
                range={range}
                paddingX={4}
                showTable={showTable}
                shareButton={shareButton()}
              />
            </Box>
            <Box>
              <HStack justifyContent="center">
                <Heading fontSize="lg" alignItems="center" mb={4}>
                  活動量 | 内訳
                </Heading>
              </HStack>
              <ActivityStackChart
                chatChannelId={chatChannelId}
                farmId={farmId}
                groupId={groupId}
                endDate={endDate}
                range={range}
                paddingX={4}
                showTable={showTable}
                shareButton={shareButton()}
              />
            </Box>
          </HStack>
        </Hidden>
      </VStack>
    );
  }
);
