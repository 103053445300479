import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { Text, Alert, Button } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';
import { Channel } from 'stream-chat';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useUserChannel } from '~/hooks/useUserChannel';
import { useYomi } from '~/hooks/useYomi';

export const ChatDMCreateEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const [isPost, setIsPost] = useState(false);
  const [channelType, setChannelType] = useState('');
  const [groupsOption, setGroupsOption] = useState([]);
  const [membersOption, setMembersOption] = useState<{ id: string; name: string }[]>([]);
  const [memberNamesById, setMemberNamesById] = useState<Record<string, string>>({});
  const [selectedChatId, setSelectedChatId] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const { chatClient, chatUserId } = useStreamChatAuthContext();
  const [members, setMembers] = useState([]);
  const [channels, setChannels] = useState<Channel[] | []>([]);

  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery();
  const { createUserChannel, errorMessages } = useUserChannel();

  const { searchYomi } = useYomi();

  const [isLoading, setIsLoading] = useState(false);

  useDidUpdate(() => {
    const queryChannels = async () => {
      setIsLoading(true);
      const filter = {
        members: {
          $eq: members,
        },
        isDM: {
          $eq: true,
        },
        type: {
          $eq: 'messaging',
        },
      };

      const option = { limit: 30 };

      const channels = await chatClient.queryChannels(filter, undefined, option);

      setChannels(channels);
      setIsLoading(false);
    };
    queryChannels();
  }, [members]);

  const goToDM = (channel: Channel) => {
    if (Platform.OS === 'web') {
      linkTo(`/dm/${String(channel?.data?.id || '')}`);
    } else {
      navigation.goBack();
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      navigation.navigate('ChatChannelMessages', { channelId: channel.id });
    }
  };

  const onSubmit = async (sendingData: any) => {
    const createChannelResult = await createUserChannel({
      name: sendingData.members.map((id: any) => memberNamesById[id]).join([', ']),
      members: sendingData.members,
      chatTeamId: sendingData.chatTeamId,
      isDM: true,
    });
    if (createChannelResult) {
      if (!('isError' in createChannelResult) || !createChannelResult.isError) {
        navigation.goBack();
      }
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  useDidUpdate(
    () => {
      const users = currentUserGroupsQuery.data
        ?.map((g) =>
          g.approved_users?.map((u) => ({
            ...u,
            groupName: g.name,
          }))
        )
        .flat();

      const chatIds = users?.map((u) => ({
        // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type '{ ... Remove this comment to see the full error message
        id: u?.chat_user_id,
        name: `${u?.name} (${u?.groupName})`,
      }));

      setMembersOption(chatIds ?? []);
      setMemberNamesById(
        users?.reduce(
          (acc, cur) => ({
            ...acc,
            // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type '{ ... Remove this comment to see the full error message
            [cur?.chat_user_id]: cur.name,
          }),
          {}
        ) ?? {}
      );

      const groups = currentUserGroupsQuery.data?.map((g) => {
        const detail =
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          g?.farm?.name === g?.farm?.account?.name ? g?.farm?.name : `${g?.farm?.name} / ${g?.farm?.account?.name}`;
        return {
          value: g?.chat_team_id,
          label: `${g?.name} (${detail})`,
        };
      });

      // @ts-expect-error TS(2345): Argument of type '{ value: string | undefined; lab... Remove this comment to see the full error message
      setGroupsOption(groups);

      setDefaultValues({
        // @ts-expect-error TS(2345): Argument of type '{ chatTeamId: string | undefined... Remove this comment to see the full error message
        chatTeamId: groups?.[0]?.value,
        members: [chatUserId],
      });
    },
    [currentUserGroupsQuery.data],
    true
  );

  useDidUpdate(() => {
    const chatIds = currentUserGroupsQuery.data
      ?.filter((d) => d.chat_team_id === selectedChatId)
      .map((g) =>
        g.approved_users?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          name: `${u.name}`,
        }))
      )
      .flat();
    // @ts-expect-error TS(2345): Argument of type '({ id: any; name: string; } | un... Remove this comment to see the full error message
    setMembersOption(chatIds);
  }, [selectedChatId]);

  if ((groupsOption?.length <= 0 ?? true) || !defaultValues) {
    return null;
  }

  return (
    <BaseScreenBuilder title="ダイレクト・メッセージを開始">
      <BaseFormBuilder
        isLoading={isLoading}
        hideBottom={channels.length > 0}
        // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
        queryResult={{}}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onFieldUpdate={(updated) => {
          setMembers(updated.members);
          if (updated.chatTeamId !== selectedChatId) {
            updated.setValue('members', [chatUserId]);
          }
          if (updated.chatTeamId) {
            setSelectedChatId(updated.chatTeamId);
          }
        }}
        emptyMessage="グループがありません"
        defaultValues={defaultValues}
        fields={[
          {
            key: 'chatTeamId',
            type: 'select',
            label: '所属グループ',
            watch: true,
            options: groupsOption,
            rules: {
              required: {
                value: true,
                message: 'グループを選択してください',
              },
            },
          },
          {
            key: 'members',
            type: 'sectionedMultiselect',
            label: 'メンバー',
            options: membersOption,
            filterFunction: async (query: string) => {
              const matchedYomi = searchYomi(query);
              return membersOption.filter(
                (o): o is { id: string; name: string } =>
                  typeof o.id !== 'undefined' && matchedYomi.findIndex((m) => m.chatUserId === o.id) >= 0
              );
            },
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            unchangeableItems: [chatUserId],
            watch: true,
            rules: {
              required: {
                value: true,
                message: 'メンバーを選択してください',
              },
            },
          },
        ]}
      >
        {channels.length > 0 &&
          channels.map((c) => <Button onPress={() => goToDM(c)}>{`DMを表示する:${c?.data?.name}`}</Button>)}
        <Alert>
          ダイレクト・メッセージは、選択したメンバーのみ閲覧できます。開始後にメンバーを変更することはできません。
        </Alert>
      </BaseFormBuilder>
      {errorMessages
        ? errorMessages.map((errorMessage, i) => (
            <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
              {errorMessage}
            </Text>
          ))
        : null}
    </BaseScreenBuilder>
  );
};
