import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';

import {
  useGetGroupsByGroupIdVisitsQuery,
  useGetGroupsByGroupIdAppliedFeedDesignsQuery,
  useGetGroupsByGroupIdAdditivesQuery,
  User,
} from '~/api/uFeedApi';

interface Props {
  groupId: number;
}

export type FarmEvent =
  | { type: 'additives'; id?: number; user?: User; date?: number; memo?: string }
  | { type: 'appliedFeedDesigns'; id?: number; user?: User; feedDesignId?: number; date?: string; memo?: string }
  | { type: 'visits'; id?: number; date?: string; memo?: string; user?: User };

export const useEventsQuery = ({ groupId }: Props) => {
  const [data, setData] = useState<FarmEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});

  const visits = useGetGroupsByGroupIdVisitsQuery({
    groupId,
  });

  const appliedFeedDesigns = useGetGroupsByGroupIdAppliedFeedDesignsQuery({
    groupId,
  });
  const additives = useGetGroupsByGroupIdAdditivesQuery({
    groupId,
  });

  useDidUpdate(
    () => {
      const merged = [
        ...(visits.data || []).map<FarmEvent>((visit) => ({
          type: 'visits',
          id: visit?.id,
          memo: visit?.memo,
          date: visit?.date,
          user: visit?.user,
        })),
        ...(appliedFeedDesigns.data || []).map<FarmEvent>((appliedFeedDesign) => ({
          type: 'appliedFeedDesigns',
          id: appliedFeedDesign?.id,
          memo: appliedFeedDesign?.memo,
          feedDesignId: appliedFeedDesign?.feed_design_id,
          date: appliedFeedDesign?.date,
          user: appliedFeedDesign?.user,
        })),
        ...(additives.data || []).map<FarmEvent>((additive) => ({
          type: 'additives',
          id: additive?.id,
          memo: additive?.memo,
          date: additive?.date,
          user: additive?.user,
        })),
      ];
      setData(merged.sort((a, b) => new Date(b?.date ?? 0).valueOf() - new Date(a?.date ?? 0).valueOf()));
    },
    [visits.data, appliedFeedDesigns.data, additives.data],
    true
  );

  useDidUpdate(() => {
    setIsLoading(visits.isLoading || appliedFeedDesigns.isLoading || additives.isLoading);
  }, [visits.isLoading, appliedFeedDesigns.isLoading, additives.isLoading]);

  useDidUpdate(() => {
    setIsSuccess(visits.isSuccess || appliedFeedDesigns.isSuccess || additives.isSuccess);
  }, [visits.isSuccess, appliedFeedDesigns.isSuccess, additives.isSuccess]);

  useDidUpdate(() => {
    setIsError(visits.isError || appliedFeedDesigns.isError || additives.isError);
  }, [visits.isError, appliedFeedDesigns.isError, additives.isError]);

  useDidUpdate(() => {
    setError({
      visits: visits.error,
      appliedFeedDesigns: appliedFeedDesigns.error,
      additives: additives.error,
    });
  }, [visits.error, appliedFeedDesigns.error, additives.error]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
