import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { HStack, Text, Icon, Pressable } from 'native-base';
import { memo } from 'react';
import { Platform } from 'react-native';

const WAITING_TIME_FOR_CLOSING_DRAWER = 250; // 250 ms

interface Props {
  icon?: string;
  title: string;
  navigateTo?: string;
  navigateToOptions?: object;
  onPress?: () => void;
  closeDrawer?: () => void;
  testID?: string;
}

export const DrawerMenuItem: React.FC<Props> = memo(
  ({ icon, title, navigateTo, navigateToOptions, onPress, closeDrawer, testID }) => {
    const navigation = useNavigation();
    const handleOnPress = () => {
      if (navigateTo) {
        if (navigateToOptions) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('HomeNavigator', {
            screen: navigateTo,
            params: navigateToOptions,
          });
        } else if (Platform.OS === 'web') {
          requestAnimationFrame(() => {
            closeDrawer?.();
            setTimeout(() => {
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('MenuNavigator', {
                screen: navigateTo,
              });
            }, WAITING_TIME_FOR_CLOSING_DRAWER);
          });
        } else {
          // @ts-expect-error TS(2769): No overload matches this call.
          navigation.navigate(navigateTo);
        }
      } else if (onPress) {
        onPress();
      }
    };

    return (
      <Pressable onPress={handleOnPress}>
        <HStack space={4} maxHeight={8} justifyContent="center" alignContent="center">
          {/* @ts-expect-error TS(2786): 'Ionicons' cannot be used as a JSX component. */}
          {icon ? <Icon size="xl" as={<Ionicons name={icon} />} alignSelf="center" /> : null}
          <Text textAlign="left" flexShrink={1} maxHeight={8} width="100%" alignSelf="center" fontWeight="bold">
            <Text size="lg" fontWeight="bold" alignSelf="center" flex={1}>
              {title}
            </Text>
          </Text>
          <Icon size="md" as={<Ionicons name="chevron-forward-outline" />} alignSelf="center" color="gray.400" />
        </HStack>
      </Pressable>
    );
  }
);
