import { SearchIndex } from 'emoji-mart';
import { Heading, HStack, Select, Spinner, Switch, Text, VStack } from 'native-base';
import normalizeUrl from 'normalize-url';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Attachment,
  Channel,
  CustomMessageActionsListProps,
  MessageInput,
  MessageList,
  renderText,
  ScrollToBottomButton,
  Thread,
  Window,
} from 'stream-chat-react';
import { channelType, getStreamChatChannelTypeById } from '../ChatChannelMessagesScreen/channelType';
import { ChatCustomThreadInput, CustomMessageAvatar } from '~/components';
import { ChatCustomMessage } from '~/components/ChatCustomMessage';
import { ChatCustomPinIndicator } from '~/components/ChatCustomPinIndicator';
import ChatCustomReactionSelectorWeb from '~/components/ChatCustomReactionSelectorWeb.web';
import ChatCustomReactionsListWeb from '~/components/ChatCustomReactionsListWeb';
import { anchorComponent } from '~/components/CustomAnchorComponent.web';
import { CustomSuggestionItem } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionItem.web';
import { CustomSuggestionList } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionList.web';
import { CustomDateSeparator } from '~/components/CustomDateSeparator';
import { CustomHeader } from '~/components/CustomHeader';

import { CustomMessageActionsListWeb } from '~/components/CustomMessageActionsListWeb';
import CustomMessageOptions from '~/components/CustomMessageOptions.web';
import { CustomMessageTimestamp } from '~/components/CustomMessageTimestamp.web';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { useDraft } from '~/hooks/useDraft.web';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import { useAllChannel } from './index.web';
import type { Channel as StreamChannel, UserResponse } from 'stream-chat';
import type { AttachmentProps } from 'stream-chat-react';

const ChannelWindow = (props: { width: number }) => {
  const { width } = props;
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();

  const customRenderText = useCallback(
    (text?: string, mentionedUsers?: UserResponse[]) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  const CustomThreadInputWrapper = useCallback(
    () => <ChatCustomThreadInput isChecked={isCheckedRef.current} toggleChecked={toggleCheckedRef} isThread />,
    [isCheckedRef, toggleCheckedRef]
  );

  return (
    <HStack width={width}>
      <Window hideOnThread>
        <MessageList messageLimit={20} renderText={customRenderText} />
        <MessageInput
          overrideSubmitHandler={overrideSubmitHandler}
          shouldSubmit={shouldSubmit}
          grow
          mentionQueryParams={{
            options: { limit: 100 },
          }}
        />
      </Window>
      <Thread
        Input={CustomThreadInputWrapper}
        additionalMessageInputProps={{
          overrideSubmitHandler,
          shouldSubmit,
          grow: true,
          mentionQueryParams: {
            options: { limit: 100 },
          },
        }}
      />
    </HStack>
  );
};

const ChannelColumnInner = (props: { channel: StreamChannel; width: number }) => {
  const { channel, width } = props;

  const isDM = useMemo(() => channelType(channel?.cid ?? '') === 'DM', [channel]);

  const CustomEditInputWrapper = useCallback(() => <ChatCustomThreadInput isEdit />, []);

  const renderAttachment = useCallback((props: AttachmentProps) => {
    // Remove duplicate attachments by title and title_link
    const uniqueAttachments = props.attachments.filter(
      (item, index, self) =>
        !item.og_scrape_url || // affects only OGAttachment
        index ===
          self.findIndex(
            (t) =>
              !!t.og_scrape_url && // comparison target must be OGAttachment
              t.title === item.title &&
              (t.title_link ? normalizeUrl(t.title_link) : '') ===
                (item.title_link ? normalizeUrl(item.title_link) : '')
          )
    );
    return <Attachment {...props} attachments={uniqueAttachments} />;
  }, []);

  const renderUnreadMessagesSeparatorEmpty = useCallback(() => {
    return null;
  }, []);

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  return (
    <HStack flex={1} width="100%">
      <Channel
        channel={channel}
        channelQueryOptions={{
          messages: {
            limit: 20,
          },
        }}
        Avatar={CustomMessageAvatar}
        EditMessageInput={CustomEditInputWrapper}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<Omit<any, "ref"> &... Remove this comment to see the full error message
        ReactionSelector={ChatCustomReactionSelectorWeb}
        ReactionsList={ChatCustomReactionsListWeb}
        // @ts-expect-error TS(2322): Type '(props: Props) => React.JSX.Element' is not ... Remove this comment to see the full error message
        AutocompleteSuggestionList={CustomSuggestionList}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<CustomSuggestionIt... Remove this comment to see the full error message
        AutocompleteSuggestionItem={CustomSuggestionItem}
        Message={ChatCustomMessage}
        MessageNotification={ScrollToBottomButton}
        CustomMessageActionsList={renderMessageActionList}
        MessageTimestamp={CustomMessageTimestamp}
        MessageOptions={CustomMessageOptions}
        Input={ChatCustomThreadInput}
        Attachment={renderAttachment}
        HeaderComponent={CustomHeader}
        emojiSearchIndex={SearchIndex}
        DateSeparator={CustomDateSeparator}
        UnreadMessagesSeparator={channel?.countUnread() == 0 ? renderUnreadMessagesSeparatorEmpty : undefined}
        PinIndicator={ChatCustomPinIndicator}
      >
        <ChannelWindow width={width} />
      </Channel>
    </HStack>
  );
};

type Props = {
  width: number;
  channel?: StreamChannel;
};

export const ChannelColumn = ({ width: columnWidth, channel: propChannel }: Props) => {
  const [selectedChannel, setSelectedChannel] = useState<StreamChannel | undefined>(propChannel);

  const { allChannels } = useAllChannel();
  const { pinnedChannels, addPinnedChannel, removePinnedChannel } = usePinnedChannels();

  useEffect(() => {
    setSelectedChannel(propChannel);
  }, [propChannel]);

  const handleSelectChannel = (cid: string) => {
    const channel = allChannels.find((c) => c.cid === cid);
    if (!channel) {
      console.log('channel not found: ', { cid });
      return;
    }
    setSelectedChannel(channel);
    channel.watch();
  };

  const handleClickPinButton = useCallback(() => {
    if (!selectedChannel) return;
    if (pinnedChannels.includes(selectedChannel.cid)) {
      removePinnedChannel(selectedChannel.cid);
    } else {
      addPinnedChannel(selectedChannel.cid).then(() => {
        setSelectedChannel(undefined);
      });
    }
  }, [selectedChannel, removePinnedChannel, addPinnedChannel]);

  if (allChannels?.length < 1) {
    return <Spinner />;
  }
  return (
    <VStack width={columnWidth} height="100%" flexGrow={0}>
      <HStack>
        <Heading size="md">チャンネル</Heading>
        {selectedChannel && (
          <HStack>
            <Text>ピン留め: </Text>
            <Switch
              size="sm"
              onToggle={handleClickPinButton}
              isChecked={pinnedChannels.includes(selectedChannel.cid)}
            />
          </HStack>
        )}
      </HStack>
      <Select selectedValue={selectedChannel?.cid ?? ''} onValueChange={handleSelectChannel} flexGrow={0}>
        {allChannels.map((channel) => (
          <Select.Item label={channel.data?.name ?? 'no named'} value={channel.cid} key={channel.cid} />
        ))}
      </Select>

      {selectedChannel && <ChannelColumnInner channel={selectedChannel} width={columnWidth} />}
    </VStack>
  );
};

export const ChannelColumnWithId = ({ width, channelId }: { width: number; channelId: string }) => {
  const { chatClient } = useStreamChatAuthContext();
  const [channel, setChannel] = useState<StreamChannel | undefined>();

  useEffect(() => {
    const initChannel = async (id: string) => {
      const channelId = id?.split(':').slice(-1).join('');
      const channel = chatClient?.channel(getStreamChatChannelTypeById(id), channelId);

      if (!channel?.initialized) {
        await channel.watch();
      }

      setChannel(channel);
    };

    if (initChannel) {
      initChannel(channelId);
    }
  }, [channelId]);

  return <ChannelColumn width={width} channel={channel} />;
};
