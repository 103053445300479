import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { usePostFeedDesignsMutation } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { CreateFormBuilder } from '~/components/builder/CreateFormBuilder';

export const GroupFeedDesignCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const [update, { isLoading: isUpdating, data, error }] = usePostFeedDesignsMutation();

  return (
    <BaseScreenBuilder title="グループ飼料設計作成">
      <CreateFormBuilder
        isUpdating={isUpdating}
        // @ts-expect-error TS(2322): Type 'FetchBaseQueryError | SerializedError | unde... Remove this comment to see the full error message
        error={error}
        fields={[
          {
            key: 'name',
            // @ts-expect-error TS(2322): Type '"select"' is not assignable to type '"text" ... Remove this comment to see the full error message
            type: 'select',
            label: '飼料設計',
            options: [
              {
                value: 1,
                labe: '飼料設計1',
              },
            ],
          },
        ]}
        onSubmit={(sendingData) =>
          update({
            body: {
              feed_design: {
                ...sendingData,
              },
            },
          })
            .unwrap()
            .then((resp) => navigation.goBack())
            .catch((error) => console.error('update error: ', error))
        }
      />
    </BaseScreenBuilder>
  );
};
