import { VStack, Text } from 'native-base';
import React from 'react';

import { useGetVisitsByVisitsIdQuery } from '~/api/uFeedApi';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

interface Props {
  groupId: number;
  visitsId: number;
}

export const PreviewVisit: React.FC<Props> = React.memo(({ groupId, visitsId }) => {
  const visit = useGetVisitsByVisitsIdQuery({ visitsId });

  if (visit.isLoading || visit.isError) {
    return null;
  }

  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="gray.100"
      overflow="hidden"
    >
      <Text marginBottom={2}>訪問履歴</Text>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '({ data?: undefined; error?: undefined; fulf... Remove this comment to see the full error message
        queryResult={visit}
        emptyMessage="訪問データがありません"
        space={2}
        fields={[
          {
            key: 'memo',
            label: 'メモ',
          },
          {
            key: 'date',
            type: 'date',
            label: '訪問日',
          },
          {
            key: 'user',
            type: 'user',
            label: '訪問者',
          },
        ]}
      />
    </VStack>
  );
});
