import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation } from '@react-navigation/native';
import { Button, VStack } from 'native-base';
import * as React from 'react';

import {
  useGetFeedDesignsByIdQuery,
  useGetFeedDesignsByFeedDesignsIdGroupsQuery,
  useDeleteFeedDesignsByIdMutation,
  useGetCurrentUserQuery,
} from '~/api/uFeedApi';
import { ItemList, ListItem } from '~/components';
import { DetailList, DetailListItem } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

const { useRef, useState } = React;

export const FeedDesignDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'feedDesignId' does not exist on type '{}... Remove this comment to see the full error message
  const queryResult = useGetFeedDesignsByIdQuery({ id: queryParams?.feedDesignId });
  const [deleteFeedDesignsById, deleteFeedDesignsResp] = useDeleteFeedDesignsByIdMutation();
  const currentUser = useGetCurrentUserQuery({});
  const groups = useGetFeedDesignsByFeedDesignsIdGroupsQuery({
    // @ts-expect-error TS(2339): Property 'feedDesignId' does not exist on type '{}... Remove this comment to see the full error message
    feedDesignsId: queryParams?.feedDesignId,
  });

  const [canEdit, setCanEdit] = useState(false);

  useDidUpdate(
    () => {
      if (currentUser.data && queryResult.data) {
        if (
          //今は作成者のみ削除可能、将来的にはアカウント管理者も削除できるようにする
          //currentUser.data?.roles?.some((role) => role === 'account_admin') ||
          currentUser.data?.id === queryResult.data?.user?.id
        ) {
          setCanEdit(true);
        }
      }
    },
    [currentUser.data, queryResult.data],
    true
  );

  return (
    <BaseScreenBuilder title="">
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '(() => MutationActionCreatorResult<MutationD... Remove this comment to see the full error message
        onDeletePress={
          canEdit
            ? () => {
                // @ts-expect-error TS(2339): Property 'feedDesignId' does not exist on type '{}... Remove this comment to see the full error message
                return deleteFeedDesignsById({ id: queryParams?.feedDesignId });
              }
            : undefined
        }
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFeedDe... Remove this comment to see the full error message
        queryResult={queryResult}
        emptyMessage="飼料設計データがありません"
        fields={[
          {
            key: 'name',
            label: '名前',
          },
          {
            key: 'memo',
            label: 'メモ',
          },
          {
            key: 'user',
            label: '作成者',
            type: 'user',
          },
          {
            key: 'files',
            label: '添付ファイル',
            type: 'files',
          },
        ]}
      >
        <DetailList>
          <DetailListItem title="公開先グループ">
            <VStack paddingY={3}>
              <ItemList>
                {groups.data?.map((group) => (
                  <ListItem
                    key={group.id}
                    title={group.name}
                    arrow
                    onPress={() =>
                      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('GroupDetail', {
                        // @ts-expect-error TS(2339): Property 'farm_id' does not exist on type 'Group'.
                        farmId: group.farm_id,
                        groupId: group.id,
                      })
                    }
                  />
                ))}
              </ItemList>
            </VStack>
          </DetailListItem>
        </DetailList>
      </DetailsBuilder>
      {canEdit && (
        <Button
          variant="outline"
          marginTop={4}
          onPress={() =>
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('FeedDesignEdit', {
              // @ts-expect-error TS(2339): Property 'feedDesignId' does not exist on type '{}... Remove this comment to see the full error message
              id: queryParams?.feedDesignId,
            })
          }
        >
          編集
        </Button>
      )}
    </BaseScreenBuilder>
  );
};
