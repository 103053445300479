import { useRoute, useNavigation } from '@react-navigation/native';
import { HStack, Text } from 'native-base';
import * as React from 'react';

import { useGetFeedDesignsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { Icon } from '~/components/builder/Icon';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const FeedDesignListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = { ...params } || {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetFeedDesignsQuery(queryParams);

  return (
    <BaseScreenBuilder
      title=""
      headerRightLabel="追加"
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      onHeaderRightPress={() => navigation.navigate('FeedDesignCreate', {})}
    >
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        onSelect={(item) =>
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('FeedDesignDetail', {
            feedDesignId: item.id,
          })
        }
        DescriptionComponent={(item) => {
          return item?.files?.length > 0 ? (
            <HStack alignContent="center">
              <Icon name="document-outline" />
              <Text fontSize="lg">{item?.files?.length}</Text>
            </HStack>
          ) : null;
        }}
        emptyMessage="飼料設計データがありません。「追加」ボタンをタップしてデータを追加してください。"
      />
    </BaseScreenBuilder>
  );
};
