import { Ionicons } from '@expo/vector-icons';
import { useRoute, useNavigation } from '@react-navigation/native';
import { Icon } from 'native-base';
import * as React from 'react';

import { ListItem } from '~/components';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { ShowPinMessagesProvider } from '~/contexts/PinMessageContext';

export const ChatDMListScreen: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute();
  // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
  const channelId = route?.params?.channelId;
  // @ts-expect-error TS(2339): Property 'messageId' does not exist on type 'objec... Remove this comment to see the full error message
  const messageId = route?.params?.messageId;

  return (
    <ShowPinMessagesProvider>
      <ChatChannelListWeb
        disableAddChannel={false}
        type="dm"
        channelId={channelId}
        messageId={messageId}
        addChannel={
          <ListItem
            key="add-button"
            title="ダイレクト・メッセージを追加"
            left={<Icon as={Ionicons} name="add-circle" color="blue.400" size="2xl" />}
            // @ts-expect-error TS(2769): No overload matches this call.
            onPress={() => navigation.navigate('ChatDMCreateEdit')}
            link
          />
        }
        isDM
      />
    </ShowPinMessagesProvider>
  );
};
