import { Ionicons } from '@expo/vector-icons';
import { Image } from 'native-base';
import { memo } from 'react';

import { useCustomMessageAvatar } from '~/hooks/useCustomMessageAvatar';
import type { AvatarProps } from '~/lib/StreamChatReact';

const UnmemoizedCustomMessageAvatar: React.FC<AvatarProps> = ({ user, name, shape, size = 32 }) => {
  const { avatar, isLoadError, onError, onLoad } = useCustomMessageAvatar(user?.id);

  if (name === 'alert-chat-notifier-id') {
    return (
      <Ionicons
        name="notifications-circle-outline"
        size={36}
        style={{ marginHorizontal: 0, marginVertical: 0, padding: 0, color: 'red' }}
      />
    );
  } else if (name === 'チャムリー') {
    return (
      <Image
        source={require('../../assets/app_icons/app_adaptive_icon_chumly.png')}
        style={{ width: 48, height: 48, backgroundColor: '#fafafa', borderRadius: 30, padding: 0 }}
        alt="チャムリー"
      />
    );
  }

  if (isLoadError || !avatar) {
    return (
      <Ionicons
        name="person-circle-sharp"
        size={36}
        style={{ marginHorizontal: 4, marginVertical: 0, padding: 0, color: '#bbb' }}
      />
    );
  }

  return (
    <div className={`str-chat__avatar str-chat__avatar--${shape} str-chat__message-sender-avatar`}>
      <Image
        source={{ uri: avatar }}
        style={{ width: size, height: size, borderRadius: 20, marginHorizontal: 4, backgroundColor: '#ccc' }}
        alt={user?.name}
        onError={onError}
        onLoad={onLoad}
      />
    </div>
  );
};

const MemoizedCustomMessageAvatar = memo(UnmemoizedCustomMessageAvatar);

export const CustomMessageAvatar: React.FC<AvatarProps> = ({ ...props }) => {
  return <MemoizedCustomMessageAvatar user={props.user} name={props.name} shape={props.shape} size={props.size} />;
};
