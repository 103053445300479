import { useDidRender, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Button, Divider, Input, VStack } from 'native-base';
import { FormBuilder } from 'native-base-form-builder';
import * as React from 'react';
import { useImperativeHandle, useRef } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { AlertBox } from './AlertBox';
import { FormDate } from './FormDate';
import { FormFarmDetails } from './FormFarmDetails';
import { FormFiles } from './FormFiles';
import { FormGroupDetails } from './FormGroupDetails';
import { FormMultiSelect } from './FormMultiselect';
import { FormRemoveFileIds } from './FormRemoveFileIds';
import { FormSectionedMultiSelect } from './FormSectionedMultiSelect';
import { FormSelect } from './FormSelect';
import { FormSingleCheckbox } from './FormSingleCheckbox';
import { FormTextArea } from './FormTextArea';

type option = {
  id: number | string;
  name: string;
};

type SelectOption = {
  label: string;
  value: string;
};

type FieldType<T> = {
  key: string;
  name?: string;
  label?: string;
  type: T;
  rules?: object;
  watch?: boolean;
  skip?: boolean;
};

interface Props {
  queryResult?: {
    data: [];
    error: boolean;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
    errorMessage: string;
    refetch: () => void;
  };
  getValues?: () => any;
  errorMessage?: string | string[];
  message?: string | string[];
  isUpdating?: boolean;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  onFieldUpdate: (data: any) => void;
  fields: (
    | (FieldType<'text'> & {
        textInputProps?: React.ComponentProps<typeof Input>;
      })
    | FieldType<'password'>
    | FieldType<'email'>
    | (FieldType<'select'> & {
        options: SelectOption[];
      })
    | FieldType<'multiselect'>
    | FieldType<'date'>
    | FieldType<'files'>
    | FieldType<'removeFileIds'>
    | FieldType<'groupDetails'>
    | FieldType<'farmDetails'>
    | (FieldType<'textarea'> & {
        textInputProps?: React.ComponentProps<typeof Input>;
      })
    | FieldType<'singleCheckbox'>
    | (FieldType<'sectionedMultiselect'> & {
        options: option[];
        exclusionDisplay?: string[];
        unchangeableItems?: (string | number)[];
        filterFunction?: (query: string) => Promise<option[]>;
      })
  )[];
  defaultValues?: object;
  submitLabel?: string;
  hideBottom?: boolean;
  isLoading?: boolean;
}

export const BaseFormBuilder: React.FC<React.PropsWithChildren & React.ComponentProps<typeof FormBuilder> & Props> =
  React.forwardRef(
    (
      {
        queryResult,
        fields,
        onSubmit,
        onCancel,
        onFieldUpdate,
        defaultValues,
        children,
        message,
        errorMessage,
        submitLabel = '保存',
        hideBottom,
        isLoading,
      },
      ref
    ) => {
      const customFormConfigArray = addCustomFormConfig(
        // @ts-expect-error TS(2345): Argument of type '({ type: "text" | "password" | "... Remove this comment to see the full error message
        fields
          ?.filter((field) => !field.skip)
          .map((field) => ({
            ...field,
            type: field?.type || 'text',
            name: field?.key,
          })) ?? []
      );

      const { control, setFocus, handleSubmit, reset, getValues, setValue } = useForm({
        defaultValues,
        mode: 'onChange',
      });

      const processing = useRef(false);

      const handleSubmitBlockDoublePost = () => {
        if (processing.current) return;
        processing.current = true;
        handleSubmit(onSubmit)().finally(() => (processing.current = false));
      };

      useImperativeHandle(ref, () => ({
        submit: () => {
          handleSubmit(onSubmit)();
        },
      }));

      // fieldの変更を通知
      const watchNames = customFormConfigArray.filter((config) => config.watch).map((config) => config.name);
      // @ts-expect-error TS(2769): No overload matches this call.
      const values = useWatch({ control, name: watchNames });

      useDidRender(() => {
        onFieldUpdate &&
          onFieldUpdate(
            watchNames.reduce(
              (acc, cur, index) => ({
                ...acc,
                // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
                [cur]: values[index],
                setValue,
              }),
              {}
            )
          );
      }, [values]);

      //  type selectの場合だけuseFormでdefaultValuesがsetされないのでここで設定しています
      useDidUpdate(
        () => {
          fields
            ?.filter((field) => !field.skip && field.type == 'select')
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            .map((field) => setValue(field.key, defaultValues?.[field.key]));
        },
        [defaultValues],
        true
      );

      useDidUpdate(
        () => {
          if (queryResult?.data) {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            fields.forEach((field) => setValue(field.key, queryResult.data?.[field.key]));
          }
        },
        [queryResult?.data],
        true
      );

      return (
        <VStack minHeight={220} space={2}>
          <FormBuilder
            inputSpacing={24}
            control={control}
            // @ts-expect-error TS(2322): Type 'UseFormSetFocus<object>' is not assignable t... Remove this comment to see the full error message
            setFocus={setFocus}
            // @ts-expect-error TS(2322): Type '({ type: string; JSX: FC<LogicProps>; key: s... Remove this comment to see the full error message
            formConfigArray={customFormConfigArray}
          />
          {(typeof errorMessage == 'string' && errorMessage) || errorMessage?.length ? (
            // @ts-expect-error TS(2741): Property 'type' is missing in type '{ message: str... Remove this comment to see the full error message
            <AlertBox message={errorMessage} />
          ) : null}
          {message ? <AlertBox type="information" message={message} /> : null}
          {children}
          <VStack space={4} marginTop={2}>
            <Divider />
            {!hideBottom ? (
              <Button
                onPress={handleSubmitBlockDoublePost}
                isLoading={processing.current || isLoading}
                isDisabled={processing.current || isLoading}
              >
                保存
              </Button>
            ) : null}
            <Button variant="outline" onPress={onCancel}>
              キャンセル
            </Button>
          </VStack>
        </VStack>
      );
    }
  );

const addCustomFormConfig = (formConfigArray: Props['fields']) =>
  formConfigArray?.map((config) => {
    switch (config.type) {
      case 'multiselect':
        return {
          ...config,
          type: 'custom',
          JSX: FormMultiSelect,
        };
      case 'date':
        return {
          ...config,
          type: 'custom',
          JSX: FormDate,
        };
      case 'files':
        return {
          ...config,
          type: 'custom',
          JSX: FormFiles,
        };
      case 'removeFileIds':
        return {
          ...config,
          type: 'custom',
          JSX: FormRemoveFileIds,
        };
      case 'select':
        return {
          ...config,
          type: 'custom',
          JSX: FormSelect,
        };
      case 'groupDetails':
        return {
          ...config,
          type: 'custom',
          JSX: FormGroupDetails,
        };
      case 'farmDetails':
        return {
          ...config,
          type: 'custom',
          JSX: FormFarmDetails,
        };
      case 'sectionedMultiselect':
        return {
          ...config,
          type: 'custom',
          JSX: FormSectionedMultiSelect,
        };
      case 'textarea':
        return {
          ...config,
          textInputProps: {
            testID: config.key,
            ...config.textInputProps,
          },
          type: 'custom',
          JSX: FormTextArea,
        };
      case 'singleCheckbox':
        return {
          ...config,
          type: 'custom',
          JSX: FormSingleCheckbox,
        };
      default:
      case 'text':
        return {
          ...config,
          textInputProps: {
            testID: config.key,
            // @ts-expect-error TS(2339): Property 'textInputProps' does not exist on type '... Remove this comment to see the full error message
            ...config.textInputProps,
            size: 'lg',
            variant: 'outline',
            borderColor: 'black',
            py: 3,
          },
        };
    }
  }) ?? [];

export const errorMessageBuilder = (error: any, fields: any) => {
  if (error.status === 422) {
    if (error.data) {
      const keys = Object.keys(error.data);
      return keys.map((key) => {
        const label =
          fields.find((field: any) => field.key === key)?.label ||
          fields.find((field: any) => field.key === `${key}_id`)?.label ||
          key;
        const massages = error.data[key];
        return massages.map((massage: any) => `${label}${massage}`);
      });
    }
    return ['エラーが発生しました。しばらく時間を置いてから再試行してください。'];
  }

  if (Number(error.status) >= 500) {
    return ['エラーが発生しました。しばらく時間を置いてから再試行してください。'];
  }
  if (error.data.error) {
    return error.data.error;
  }
};
