import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Spinner, Text } from 'native-base';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { useSearchHistory } from '~/hooks/useSearchHistory';

interface Props {
  query: string;
  onSubmit: (query: string | undefined) => void;
}

export const SearchHistoryItem: React.FC<Props> = ({ query, onSubmit }) => {
  const { deleteSearchHistory } = useSearchHistory();
  const [spinner, setSpinner] = useState(false);
  return (
    <TouchableOpacity
      onPress={() => {
        onSubmit(query);
      }}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" space={2} paddingY={2}>
          <Icon as={<Ionicons name="search-outline" />} fontWeight={500} />
          <Text fontSize="md" fontWeight={500}>
            {query}
          </Text>
        </HStack>
        {spinner ? (
          <HStack space={2} padding={2}>
            <Spinner size="sm" />
          </HStack>
        ) : (
          <TouchableOpacity
            onPress={() => {
              setSpinner(true);
              deleteSearchHistory(query);
            }}
          >
            <HStack space={2} padding={2}>
              <Icon as={<Ionicons name="close-outline" />} />
            </HStack>
          </TouchableOpacity>
        )}
      </HStack>
    </TouchableOpacity>
  );
};
