import { Box, VStack, HStack, Text } from 'native-base';
import React from 'react';

export const PreviewMastitis = ({ data }: any) => {
  return (
    <Box backgroundColor="white" borderRadius={20} padding={4} marginBottom={4}>
      <VStack space={4}>
        <HStack>
          <Text width="50%" fontWeight="bold">
            牛番号
          </Text>
          <Text width="50%">{data.cow_no}</Text>
        </HStack>

        <HStack>
          <Text width="50%" fontWeight="bold">
            発生時所属牛群
          </Text>
          <Text width="50%">{data.mastitis_cow_group_name}</Text>
        </HStack>
        <HStack>
          <Text width="50%" fontWeight="bold">
            乳房炎スコア
          </Text>
          <Text width="50%">{data.mastitis_score}</Text>
        </HStack>
        <Text fontWeight="bold">診断結果</Text>
        <HStack>
          <VStack width="50%">
            <Text fontWeight="bold">左前：</Text>
            <Text>{data.mastitis_diagnosis_result_of_breast_fl}</Text>
          </VStack>
          <VStack width="50%">
            <Text fontWeight="bold">右前：</Text>
            <Text>{data.mastitis_diagnosis_result_of_breast_fr}</Text>
          </VStack>
        </HStack>
        <HStack>
          <VStack width="50%">
            <Text fontWeight="bold">左後：</Text>
            <Text>{data.mastitis_diagnosis_result_of_breast_bl}</Text>
          </VStack>
          <VStack width="50%">
            <Text fontWeight="bold">右後：</Text>
            <Text>{data.mastitis_diagnosis_result_of_breast_br}</Text>
          </VStack>
        </HStack>
        <HStack>
          <Text width="50%" fontWeight="bold">
            体温
          </Text>
          <Text width="50%">{data.body_temperature}</Text>
        </HStack>
        <VStack>
          <Text fontWeight="bold">診断治療結果</Text>
          <Text>{data.diagnosis_result_detail}</Text>
        </VStack>
        <VStack>
          <Text fontWeight="bold">コメント</Text>
          <Text>{data.comment}</Text>
        </VStack>
        <Text fontWeight="bold">盲乳処置</Text>
        <HStack>
          <VStack width="25%">
            <Text fontWeight="bold">左前：</Text>
            <Text>{data.stop_milking_of_breast_fl ? 'はい' : 'いいえ'}</Text>
          </VStack>
          <VStack width="25%">
            <Text fontWeight="bold">右前：</Text>
            <Text>{data.stop_milking_of_breast_fr ? 'はい' : 'いいえ'}</Text>
          </VStack>
          <VStack width="25%">
            <Text fontWeight="bold">左後：</Text>
            <Text>{data.stop_milking_of_breast_bl ? 'はい' : 'いいえ'}</Text>
          </VStack>
          <VStack width="25%">
            <Text fontWeight="bold">右後：</Text>
            <Text>{data.stop_milking_of_breast_br ? 'はい' : 'いいえ'}</Text>
          </VStack>
        </HStack>
        <Text fontWeight="bold">乳房炎菌</Text>
        <HStack>
          <VStack width="50%">
            <Text fontWeight="bold">左前：</Text>
            <Text>{data.mastitis_bacteria_of_breast_fl}</Text>
          </VStack>
          <VStack width="50%">
            <Text fontWeight="bold">右前：</Text>
            <Text>{data.mastitis_bacteria_of_breast_fr}</Text>
          </VStack>
        </HStack>
        <HStack>
          <VStack width="50%">
            <Text fontWeight="bold">左後：</Text>
            <Text>{data.mastitis_bacteria_of_breast_bl}</Text>
          </VStack>
          <VStack width="50%">
            <Text fontWeight="bold">右後：</Text>
            <Text>{data.mastitis_bacteria_of_breast_br}</Text>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};
