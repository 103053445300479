import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { useRoute, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Skeleton, Flex, HStack, Icon, Pressable, Text, Input } from 'native-base';
import * as React from 'react';

import { Data } from '../MultiSelectScreen';
import {
  useGetCurrentUserGroupsQuery,
  useGetFeedDesignsByIdQuery,
  usePutFeedDesignsByIdMutation,
  usePostGroupsByGroupIdGroupFeedDesignsMutation,
  useDeleteGroupFeedDesignsByGroupFeedDesignsIdMutation,
  useGetFeedDesignsByFeedDesignsIdGroupFeedDesignsQuery,
} from '~/api/uFeedApi';
import {
  BaseScreenBuilder,
  BaseFormBuilder,
  FormFieldLabel,
  FormFieldList,
  errorMessageBuilder,
} from '~/components/builder/';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { setData } from '~/utils/multiSelectInputDataHelper';

const { useRef, useState } = React;

export const FeedDesignEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetFeedDesignsByIdQuery(queryParams);
  const [update, { isLoading: isUpdating, data }] = usePutFeedDesignsByIdMutation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const formRef = useRef();

  const groups = useGetCurrentUserGroupsQuery();
  const groupOptions = groups.data?.map((group) => ({
    name: group.name,
    id: group.id,
  }));
  // const publishedGroups = useGetFeedDesignsByFeedDesignsIdGroupsQuery({
  //   feedDesignsId: queryParams?.id || skipToken,
  // });

  const publishedGroups = useGetFeedDesignsByFeedDesignsIdGroupFeedDesignsQuery({
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    feedDesignsId: queryParams?.id || skipToken,
  });

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[] | undefined>();

  const [postGroupFeedDesign, postGroupFeedDesignResult] = usePostGroupsByGroupIdGroupFeedDesignsMutation();
  const [deleteGroupFeedDesign, deleteGroupFeedDesignResult] = useDeleteGroupFeedDesignsByGroupFeedDesignsIdMutation();

  const openMultiSelectScreen = () => {
    setData({
      data: groupOptions as Data[],
      selectedItems: selectedGroupIds as string[] | number[],
      onConfirm: (result: any) => {
        setSelectedGroupIds(result);
      },
      onCancel: () => {},
    });
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('MultiSelect');
  };

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      key: 'memo',
      label: 'メモ',
    },
    {
      key: 'delete_file_ids',
      type: 'removeFileIds',
      label: '添付ファイル',
      options: queryResult.data?.files,
    },
    {
      key: 'files',
      type: 'files',
      options: {
        clear: true,
      },
    },
  ];

  useDidUpdate(
    () => {
      setSelectedGroupIds(
        // @ts-expect-error TS(2345): Argument of type '(number | undefined)[]' is not a... Remove this comment to see the full error message
        publishedGroups.isSuccess ? publishedGroups.data.map((group_feed_design) => group_feed_design.group.id) : []
      );
    },
    [publishedGroups],
    true
  );

  return (
    <ScreenWidthModal title={queryResult.data?.name || ''}>
      <BaseScreenBuilder title={queryResult.data?.name || ''}>
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFeedDe... Remove this comment to see the full error message
          queryResult={queryResult}
          update={update}
          updateResult={{ isUpdating, data }}
          requestBodyField="farms"
          emptyMessage="データがありません"
          ref={formRef}
          errorMessage={errorMessages}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
          onCancel={() => navigation.goBack()}
          onSubmit={async (sendingData) => {
            setErrorMessages((errorMessages) => []);
            const formData = new FormData();
            formData.append('feed_design[name]', sendingData.name);
            formData.append('feed_design[memo]', sendingData.memo ? sendingData.memo : '');

            if (sendingData.files) {
              sendingData.files?.forEach((file: any) => {
                if (!('id' in file)) {
                  // @ts-expect-error TS(2345): Argument of type '{ type: any; name: any; uri: any... Remove this comment to see the full error message
                  formData.append('feed_design[files][]', {
                    type: file?.assets?.[0]?.mimeType,
                    name: file?.assets?.[0]?.name,
                    uri: file?.assets?.[0]?.uri,
                  });
                }
              });
            }

            if (sendingData.delete_file_ids) {
              sendingData.delete_file_ids.forEach((id: any) => {
                formData.append('feed_design[delete_file_ids][]', id);
              });
            }

            update({
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              id: queryResult.data?.id,
              // @ts-expect-error TS(2741): Property 'feed_design' is missing in type 'FormDat... Remove this comment to see the full error message
              body: formData,
            })
              .unwrap()
              .then(async (resp) => {
                const publishedGroupIds =
                  publishedGroups.data?.map((group_feed_design) => group_feed_design.group?.id) ?? [];

                const addPromises = selectedGroupIds
                  ?.map((id) => {
                    if (!publishedGroupIds?.includes(id)) {
                      return postGroupFeedDesign({
                        groupId: id,
                        body: {
                          group_feed_design: {
                            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                            feed_design_id: queryResult.data.id,
                          },
                        },
                      }).unwrap();
                    } else {
                      return undefined;
                    }
                  })
                  ?.filter((promise) => !!promise);

                // const addResult = await Promise.all(addPromises).catch((error) =>
                //   console.error('publishing feed design to groups failed: ', error)
                // );

                const removePromises = publishedGroupIds
                  ?.map((id) => {
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    if (!selectedGroupIds.includes(id)) {
                      return deleteGroupFeedDesign({
                        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                        groupFeedDesignsId: publishedGroups.data?.filter(
                          (group_feed_design) => group_feed_design.group?.id == id
                        )[0]?.id,
                      }).unwrap();
                    } else {
                      return undefined;
                    }
                  })
                  ?.filter((promise) => !!promise);

                // @ts-expect-error TS(2488): Type '(Promise<unknown> | undefined)[] | undefined... Remove this comment to see the full error message
                const removeResult = await Promise.all([...addPromises, ...removePromises])
                  .then((res) => navigation.goBack())
                  .catch((error) => {
                    setErrorMessages(errorMessageBuilder(error, fields));
                    console.error('removing feed designs from group failed: ', error);
                  });
              });
          }}
        >
          <FormFieldList>
            <FormFieldLabel label="公開先グループ" />
            {!selectedGroupIds ? (
              <Skeleton />
            ) : (
              <>
                <HStack alignItems="center">
                  <Input
                    w={{
                      base: '90%',
                      md: '100%',
                    }}
                    size="lg"
                    variant="unstyled"
                    borderColor="black"
                    placeholder={
                      selectedGroupIds.length == 0 ? '選択してください' : `${selectedGroupIds.length}アイテム選択中`
                    }
                    py={3}
                    isReadOnly
                    onPressIn={openMultiSelectScreen}
                  />
                  <Pressable onPress={openMultiSelectScreen}>
                    <Icon as={Ionicons} name="chevron-down-outline" size="md" />
                  </Pressable>
                </HStack>
                <Flex direction="row" flexWrap="wrap">
                  {selectedGroupIds?.map((item) => (
                    <HStack
                      key={item}
                      borderWidth={1}
                      margin={1}
                      padding={2}
                      color="gray.400"
                      borderColor="gray.400"
                      borderRadius={40}
                    >
                      <Text marginX={2}>
                        {groupOptions?.find((option) => option.id == item)?.name}
                        <Pressable
                          onPress={() => {
                            setSelectedGroupIds(selectedGroupIds?.filter((select) => select !== item));
                          }}
                        >
                          <Icon as={Ionicons} name="close-outline" marginLeft={2} />
                        </Pressable>
                      </Text>
                    </HStack>
                  ))}
                </Flex>
              </>
            )}
          </FormFieldList>
        </BaseFormBuilder>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
