import * as Device from 'expo-device';
import { DeviceType } from 'expo-device';
import * as WebBrowser from 'expo-web-browser';
import { Button } from 'native-base';
import React from 'react';

interface Props {
  mobileUrl: string;
  pcUrl: string;
}

export const OpenInUmotionButton: React.FC<Props> = React.memo(({ mobileUrl, pcUrl }) => {
  const openUmotionAsync = async () => {
    const type = await Device.getDeviceTypeAsync();
    const url = type === DeviceType.PHONE ? mobileUrl : pcUrl;
    await WebBrowser.openBrowserAsync(url);
  };

  return (
    <Button marginBottom={2} size="sm" color="primary.100" onPress={openUmotionAsync}>
      U-motionで開く
    </Button>
  );
});
