import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  Box,
  Center,
  Input,
  Icon,
  VStack,
  useTheme,
  KeyboardAvoidingView,
  HStack,
  Button,
  Text,
  Spinner,
} from 'native-base';
import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { Dimensions, Platform, Pressable, TextInput, useWindowDimensions } from 'react-native';
import { TabView, TabBar, Route } from 'react-native-tab-view';
import { Channel, ChannelMemberResponse } from 'stream-chat';

import { useChannelQuery } from '~/hooks/useChannelQuery';
import { useInputFocus } from '~/hooks/useInputFocus';
import { useMessageQuery } from '~/hooks/useMessageQuery';
import { useSearchHistory } from '~/hooks/useSearchHistory';

import { ChannelSearchResults } from './ChannelSearchResults';
import { ChatSearchResults } from './ChatSearchResults';
import { SearchHistory } from './SearchHistory';

const initialLayout = {
  width: Dimensions.get('window').width,
};

type RouteParams = {
  id?: string;
};

export const SearchScreen = () => {
  const textInputRef = useRef<TextInput | null>(null);
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [isSearching, setIsSearching] = useState(false);
  const [index, setIndex] = useState(0);
  const [targetUser, setTargetUser] = useState<ChannelMemberResponse | undefined>();
  const [showTag, setShowTag] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>();
  const { query, setQuery, channelQuery, channels, resetChannels, usersChannels, userList } = useChannelQuery();
  const { messageQuery, messages, resetMessages, isReachingEnd, pagingMessageQuery } = useMessageQuery();
  const { putSearchHistory } = useSearchHistory();
  const { inputFocus, setInputFocus } = useInputFocus(textInputRef);
  const isWeb = Platform.OS === 'web';

  const route = useRoute();
  const navigation = useNavigation();
  const { id } = (route.params as RouteParams) || {};

  const routes = useMemo<(Route & { count: number })[]>(
    () => [
      {
        key: 'chat',
        title: 'チャット',
        count: messages?.length ?? 0,
      },
      {
        key: 'channel',
        title: 'チャンネル',
        count: channels?.length ?? 0,
      },
    ],
    [channels, messages]
  );

  useEffect(() => {
    if (id) {
      setShowTag(true);
      setSelectedChannel(usersChannels.find((obj) => obj.id === id));
    }
  }, [id, usersChannels]);

  const renderScene = useCallback(
    ({ route }: { route: Route }) => {
      switch (route.key) {
        case 'chat':
          return (
            <ChatSearchResults
              messages={messages}
              paginationSubmit={chatPaginationSubmit}
              isReachingEnd={isReachingEnd}
            />
          );
        case 'channel':
          return <ChannelSearchResults channels={channels} />;
        default:
          return null;
      }
    },
    [messages, channels, isReachingEnd]
  );

  const renderTabBar = (props: any) => {
    return (
      <TabBar<Route & { count: number }>
        {...props}
        index={index}
        setIndex={setIndex}
        indicatorStyle={{ backgroundColor: '#0EC9E5' }}
        style={{ backgroundColor: 'white', color: 'black' }}
        activeColor="black"
        inactiveColor="#bbb"
        renderLabel={({ route }) => (
          <Text>
            {route.title}
            <Text>({route.count})</Text>
          </Text>
        )}
      />
    );
  };

  const chatPaginationSubmit = async () => {
    await pagingMessageQuery(query, targetUser, showTag ? selectedChannel?.cid : null, true);
  };

  const onSubmit = async (historyItem: string | undefined) => {
    if (!targetUser && query === '' && !historyItem) {
      return;
    }
    if (query !== historyItem) {
      reset();
    }
    if (historyItem) {
      setQuery(historyItem);
    }

    setIsSearching(true);

    const chat = messageQuery(historyItem ? historyItem : query, targetUser, showTag ? selectedChannel?.cid : null);

    const channel = channelQuery(historyItem ? historyItem : query);

    await Promise.all([chat, channel]);

    putSearchHistory(query);
  };

  const reset = () => {
    resetChannels();
    resetMessages();
  };

  const onPressUser = useCallback(
    (user: ChannelMemberResponse) => {
      setTargetUser(user);
      setQuery((prev) => prev.replace(/^from:[^\s]*((?![^\s])$|\s(?![^\s]))/i, ''));
    },
    [query]
  );

  const resetTag = () => {
    resetChannels();
    setShowTag(false);
    setInputFocus(true);
    setIsSearching(false);
    setSelectedChannel(undefined);
    // @ts-expect-error TS(2345): Argument of type '{ id: undefined; }' is not assignable to parameter of type 'undefined'.
    navigation.setParams({ id: undefined });
  };

  return (
    <KeyboardAvoidingView flex={1} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
      <VStack backgroundColor={theme?.colors.white} flex={1} space={4} paddingX={4} paddingTop={4}>
        <HStack>
          <Input
            placeholder="キーワードを入力して検索"
            variant="rounded"
            py="3"
            px="1"
            fontSize="14"
            width={inputFocus && !isWeb ? '75%' : '100%'}
            InputLeftElement={
              <HStack alignItems="center">
                <Icon m="2" ml="3" size="6" color="gray.400" as={<Ionicons name="search-outline" />} />
                {!!targetUser && (
                  <Box
                    px={2}
                    py={1}
                    backgroundColor="primary.700"
                    rounded="lg"
                    mr="1"
                    maxW={`${Math.floor(width * 0.3)}px`}
                  >
                    <Text fontSize="sm" color="white" noOfLines={1}>
                      @{targetUser?.user?.name}
                    </Text>
                  </Box>
                )}
                {showTag ? (
                  <Pressable onPress={() => {}}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      px={2}
                      py={1}
                      borderWidth={1}
                      borderColor="#aaa"
                      borderRadius={20}
                    >
                      <Text fontSize={12}>in:{selectedChannel?.data?.name}</Text>
                      <Icon
                        ml="1"
                        size="3"
                        color="gray.400"
                        as={<Ionicons onPress={resetTag} name="close-outline" />}
                      />
                    </Box>
                  </Pressable>
                ) : null}
              </HStack>
            }
            InputRightElement={
              query ? (
                <Pressable
                  onPress={() => {
                    setTargetUser(undefined);
                    textInputRef.current?.clear();
                    setQuery('');
                    setIsSearching(false);
                    reset();
                  }}
                >
                  <Icon m="2" mr="3" size={6} color="gray.500" as={<Ionicons name="close-circle" />} />
                </Pressable>
              ) : undefined
            }
            onKeyPress={({ nativeEvent }) => {
              if (query === '' && nativeEvent.key === 'Backspace' && targetUser) {
                setQuery(`from:@${targetUser?.user?.name}`);
                setTargetUser(undefined);
              }
            }}
            onChangeText={(text) => {
              setQuery(text);
              reset();
            }}
            returnKeyType="search"
            onSubmitEditing={() => onSubmit(undefined)}
            autoFocus
            ref={textInputRef}
            value={query}
            onFocus={() => {
              setInputFocus(true);
              setIsSearching(false);
            }}
            onBlur={() => {
              // web版で onBlur イベントを使うとクリアボタンが動作しない不具合が発生する
              // web版では inputFocus は参照していないためイベントをスキップして問題ない
              // https://github.com/u-motion/u-motion-apps/issues/1926
              if (!isWeb) {
                setInputFocus(false);
              }
            }}
          />
          {inputFocus && !isWeb ? (
            <Button
              paddingX={1}
              variant="unstyled"
              onPress={() => {
                textInputRef.current?.clear();
                setQuery('');
                textInputRef.current?.blur();
              }}
            >
              キャンセル
            </Button>
          ) : null}
        </HStack>
        {!isSearching ? (
          <SearchHistory
            query={query}
            onSubmit={onSubmit}
            usersChannels={usersChannels}
            userList={userList}
            targetUser={targetUser}
            onPressUser={onPressUser}
          />
        ) : (
          <>
            {!isReachingEnd && messages.length === 0 ? (
              <Center flex={1}>
                <Spinner />
              </Center>
            ) : (
              <TabView
                navigationState={{
                  index,
                  routes,
                }}
                onIndexChange={setIndex}
                renderScene={renderScene}
                renderTabBar={renderTabBar}
                initialLayout={initialLayout}
                lazy
              />
            )}
          </>
        )}
      </VStack>
    </KeyboardAvoidingView>
  );
};
