import { useNavigation } from '@react-navigation/native';
import { HStack, VStack, Text, Avatar, Skeleton } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import { DetailList } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

import { useAppointmentServiceDetail } from './useAppointmentServiceDetail';

export const AppointmentServiceDetail = ({ id }: any) => {
  const { appointmentsSlotsQuery, appointmentsServiceQuery, slots } = useAppointmentServiceDetail({
    id,
  });
  const navigation = useNavigation();
  const [selected, setSelected] = useState('');

  if (appointmentsServiceQuery?.isLoading) {
    return (
      <VStack space={4}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </VStack>
    );
  }

  if (!id || appointmentsServiceQuery?.isError) {
    return <BaseScreenBuilder isError errorMessage="存在しないサービスです" />;
  }

  return (
    <VStack space={6}>
      <DetailList>
        <VStack space={4} paddingBottom={4}>
          <Text bold color="black">
            タイトル
          </Text>
          <Text fontSize="lg">{appointmentsServiceQuery?.data?.name}</Text>
          <Text bold color="black">
            概要
          </Text>
          <Text fontSize="lg">{appointmentsServiceQuery?.data?.description}</Text>
          <Text bold color="black">
            サービス提供者
          </Text>
          <HStack space={2} alignItems="center">
            <Avatar source={{ uri: appointmentsServiceQuery?.data?.provider?.avatar }} size="md" />
            <Text fontSize="lg">{appointmentsServiceQuery?.data?.provider?.name}</Text>
          </HStack>
          <Text bold color="black">
            開始通知メッセージ
          </Text>
          <Text fontSize="lg">{appointmentsServiceQuery?.data?.notification_text}</Text>
        </VStack>
      </DetailList>
    </VStack>
  );
};
