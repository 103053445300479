import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { VStack, FormControl } from 'native-base';
import { Logic } from 'native-base-form-builder';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { MultiSelectInput } from '~/components';
import { FormFieldLabel } from '~/components/builder';

type option = {
  id: number | string;
  name: string;
};

type ownProps = {
  options: option[];
  exclusionDisplay: string[];
  unchangeableItems: string[] | number[];
  filterFunction: (query: string) => Promise<option[]>;
};

export const FormSectionedMultiSelect: React.FC<React.ComponentProps<typeof Logic> & ownProps> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options,
  isRequired,
  exclusionDisplay,
  unchangeableItems,
  filterFunction,
}) => {
  const { field, formState } = useController({
    name,
    shouldUnregister,
    defaultValue,
    control,
  });

  const errorMessage = formState.errors?.[field.name]?.message;

  const [selectedItems, setSelectedItems] = useState([]);

  useDidUpdate(() => field.value && setSelectedItems(field.value), [field.value], true);

  return (
    // @ts-expect-error TS(2322): Type 'string | FieldError | Merge<FieldError, Fiel... Remove this comment to see the full error message
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack space={2} flex={1} zIndex={1000}>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <MultiSelectInput
          options={options}
          selectedItems={selectedItems
            .filter((item) => !exclusionDisplay?.includes(item))
            .filter((item) => {
              // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ label: str... Remove this comment to see the full error message
              return options?.map((o) => o.id).includes(item);
            })}
          unchangeableItems={unchangeableItems}
          onSelectedItemsChange={field.onChange}
          filterFunction={filterFunction}
        />
        {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
      </VStack>
    </FormControl>
  );
};
