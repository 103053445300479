import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Skeleton } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import {
  useGetAppliedFeedDesignsByIdQuery,
  usePostGroupsByGroupIdAppliedFeedDesignsMutation,
  usePutAppliedFeedDesignsByIdMutation,
  useGetGroupsByGroupIdGroupFeedDesignsQuery,
} from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useCustomToast } from '~/hooks/useCustomToast';
import { useGroupChat } from '~/hooks/useGroupChat';
import { DateUtil } from '~/utils/DateUtils';

export const AppliedFeedDesignCreateEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const toast = useCustomToast();
  // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
  const { share } = useGroupChat({ farmId: queryParams.farmId, groupId: queryParams.groupId });

  const showToast = async (resp: any, farmId: any, groupId: any) => {
    if (resp) {
      toast.showWithShare('飼料設計を適用しました', () => {
        share(
          `飼料設計を適用しました\n${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/applied_feed_designs/${resp.id}`
        );
      });
    }
    navigation.goBack();
  };
  const onCancel = () => navigation.goBack();

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const isPost = !!(!queryParams?.id || queryParams?.id === 'undefined');
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const queryResult = useGetAppliedFeedDesignsByIdQuery({ id: queryParams?.id }, { skip: isPost });
  const [post, postStatus] = usePostGroupsByGroupIdAppliedFeedDesignsMutation();
  const [put, putStatus] = usePutAppliedFeedDesignsByIdMutation();
  const groupFeedDesigns = useGetGroupsByGroupIdGroupFeedDesignsQuery({
    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
    groupId: queryParams.groupId,
  });

  const today = DateUtil.toYYYYMMDD(new Date(), '-');

  const [defaultValues, setDefaultValues] = useState(isPost ? { date: today } : undefined);

  const fields = [
    {
      key: 'memo',
      label: 'メモ',
    },
    {
      key: 'date',
      type: 'date',
      label: '適用日付',
      rules: {
        required: {
          value: true,
          message: '適用日付を選択してください',
        },
        max: today,
      },
    },
  ];

  const selectFeedDesigns = {
    key: 'feed_design_id',
    type: 'select',
    label: '飼料設計',
    rules: {
      required: {
        value: true,
        message: '飼料設計を選択してください',
      },
    },
    options:
      groupFeedDesigns?.data?.map((groupFeedDesign) => ({
        value: groupFeedDesign.feed_design?.id,
        label: groupFeedDesign.feed_design?.name,
      })) ?? {},
  };

  if (groupFeedDesigns.isSuccess) {
    // @ts-expect-error TS(2345): Argument of type '{ key: string; type: string; lab... Remove this comment to see the full error message
    fields.unshift(selectFeedDesigns);
  }

  useDidUpdate(
    () => {
      if (!queryResult.isSuccess) {
        return;
      }

      const { memo, feed_design_id, date } = queryResult.data;

      if (!isPost) {
        setDefaultValues({
          memo,
          feed_design_id,
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          date,
        });
      }
    },
    [queryResult.isSuccess],
    true
  );

  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    if (isPost) {
      // @ts-expect-error TS(2345): Argument of type '{ body: { applied_feed_design: a... Remove this comment to see the full error message
      post({
        ...queryParams,
        body: {
          applied_feed_design: {
            ...sendingData,
          },
        },
      })
        .unwrap()
        // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
        .then((resp) => showToast(resp, queryParams.farmId, queryParams.groupId))
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
          console.error('update error: ', error);
        });
    } else {
      put({
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        id: queryParams?.id,
        body: {
          applied_feed_design: {
            ...sendingData,
          },
        },
      })
        .unwrap()
        .then((resp) => navigation.goBack())
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
          console.error('update error: ', error);
        });
    }
  };

  return (
    <>
      {groupFeedDesigns.isLoading || (!isPost && !defaultValues) ? (
        <Skeleton height="400" />
      ) : (
        <ScreenWidthModal title="飼料設計適用">
          <BaseScreenBuilder title="飼料設計適用">
            {groupFeedDesigns.isSuccess ? (
              <BaseFormBuilder
                // @ts-expect-error TS(2339): Property 'isUpdating' does not exist on type 'Muta... Remove this comment to see the full error message
                isUpdating={post.isUpdating}
                // @ts-expect-error TS(2339): Property 'error' does not exist on type 'MutationT... Remove this comment to see the full error message
                error={post.error}
                // @ts-expect-error TS(2322): Type '({ key: string; label: string; type?: undefi... Remove this comment to see the full error message
                fields={fields}
                defaultValues={defaultValues}
                onCancel={onCancel}
                onSubmit={onSubmit}
                errorMessage={errorMessages}
              />
            ) : null}
          </BaseScreenBuilder>
        </ScreenWidthModal>
      )}
    </>
  );
};
