import { VStack, Text } from 'native-base';
import React from 'react';

import { useGetAppliedFeedDesignsByIdQuery } from '~/api/uFeedApi';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';

interface Props {
  groupId: number;
  id: number;
}

export const PreviewAppliedFeedDesign: React.FC<Props> = React.memo(({ id }) => {
  const appliedFeedDesign = useGetAppliedFeedDesignsByIdQuery({ id });

  if (appliedFeedDesign.isLoading || appliedFeedDesign.isError) {
    return null;
  }

  // @ts-expect-error TS(2339): Property 'memo' does not exist on type 'AppliedFee... Remove this comment to see the full error message
  const { memo, user, date } = appliedFeedDesign.data;
  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="gray.100"
    >
      <Text marginBottom={2}>飼料設計適用</Text>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type '({ data?: undefined; error?: undefined; fulf... Remove this comment to see the full error message
        queryResult={appliedFeedDesign}
        emptyMessage="飼料設計データがありません"
        space={2}
        fields={[
          {
            key: 'user',
            label: '作成者',
            type: 'user',
          },
          {
            key: 'memo',
            label: 'メモ',
          },
          {
            key: 'date',
            label: '適用日付',
            type: 'date',
          },
        ]}
      />
    </VStack>
  );
});
