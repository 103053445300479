import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { HStack, Text, Icon, Box, Toast, Pressable } from 'native-base';
import React, { useCallback, useContext, useState } from 'react';
import {
  useDeletePushMuteConfigsByIdMutation,
  useGetPushMuteConfigsExistQuery,
  usePostPushMuteConfigsMutation,
} from '~/api/uFeedApi';
import { ShowPinMessagesContext, ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChannelMembers } from '~/hooks';
import { useChannelStateContext } from '~/lib/StreamChatReact';

const UnMemoizedCustomHeader: React.FC = () => {
  const { thread } = useChannelStateContext();

  if (thread) {
    return null;
  }
  const navigation = useNavigation();
  const { chatClient, appChannel } = useStreamChatContext();
  const channel = appChannel;
  const linkTo = useLinkTo();

  const [mute, setMute] = useState(false);
  const pushMuteConfigQuery = useGetPushMuteConfigsExistQuery({ targetId: channel?.cid, targetType: 'channel' });
  const [postPushMuteConfig] = usePostPushMuteConfigsMutation();
  const [deletePushMuteConfig] = useDeletePushMuteConfigsByIdMutation();
  const { allMembers } = useChannelMembers(channel?.id ?? '');

  const dispatch = useContext(ShowPinMessagesDispatchContext);
  const showPinMessages = useContext(ShowPinMessagesContext);

  const switchPinMessage = useCallback(() => {
    dispatch && dispatch({ type: 'switch' });
  }, [dispatch]);

  const handleSearchPress = useCallback(() => {
    const channelId = channel?.id;

    linkTo(`/search?id=${channelId}`);
  }, [channel]);

  const handleMemberlistPress = useCallback(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const index = channel?.cid?.indexOf(':') + 1;
    const channelId = channel?.cid?.substring(index);
    navigation.getState().routeNames[0] === 'DirectMessageList'
      ? linkTo(`/dm/memberlist/${channelId}`)
      : linkTo(`/chat/memberlist/${channelId}`);
  }, [navigation]);

  const handleMutePress = useCallback(() => {
    if (mute) {
      const muteConfig = pushMuteConfigQuery.currentData;
      if (!muteConfig) {
        return;
      }
      deletePushMuteConfig({ id: muteConfig.id });
      Toast.show({
        description: 'プッシュ通知のミュートを解除しました',
        placement: 'top',
      });
    } else {
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      postPushMuteConfig({ body: { push_mute_config: { target_type: 'channel', target_id: channel?.cid } } });
      Toast.show({
        description: 'プッシュ通知をミュートしました',
        placement: 'top',
      });
    }
  }, [pushMuteConfigQuery, deletePushMuteConfig, postPushMuteConfig, mute]);

  useDidUpdate(
    () => {
      if (pushMuteConfigQuery.isLoading) {
        return;
      }
      const data = pushMuteConfigQuery.currentData;
      if (pushMuteConfigQuery.isSuccess && data?.id) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    [pushMuteConfigQuery],
    true
  );

  useDidUpdate(() => {
    dispatch && dispatch({ type: 'close' });
  }, [channel?.cid]);

  //トーストの問題の対応 https://github.com/GeekyAnts/NativeBase/issues/5107
  setTimeout(() => Toast.closeAll(), 5000);

  const memberIds = channel?.state?.members ? Object.keys(channel?.state?.members) : [];

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        backgroundColor: 'white',
        zIndex: 100,
        padding: 10,
      }}
    >
      <HStack padding={2} paddingRight={3}>
        <Box>
          <Text fontWeight={700} fontSize="md">
            {!channel?.data?.isDM || memberIds.length <= 1
              ? channel?.data?.name
              : memberIds
                  .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                  .map((key) => channel?.state?.members[key]?.user?.name)
                  .join(', ')}
          </Text>
        </Box>
        <HStack marginRight={0} marginLeft="auto" space={10}>
          <Pressable onPress={handleSearchPress}>
            <Box>
              <HStack alignItems="center" space={1}>
                <Icon as={Ionicons} name="search-outline" size="md" />
                チャンネル内を検索
              </HStack>
            </Box>
          </Pressable>
          <Pressable onPress={handleMutePress}>
            <Box>
              <HStack alignItems="center" space={1}>
                <Icon as={Ionicons} name={mute ? 'notifications-off-outline' : 'notifications-outline'} size="md" />
                {mute ? '通知なし' : '通知あり'}
              </HStack>
            </Box>
          </Pressable>
          <Pressable onPress={handleMemberlistPress}>
            <HStack alignItems="center" space={1}>
              <Icon as={Ionicons} name="people-outline" size="md" />
              <Text>{allMembers?.length}</Text>
            </HStack>
          </Pressable>

          <Pressable onPress={switchPinMessage}>
            <HStack alignItems="center" space={1}>
              {!showPinMessages ? (
                <>
                  <Icon as={AntDesign} name="pushpino" size="md" />
                  <Text>ピン留めアイテム</Text>
                </>
              ) : (
                <>
                  <Icon as={Ionicons} name="close-outline" size="md" />
                  <Text>ピン留めアイテムを閉じる</Text>
                </>
              )}
            </HStack>
          </Pressable>
        </HStack>
      </HStack>
    </div>
  );
};

export const CustomHeader = React.memo(UnMemoizedCustomHeader);
