import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { useRoute, useNavigation, useFocusEffect, useLinkTo } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  VStack,
  HStack,
  Text,
  Divider,
  ScrollView,
  Icon,
  Image,
  Button,
  Alert as NativeBaseAlert,
  Switch,
  Spinner,
  Toast,
} from 'native-base';
import { useCallback, useState } from 'react';
import { Platform } from 'react-native';

import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { Section, SectionList, ListItem } from '~/components';
import { Alert } from '~/components/Alert';
import { HeaderRight } from '~/components/HeaderRight';
import { ScreenWidthAdjuster } from '~/components/ScreenWidthAdjuster';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useAvatars, useAccounts, useGroups, useChannelMembers } from '~/hooks';
import { usePinnedChannels } from '~/hooks/usePinnedChannels';
import { isEmpty } from '~/utils/ isEmptyObject';
import { setData } from '~/utils/multiSelectInputDataHelper';
import { queryChannel } from '~/utils/queryChannels';

export const ChatMemberListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const { appChannel, chatClient, setAppChannel, isUserConnected } = useStreamChatContext();
  const [overview, setOverview] = useState(null);
  const [name, setName] = useState(null);
  const currentUser = useGetCurrentUserQuery({});
  const { avatarsByChatUserId } = useAvatars();
  const { accounts } = useAccounts();
  const { groupByChatTeamId } = useGroups();
  const [currentMembers, setCurrentMembers] = useState<{ id?: string; name?: string }[]>([]);
  const [isSystemChannel, setIsSystemChannel] = useState();
  const { chatUserId, findUsersByTeamIdAsync } = useStreamChatContext();
  const { pinnedChannels, addPinnedChannel, removePinnedChannel } = usePinnedChannels();

  const { allMembers } = useChannelMembers(appChannel?.id ?? '');

  const linkTo = useLinkTo();

  useDidUpdate(
    () => {
      setCurrentMembers(
        allMembers.map((item) => ({
          id: item?.user?.id,
          name: item?.user?.name,
        }))
      );
    },
    [allMembers, setCurrentMembers],
    true
  );

  if (Platform.OS === 'web') {
    navigation?.setOptions({
      headerLeft: () => (
        <HeaderRight
          onPress={() =>
            // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
            appChannel?.data?.isDM ? linkTo(`/dm/${params?.channelId}`) : linkTo(`/chat/${params?.channelId}`)
          }
          label="戻る"
        />
      ),
    });
  }

  useDidUpdate(
    () => {
      const setChannel = async (id: string) => {
        const newChannel = await queryChannel(chatClient, id);
        setAppChannel(newChannel);
      };

      // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
      if (params?.channelId && isUserConnected) {
        // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
        setChannel(params?.channelId);

        appChannel?.watch();
      }
    },
    // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
    [params?.channelId, isUserConnected, chatClient],
    true
  );

  useDidUpdate(
    () => {
      if (!appChannel?.data || isEmpty(appChannel?.data) || appChannel?.disconnected) {
        return;
      }

      if (appChannel.data?.isDM || appChannel.data?.is_my_channel) {
        return;
      }

      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      setOverview(appChannel.data.channel_overview);
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setName(appChannel.data.name);

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (appChannel?.data?.cid.match(/^team\:u-feed\-group.+/)?.length > 0) {
        // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        setIsSystemChannel(true);
      } else {
        // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        setIsSystemChannel(false);

        if (
          // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
          appChannel?.state.members[chatUserId]?.role === 'owner' ||
          currentUser.data?.roles?.includes('group_admin')
        ) {
          navigation?.setOptions({
            headerRight: () => (
              <HeaderRight
                onPress={() => {
                  Platform.OS === 'web'
                    ? // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
                      linkTo(`/chat/channel/${params?.channelId}/edit`)
                    : // @ts-expect-error TS(2769): No overload matches this call.
                      navigation.navigate('ChatChannelOverviewEdit');
                }}
                label="編集する"
              />
            ),
          });
        }
      }
    },
    [appChannel, appChannel?.data, chatUserId, currentUser.data?.roles],
    true
  );

  const updateTeamMembers = async (query: string) => {
    const channelState = await appChannel?.watch();
    const channelTeam = channelState?.channel.team;
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const result = await findUsersByTeamIdAsync(channelTeam, query);
    return result || [];
  };

  const onAddMember = async () => {
    const teamMembers = await updateTeamMembers('');
    if (teamMembers === undefined || teamMembers?.length === 0) {
      return;
    }

    setData({
      data: teamMembers
        ?.map((m) => ({
          id: m.id,
          name: m.name,
        }))
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        .filter((m) => !currentMembers.find((c) => c.id === m.id)) as Data[],
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      selectedItems: currentMembers.map((m) => m.id),
      onQuery: (query: any) => {
        return updateTeamMembers(query);
      },
      onConfirm: async (selectedChatIds: any) => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const filteredChatIds = selectedChatIds.filter((id) => !currentMembers.some((member) => member.id === id));
        appChannel
          ?.addMembers(filteredChatIds)
          .then(async (result) => {
            const newMembers = appChannel?.state.members;

            setCurrentMembers(
              Object.keys(newMembers).map((id) => ({
                id,
                name: newMembers[id]?.user?.name,
              }))
            );
          })
          .catch((error) => console.error('addMembersToChannel Error: ', error));
      },
    });

    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('MultiSelect');
  };

  const onJoinChannel = async () => {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    await appChannel?.addMembers([chatUserId]);
  };

  const onLeaveChannel = () => {
    Alert.alert('確認', `「${name}」から退出します。`, [
      {
        text: 'キャンセル',
      },
      {
        text: '退出する',
        onPress: async () => {
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          await appChannel?.removeMembers([chatUserId]);
        },
      },
    ]);
  };

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const pinned = pinnedChannels.includes(appChannel?.data.cid);

  const onPinToggle = async (ev: boolean) => {
    if (ev) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      await addPinnedChannel(appChannel?.data.cid);
    } else {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      await removePinnedChannel(appChannel?.data.cid);
    }
  };

  useFocusEffect(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    setOverview(appChannel?.data.channel_overview);
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    setName(appChannel?.data.name);
    navigation.setOptions({
      title: name,
    });
  });

  const renderItem = ({ item }: any) => {
    return (
      <HStack paddingY={4} space={2} alignItems="center">
        {avatarsByChatUserId[item.id]?.avatar ? (
          <Image source={{ uri: avatarsByChatUserId[item.id]?.avatar }} size="sm" borderRadius="full" alt="" />
        ) : (
          <Icon as={Ionicons} name="person-circle-outline" size="6xl" />
        )}
        <VStack>
          <VStack>
            <Text fontSize="lg">{item.name}</Text>
          </VStack>
          <VStack>
            <Text fontSize="lg" color="muted.400">
              {/* @ts-expect-error TS(2538): Type 'any[]' cannot be used as an index type. */}
              {accounts[[item.id]]}
            </Text>
          </VStack>
        </VStack>
      </HStack>
    );
  };

  // チャンネルにメンバーを追加する権限があるかどうか
  const hideMemberAddButton =
    appChannel?.data?.isDM ||
    isSystemChannel ||
    appChannel?.data?.is_my_channel ||
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    !appChannel?.data?.own_capabilities?.includes('update-channel-members');

  const deleteChannel = useCallback(async () => {
    if (confirm('チャンネルを削除します。投稿済みのチャットメッセージは表示できなくなります') === true) {
      const cId = appChannel?.cid;
      if (!cId) {
        return;
      }

      // Workaround: To avoid the error "You can't use a channel after client.disconnect() was called.",
      // set `to_be_deleted` true before deleting.
      await appChannel?.updatePartial({ set: { to_be_deleted: true } });
      appChannel
        ?.delete()
        .then(() => {
          // @ts-expect-error TS(2769): No overload matches this call.
          navigation.navigate('ChatChannelList');

          Toast.show({
            description: 'チャンネルを削除しました',
            placement: 'top',
          });
        })
        .catch((_error) => {
          Toast.show({
            description: 'チャンネルの削除でエラーが発生しました',
            placement: 'top',
          });
        });
    }
  }, [appChannel]);

  if (!appChannel) return <Spinner />;

  return (
    <ScreenWidthAdjuster>
      <ScrollView>
        <SectionList>
          <Section title="名称" padding={4}>
            <VStack space={4}>
              <Text selectable>{appChannel?.data?.name} </Text>
            </VStack>
          </Section>
          {overview ? (
            <Section title="概要" padding={4}>
              <Text selectable>{overview}</Text>
            </Section>
          ) : null}
          {!appChannel?.data?.is_my_channel ? (
            <Section title="グループ" padding={4}>
              {/* @ts-expect-error TS(2538): Type 'unknown' cannot be used as an index type. */}
              <Text selectable>{groupByChatTeamId?.[appChannel?.data?.team]?.name}</Text>
            </Section>
          ) : null}
          <Section title="ピン" padding={4}>
            <HStack alignItems="center" justifyContent="space-between" space={4}>
              <Text>チャンネルをピンする</Text>
              <Switch onToggle={onPinToggle} value={pinned} />
            </HStack>
          </Section>
          <Section title="未読メッセージ" padding={4}>
            <Button variant="outline" onPress={() => appChannel?.markRead()}>
              すべて既読にする
            </Button>
          </Section>
          {isSystemChannel ? (
            <NativeBaseAlert margin={4} marginTop={4}>
              システムが作成したチャンネルです。変更できません。
            </NativeBaseAlert>
          ) : null}
          {appChannel?.data?.isSecret ? (
            <NativeBaseAlert margin={4} marginTop={0}>
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <NativeBaseAlert.Icon />
                <Text bold>シークレットチャンネル</Text>
              </HStack>
              <Text w="100%">参加しているメンバーのみ閲覧可能です。</Text>
            </NativeBaseAlert>
          ) : appChannel?.data?.isPublic ? (
            <NativeBaseAlert margin={4} status="warning" marginTop={0}>
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <NativeBaseAlert.Icon />
                <Text bold>公開チャンネル</Text>
              </HStack>
              <Text w="100%">同じグループのメンバー全員が閲覧可能です。</Text>
            </NativeBaseAlert>
          ) : appChannel?.data?.isDM ? (
            <NativeBaseAlert margin={4} marginTop={0}>
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <NativeBaseAlert.Icon />
                <Text bold>ダイレクト・メッセージ</Text>
              </HStack>
              <Text w="100%">参加しているメンバーのみ閲覧可能です。</Text>
            </NativeBaseAlert>
          ) : appChannel?.data?.is_my_channel ? (
            <NativeBaseAlert margin={4} marginTop={0}>
              <HStack space={2} alignItems="center" flex={1} w="100%">
                <NativeBaseAlert.Icon />
                <Text bold>マイ・チャンネル</Text>
              </HStack>
              <Text w="100%">自分専用チャンネルです。他のユーザーからは閲覧できません。</Text>
            </NativeBaseAlert>
          ) : undefined}
          {appChannel?.data?.isDM || isSystemChannel || appChannel?.data?.is_my_channel ? null : (
            <VStack padding={4}>
              {currentMembers.find((m) => m.id === chatUserId) &&
              // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
              appChannel.state.members[chatUserId]?.role !== 'owner' ? (
                <Button variant="outline" colorScheme="red" borderColor="red.500" onPress={onLeaveChannel}>
                  チャンネルから退出する
                </Button>
              ) : (
                !currentMembers.find((m) => m.id === chatUserId) && (
                  <Button variant="solid" onPress={onJoinChannel}>
                    チャンネルに参加する
                  </Button>
                )
              )}
            </VStack>
          )}
          {/* @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type. */}
          {(appChannel.state.members[chatUserId]?.role === 'owner' ||
            currentUser.data?.roles?.includes('group_admin')) && (
            <VStack padding={4}>
              <Button variant="outline" colorScheme="red" borderColor="red.500" onPress={deleteChannel}>
                チャンネルを削除する
              </Button>
            </VStack>
          )}
          <Section title={`メンバー: ${currentMembers?.length}`} padding={4}>
            <VStack flex={1}>
              {hideMemberAddButton ? null : (
                <ListItem
                  key="add-button"
                  title="メンバーを追加"
                  left={<Icon as={Ionicons} name="add-circle" color="blue.400" />}
                  onPress={onAddMember}
                  link
                />
              )}
              <FlashList data={currentMembers} renderItem={renderItem} ItemSeparatorComponent={Divider} />
            </VStack>
          </Section>
        </SectionList>
      </ScrollView>
    </ScreenWidthAdjuster>
  );
};
