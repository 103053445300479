import { Ionicons } from '@expo/vector-icons';
import { HStack, Text, VStack, Icon } from 'native-base';
import * as React from 'react';

interface PanelProps {
  label: string;
  targetAmount: number;
  amount: number;
  isPreview?: boolean;
}

export const MilkAmountPanel = ({ label, targetAmount, amount, isPreview = false }: PanelProps) => {
  const amountRounded = Math.round(amount);
  const targetAmountRounded = Math.round(targetAmount);
  const diff = amountRounded - targetAmountRounded;
  const diffRounded = Math.round(diff);
  const diffAbs = Math.abs(diff);

  const isMinus = diff < 0;
  const isEqual = Math.round(diffAbs) === 0;

  return (
    <VStack
      padding={3}
      borderWidth={0}
      borderColor="black.500"
      borderRadius={10}
      backgroundColor={
        'gray.100' /*
        Math.floor((diffMin / preTotal) * 100) >= 0
          ? '#a1fb8d'
          : Math.floor((diffMin / preTotal) * 100) < -20
          ? '#f66'
          : '#f5ff89'
  */
      }
      flex={1}
      space={1}
    >
      <HStack alignItems="center">
        <Text bold fontSize={isPreview ? '2xs' : 'sm'} flexGrow={1}>
          {label}
        </Text>

        <HStack alignItems="center">
          {isEqual ? null : isMinus ? (
            <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'sm'} />
          ) : (
            <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'sm'} />
          )}

          <Text bold fontSize={isPreview ? '2xs' : 'lg'}>
            {Math.round((Math.abs(diffRounded) / targetAmountRounded) * 100)}%
          </Text>
        </HStack>
      </HStack>

      <VStack>
        <HStack flex={1}>
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            5日間平均
          </Text>
          <Text fontSize={isPreview ? '3xs' : 'xs'}>{Math.round(targetAmount)} kg/頭</Text>
        </HStack>
        <HStack>
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            昨日
          </Text>
          <Text fontSize={isPreview ? '3xs' : 'xs'}>{Math.round(amount)} kg/頭</Text>
        </HStack>
        <HStack flex={1} alignItems="center">
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1}>
            差分
          </Text>
          <HStack alignItems="center">
            {isEqual ? null : isMinus ? (
              <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'xs'} />
            ) : (
              <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'xs'} />
            )}
            <Text fontSize={isPreview ? '3xs' : 'xs'}>{Math.round(diffAbs)} kg</Text>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};
