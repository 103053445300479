import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { HStack, VStack, Text, Image, Icon } from 'native-base';
import { Platform, TouchableOpacity } from 'react-native';

import { CustomMessageText } from '~/components';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useAvatars } from '~/hooks';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  message: object;
  setShowOverlay?: (arg0: boolean) => void;
  handleMessagePress?: () => void;
}

export const ChatSearchResultItem = ({ message, setShowOverlay, handleMessagePress }: Props) => {
  const { chatClient } = useStreamChatAuthContext();
  const navigation = useNavigation();
  const { avatarsByChatUserId } = useAvatars();
  const linkTo = useLinkTo();

  const defaultHandleMessagePress = async () => {
    if (Platform.OS === 'web') {
      // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
      if (message?.channel.isDM) {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        navigation.navigate('DMNavigator', {
          screen: 'DirectMessageList',
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
          params: { channelId: message?.channel.id, messageId: message?.id },
        });
      } else {
        // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
        linkTo(`/chat/${message?.channel?.id}/${message?.id}`);
      }
    } else {
      // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
      if (message?.channel?.isDM) {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        navigation.navigate(message?.parent_id ? 'ChatThreadMessages' : 'ChatChannelMessages', {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
          channelType: message?.channel?.type,
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
          channelId: message?.channel?.id,
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
          messageId: message?.id,
        });
      } else {
        if (message) {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
          const newChannel = chatClient.channel(message.channel.type, message.channel.id);
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message
          linkTo(`/chat/${message.channel?.id}/${message.id}`);
        }
      }
    }
    if (setShowOverlay) {
      setShowOverlay(false);
    }
  };

  if (!message) {
    return null;
  }

  return (
    <TouchableOpacity onPress={handleMessagePress ? handleMessagePress : defaultHandleMessagePress} style={{ flex: 1 }}>
      <VStack space={2}>
        <HStack space={2}>
          <Text fontWeight={500} color="gray.400">
            {/* @ts-expect-error TS(2339): Property 'channel' does not exist on type 'object'... Remove this comment to see the full error message */}
            {message?.channel?.name}
          </Text>
        </HStack>
        <HStack space={2}>
          {/* @ts-expect-error TS(2339): Property 'user' does not exist on type 'object'. */}
          {avatarsByChatUserId[message.user.id] ? (
            <Image
              // @ts-expect-error TS(2339): Property 'user' does not exist on type 'object'.
              source={{ uri: avatarsByChatUserId[message.user.id]?.avatar }}
              size="xs"
              borderRadius="full"
              alt=""
            />
          ) : (
            <Icon as={Ionicons} name="person-circle-outline" size="4xl" />
          )}
          <VStack space={1} flex={1}>
            <HStack alignItems="center" space={2}>
              <Text fontSize="md" bold>
                {/* @ts-expect-error TS(2339): Property 'user' does not exist on type 'object'. */}
                {message?.user?.name}
              </Text>
              <Text color="gray.500">
                {/* @ts-expect-error TS(2339): Property 'created_at' does not exist on type 'obje... Remove this comment to see the full error message */}
                {DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(message?.created_at))}{' '}
                {/* @ts-expect-error ts(2339): Property 'created_at' does not exist on type 'obje... Remove this comment to see the full error message */}
                {DateUtil.tohmm(new Date(message?.created_at))}
              </Text>
            </HStack>
            <CustomMessageText message={message} />
          </VStack>
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
};
