import { U_FEED_URL_BASE } from '@env';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';

import { usePostGroupsByGroupIdAdditivesMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useCustomToast } from '~/hooks/useCustomToast';
import { useGroupChat } from '~/hooks/useGroupChat';
import { DateUtil } from '~/utils/DateUtils';

export const AdditiveCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};

  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams.groupId;
  const [post, { isLoading: isUpdating, error: postError }] = usePostGroupsByGroupIdAdditivesMutation();

  const [errorMessages, setErrorMessages] = React.useState([]);

  const toast = useCustomToast();
  // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
  const { share } = useGroupChat({ farmId: queryParams.farmId, groupId: queryParams.groupId });

  const showToast = async (resp: any, farmId: any, groupId: any) => {
    if (resp) {
      toast.showWithShare('添加剤追加イベントを作成しました', () => {
        share(
          `添加剤追加イベントを作成しました\n${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/additives/${resp.id}`
        );
      });
    }
    navigation.goBack();
  };

  const onCancel = () => navigation.goBack();

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    const { memo, date } = sendingData;
    post({
      groupId,
      body: {
        additive: {
          memo,
          date,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
        showToast(resp, queryParams.farmId, groupId);
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };
  const today = DateUtil.toYYYYMMDD(new Date(), '-');
  const defaultValues = { date: today };

  const fields = [
    {
      key: 'memo',
      label: '内容',
    },
    {
      key: 'date',
      label: '追加日',
      type: 'date',
      rules: {
        required: {
          value: true,
          message: '追加日を選択してください',
        },
        max: today,
      },
    },
  ];

  return (
    <ScreenWidthModal title="添加剤追加">
      <BaseScreenBuilder title="添加剤追加">
        <BaseFormBuilder
          isUpdating={isUpdating}
          error={postError}
          onSubmit={onSubmit}
          onCancel={onCancel}
          defaultValues={defaultValues}
          errorMessage={errorMessages}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; type?: undefi... Remove this comment to see the full error message
          fields={fields}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
