import { HStack, Text, Heading, Button, StyledProps } from 'native-base';
import React from 'react';

interface Props {
  title?: string;
  moreLabel?: string;
  onMorePress?: (item: any) => void;
  fontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  right?: React.ReactElement;
  center?: React.ReactElement;
}

export const SectionTitle: React.FC<Props & StyledProps> = ({
  title,
  moreLabel,
  onMorePress,
  fontSize = 'xl',
  center,
  right,
  ...props
}) => {
  return (
    <HStack alignItems="center" justifyContent="space-between" backgroundColor="white" {...props} space={2} flex={1}>
      <HStack alignItems="center" justifyContent="space-between">
        {title ? (
          <Heading fontSize={fontSize} alignItems="center">
            {title}
          </Heading>
        ) : null}
      </HStack>
      {center}
      {right}
      {onMorePress ? (
        <Button variant="unstyled" onPress={onMorePress} size="lg" padding={0}>
          <Text color="gray.400">{moreLabel || 'すべて見る'}</Text>
        </Button>
      ) : null}
    </HStack>
  );
};
