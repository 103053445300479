import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Box, Divider, FlatList, Spinner, Skeleton, VStack, Alert, Button, Text } from 'native-base';
import { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { Platform } from 'react-native';

import {
  GetNotificationsApiResponse,
  uFeedApi,
  useGetNotificationsQuery,
  usePatchNotificationsByIdMutation,
  useGetCurrentUserSettingQuery,
  usePutCurrentUserSettingMutation,
  usePatchNotificationsReadMutation,
} from '~/api/uFeedApi';
import { EmptyMessage } from '~/components/EmptyMessage';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { setEnableWebNotification } from '~/slices/settingsSlice';
import { useAppDispatch } from '~/store';

import { NotificationItem } from './NotificationItem';

const Loading = memo(() => (
  <VStack space={8}>
    <Skeleton.Text />
    <Skeleton.Text />
    <Skeleton.Text />
  </VStack>
));

export const NotificationsScreen = () => {
  const notifications = useGetNotificationsQuery({ withReaction: 1 });
  const [trigger, result] = uFeedApi.endpoints.getNotifications.useLazyQuery();
  const [readNotification] = usePatchNotificationsByIdMutation();
  const [notificationData, setNotificationData] = useState<GetNotificationsApiResponse>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const { chatClient, setAppChannel } = useStreamChatContext();
  const [noMoreData, setNoMoreData] = useState(false);
  const [lastId, setLastId] = useState<number | null>(null);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const userSetting = useGetCurrentUserSettingQuery();
  const [put] = usePutCurrentUserSettingMutation();
  const [readNotifications] = usePatchNotificationsReadMutation();

  const isLoading = useMemo(() => {
    return notifications.isLoading && !notificationData;
  }, [notifications, notificationData]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (userSetting.isSuccess) {
        put({
          body: {
            user_setting: {
              details: {
                ...userSetting?.data?.details,
                notificationsTabLastOpenAt: new Date().getTime(),
              },
            },
          },
        })
          .unwrap()
          .catch((error) => {
            console.error('update error: ', error);
          });
      }
    });

    return unsubscribe;
  }, [navigation, userSetting, put]);

  const messageNew = async () => {
    setInitialized(false);
    setNoMoreData(false);
    setLastId(null);
    await dispatch(uFeedApi.util.invalidateTags(['Notification']));
  };

  useDidMount(() => {
    // 通知ページを開いたときにReaction通知を既読にする
    readNotifications({ type: 'reaction' });

    const messageNewEventListener = chatClient.on('chumly-notification-new', async (event) => {
      messageNew();
    });

    return () => {
      messageNewEventListener.unsubscribe();
    };
  });

  useDidUpdate(
    () => {
      if (initialized) return;

      if (notifications.data && notifications.isSuccess && notifications.status === 'fulfilled') {
        setNotificationData(notifications.data);
        if (notifications.data.length > 0) {
          const last = notifications.data[notifications.data.length - 1];
          setLastId(last.id);
        }
        setInitialized(true);
        setRefreshing(false);
      }
    },
    [notifications],
    true
  );

  useDidUpdate(() => {
    if (result.isSuccess && result.data.length > 0) {
      if (loadingMore) {
        const newData = [...notificationData];
        result.data.forEach((item) => {
          // 念の為、データの重複をチェック
          if (newData.findIndex((d) => d.id === item.id) === -1) {
            newData.push(item);
          }
        });
        setNotificationData(newData);
        setLoadingMore(false);
      }
      setLastId(result.data[result.data.length - 1].id);
    } else if (result.isError || result.isSuccess) {
      setNoMoreData(true);
      setLoadingMore(false);
    }
  }, [result]);

  const loadMore = useCallback(async () => {
    if (noMoreData || loadingMore || lastId == null) {
      return;
    }
    setLoadingMore(true);
    trigger({ lastId, withReaction: 1 });
  }, [noMoreData, loadingMore, lastId, trigger]);

  const handleMessagePress = useCallback(
    (
      id: number,
      sourceChannelId: string,
      sourceChannelType: string,
      sourceMessageId: string,
      sourceLink: string,
      isDM: boolean,
      isDMWeb: boolean
    ) => {
      readNotification({ id });
      if (Platform.OS === 'web') {
        if (isDMWeb) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('DMNavigator', {
            screen: 'DirectMessageList',
            params: { channelId: sourceChannelId, messageId: sourceMessageId },
          });
        } else {
          linkTo(sourceLink);
        }
      } else {
        if (isDM) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', {
            channelId: sourceChannelId,
            messageId: sourceMessageId,
          });
        } else {
          if (id) {
            const newChannel = chatClient.channel(sourceChannelType, sourceChannelId);
            setAppChannel(newChannel);
            linkTo(sourceLink);
          }
        }
      }
    },
    [readNotification, navigation, linkTo, chatClient, setAppChannel]
  );

  const keyExtractor = useCallback(
    (item: GetNotificationsApiResponse[number], index: number) => `${item.id}-${index}`,
    []
  );

  const renderItem = useCallback(({ item }: { item: GetNotificationsApiResponse[number] }) => {
    return (
      <NotificationItem
        id={item.id}
        sourceType={item.source_type}
        sourceChannelName={item.source_channel?.name || ''}
        sourceUserAvatar={item.source_user?.avatar}
        sourceUserName={item.source_user?.name}
        sourceEventedAt={item.source_evented_at}
        body={item.body}
        // @ts-expect-error TS(2339): Property 'read' does not exist on type '{ id: numb... Remove this comment to see the full error message
        isUnread={item.source_type !== 'chat' && !item.read}
        sourceChannelId={item.source_channel_id}
        sourceChannelType={item.source_channel_type}
        sourceMessageId={item.source_message_id}
        sourceLink={item.source_link}
        isDM={!!item.source_channel_is_dm}
        isDMWeb={!!item.source_channel_is_dm}
        handleMessagePress={handleMessagePress}
      />
    );
  }, []);

  const refresh = useCallback(async () => {
    if (refreshing) {
      return;
    }

    setRefreshing(true);
    setInitialized(false);
    setNotificationData([]);
    setNoMoreData(false);
    setLastId(null);
    notifications.refetch();
  }, [refreshing, notifications]);

  const onWebPushConfigPress = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await dispatch(setEnableWebNotification(true));
      location.reload();
    }
  }, []);

  const ListFooterComponent = useCallback(() => {
    return loadingMore ? <Spinner /> : null;
  }, [loadingMore]);

  const ItemSeparatorComponent = useCallback(() => <Divider borderColor="gray.200" />, []);

  const ListEmptyComponent = useCallback(() => {
    // @ts-expect-error TS(2322): Type '{ message: string; }' is not assignable to t... Remove this comment to see the full error message
    return <EmptyMessage message="通知はありません" />;
  }, []);

  if (isLoading || !initialized) {
    return (
      <VStack backgroundColor="white" padding={4} flex={1} space={8}>
        <Loading />
        <Loading />
        <Loading />
      </VStack>
    );
  }

  return (
    <>
      {Platform.OS === 'web' && Notification?.permission === 'default' ? (
        <VStack backgroundColor="white" padding={8}>
          <Alert status="info" padding={6}>
            <VStack space={6}>
              <VStack>
                <Text lineHeight="md">
                  ウェブ・プッシュ通知を許可すると、メッセージを受信したときに通知バナーを表示できます。{'\n'}
                  開始する場合は「通知を設定する」をクリックして、ブラウザの通知を「許可」してください。
                </Text>
              </VStack>
              <Button variant="submit" onPress={onWebPushConfigPress}>
                通知を設定する
              </Button>
            </VStack>
          </Alert>
        </VStack>
      ) : null}
      <Box flex={1}>
        <FlatList
          data={notificationData}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onRefresh={refresh}
          refreshing={refreshing}
          onEndReached={loadMore}
          onEndReachedThreshold={Platform.OS === 'web' ? 0.1 : 0.5}
          ItemSeparatorComponent={ItemSeparatorComponent}
          padding={0}
          flex={1}
          backgroundColor="white"
          ListFooterComponent={ListFooterComponent}
          ListEmptyComponent={ListEmptyComponent}
        />
      </Box>
    </>
  );
};
