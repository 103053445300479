import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import { Image, VStack, Text, Button, Alert as AlertBox, Switch, HStack, TextArea } from 'native-base';
import * as React from 'react';
import { useState, useCallback } from 'react';
import { Platform } from 'react-native';

import {
  useGetCurrentUserQuery,
  useGetPushConfigsExistQuery,
  usePatchPushConfigsUpdatesMutation,
  usePostPushConfigsMutation,
  useDeleteCurrentUserMutation,
  usePostPushKeywordsMutation,
  useGetPushKeywordsExistQuery,
  usePatchPushKeywordsUpdatesMutation,
} from '~/api/uFeedApi';
import { ListItem, UsageNote, Alert } from '~/components';
import { DetailList } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { useAuth, useCustomToast } from '~/hooks';
import {
  settingsSelector,
  setEnableKanasapo,
  setIsAppointmentServiceProvider,
  setIsUseAppointmentService,
  setEnableWebNotification,
  setEnableUDFont,
  setEnablePushNotification,
  SettingsState,
} from '~/slices/settingsSlice';
import { useAppSelector, useAppDispatch } from '~/store';

export const UserSettingsScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const settings: SettingsState = useAppSelector(settingsSelector);
  const dispatch = useAppDispatch();
  const { show } = useCustomToast();

  const linkTo = useLinkTo();

  const queryParams = params ? { ...params } : {};
  const queryResult = useGetCurrentUserQuery(queryParams);
  const { isUmotionUser, handleLogout } = useAuth();
  const [shoudShowKanasapo, setShouldShowKanasapo] = useState(false);
  const pushConfigData = useGetPushConfigsExistQuery();
  const [setPushConfig] = usePostPushConfigsMutation();
  const [patchPushConfig] = usePatchPushConfigsUpdatesMutation();
  const [isMentionOnly, setIsMentionOnly] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [inputKeyWord, setInputKeyWord] = useState('');
  const [postKeyword] = usePostPushKeywordsMutation();
  const pushKeywordQuery = useGetPushKeywordsExistQuery();
  const [pathPushKeyWord] = usePatchPushKeywordsUpdatesMutation();
  const [deleteCurrentUser, { isLoading: isDeletingCurrentUser, isSuccess: isDeleteCurrentUserSuccess }] =
    useDeleteCurrentUserMutation();

  useDidUpdate(
    () => {
      if (pushConfigData.isSuccess && !isInitialized) {
        dispatch(setEnablePushNotification(pushConfigData.data.notify_type !== 'notify_none'));
        setIsMentionOnly(pushConfigData.data.notify_type === 'notify_mention');
        setIsInitialized(true);
      }
    },
    [pushConfigData],
    true
  );

  React.useEffect(() => {
    setInputKeyWord(pushKeywordQuery.data?.keyword ?? '');
  }, [pushKeywordQuery.data?.keyword]);
  const onDeleteCurrentUserPress = useCallback(async () => {
    Alert.alert(
      'Chumlyを退会',
      'アカウントを削除します。作成済みのメッセージやタスクは「削除済みユーザーのデータ」として残ります。',
      [
        { text: 'キャンセル' },
        {
          text: '確認',
          onPress: () => {
            deleteCurrentUser().then(() => {
              handleLogout();
            });
          },
        },
      ]
    );
  }, []);
  const handlePostKeyword = useCallback(async () => {
    const res = await postKeyword({ body: { keyword: inputKeyWord } });
    if ('error' in res) {
      show('キーワード通知の保存に失敗しました');
    } else {
      show('キーワード通知を保存しました');
    }
  }, [postKeyword, inputKeyWord, show]);
  const handleUpdateKeyword = useCallback(async () => {
    const res = await pathPushKeyWord({ body: { keyword: inputKeyWord } });
    if ('error' in res) {
      show('キーワード通知の保存に失敗しました');
    } else {
      show('キーワード通知を保存しました');
    }
  }, [pathPushKeyWord, inputKeyWord, show]);
  return (
    <BaseScreenBuilder
      title=""
      headerRightLabel="編集"
      onHeaderRightPress={() => {
        // @ts-expect-error TS(2769): No overload matches this call.
        Platform.OS === 'web' ? linkTo(`/settings/edit`) : navigation.navigate('UserSettingsEdit');
      }}
    >
      <VStack space={6}>
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="gray.400">
              プロフィール画像
            </Text>
            {queryResult?.data?.avatar ? (
              <Image source={{ uri: queryResult?.data?.avatar }} size="lg" borderRadius={60} alt="プロフィール画像" />
            ) : null}
          </VStack>
        </DetailList>

        <DetailsBuilder
          // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
          queryResult={queryResult}
          emptyMessage="ユーザーデータがありません"
          fields={[
            {
              key: 'name',
              label: '名前',
            },
            {
              key: 'yomi',
              label: '名前（カナ）',
            },
            {
              key: 'email',
              label: 'メールアドレス',
            },
          ]}
        />
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="gray.400">
              パスワード
            </Text>
            <Text bold>********</Text>

            {isUmotionUser ? (
              // @ts-expect-error TS(2322): Type '{ children: Element; type: string; }' is not... Remove this comment to see the full error message
              <AlertBox type="info">
                <Text>
                  <Text bold>U-motion IDでログイン中: </Text>
                  ユーザー名、パスワードとメールアドレスは、U-motionのユーザー管理画面で変更できます
                </Text>
              </AlertBox>
            ) : (
              <Button
                variant="link"
                onPress={() =>
                  Platform.OS === 'web'
                    ? linkTo('/settings/password/edit')
                    : // @ts-expect-error TS(2769): No overload matches this call.
                      navigation.navigate('UserSettingsPasswordEdit')
                }
              >
                パスワードを変更
              </Button>
            )}
          </VStack>
        </DetailList>
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="gray.400">
              スケジュール予約
            </Text>

            <HStack alignItems="center" space={4} justifyContent="space-between">
              <Text fontSize="md">スケジュール予約を利用する</Text>
              <Switch
                isChecked={settings.isUseAppointmentService}
                onToggle={(value) => {
                  dispatch(setIsUseAppointmentService(value));
                }}
                testID="ToggleisUseAppointmentService"
              />
            </HStack>
            <HStack alignItems="center" space={4} justifyContent="space-between">
              <Text fontSize="md">サービス提供を行う</Text>
              <Switch
                isChecked={settings.isAppointmentServiceProvider}
                onToggle={(value) => {
                  dispatch(setIsAppointmentServiceProvider(value));
                }}
                testID="ToggleisAppointmentServiceProvider"
              />
            </HStack>
          </VStack>
        </DetailList>
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="gray.400">
              かなサポ
            </Text>
            <ListItem
              title="チャットをやさしい日本語で表示"
              right={
                <Switch
                  isChecked={settings.enableKanasapo}
                  onToggle={(value) => {
                    dispatch(setEnableKanasapo(value));
                  }}
                />
              }
            />
            <ListItem
              title="UDフォントで表示"
              right={
                <Switch
                  isChecked={settings.enableUDFont}
                  onToggle={(value) => {
                    dispatch(setEnableUDFont(value));
                  }}
                />
              }
            />
          </VStack>
        </DetailList>
        {!pushConfigData.isLoading && (
          <DetailList>
            <VStack space={4} paddingBottom={4}>
              <Text bold color="gray.400">
                メッセージ通知
              </Text>
              <ListItem
                title="メッセージ通知を受信する"
                right={
                  <Switch
                    isChecked={settings.enablePushNotification}
                    onToggle={(value) => {
                      if (pushConfigData.isError) {
                        setPushConfig({ body: { notify_type: value ? 0 : 2 } });
                      } else {
                        patchPushConfig({ body: { notify_type: value ? 0 : 2 } });
                      }
                      dispatch(setEnablePushNotification(value));
                    }}
                  />
                }
              />
              {settings.enablePushNotification && (
                <>
                  <ListItem
                    title="メンション(@)・DMのみ通知する"
                    right={
                      <Switch
                        isChecked={isMentionOnly}
                        onToggle={(value) => {
                          setIsMentionOnly(value);
                          patchPushConfig({ body: { notify_type: value ? 1 : 0 } });
                        }}
                      />
                    }
                  />
                  {Platform.OS === 'web' ? (
                    <ListItem
                      title="ウェブブラウザで通知を受信する(ブラウザ・OSの許可が必要です)"
                      right={
                        <Switch
                          isChecked={settings.enableWebNotification}
                          onToggle={(value) => {
                            dispatch(setEnableWebNotification(value));
                          }}
                        />
                      }
                    />
                  ) : null}
                </>
              )}
            </VStack>
          </DetailList>
        )}
        {isUmotionUser ? null : (
          <DetailList>
            <VStack space={4} paddingBottom={4}>
              <Text bold color="gray.400">
                ユーザーアカウント
              </Text>
              <Button
                variant="outline"
                colorScheme="warning"
                onPress={onDeleteCurrentUserPress}
                isLoading={isDeletingCurrentUser}
                isDisabled={isDeleteCurrentUserSuccess || isDeletingCurrentUser}
              >
                Chumlyを退会する
              </Button>
              <UsageNote colorScheme="warning">
                アカウントを削除します。作成済みのメッセージやタスクは「削除済みユーザーのデータ」として残ります。
              </UsageNote>
            </VStack>
          </DetailList>
        )}
        <DetailList>
          <VStack space={4} paddingBottom={4}>
            <Text bold color="gray.400">
              キーワード通知を追加
            </Text>
            {/* @ts-expect-error TS(2741): Property 'autoCompleteType' is missing in type '{ ... Remove this comment to see the full error message */}
            <TextArea
              value={inputKeyWord}
              width={Platform.OS === 'web' ? '50%' : '100%'}
              height="100px"
              onChangeText={(e) => setInputKeyWord(e)}
            />
            <Button width="80px" onPress={pushKeywordQuery.data?.keyword ? handleUpdateKeyword : handlePostKeyword}>
              保存
            </Button>
          </VStack>
        </DetailList>
      </VStack>
    </BaseScreenBuilder>
  );
};
