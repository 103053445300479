import { useRoute, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Skeleton } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import { usePostFarmsMutation, usePutFarmsByIdMutation, useGetFarmsByIdQuery } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const AdminFarmCreateEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const farm = useGetFarmsByIdQuery(queryParams?.id ? { id: queryParams?.id } : skipToken);

  const [post, postResult] = usePostFarmsMutation();
  const [put, putResult] = usePutFarmsByIdMutation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const isPost = !!(!queryParams?.id || queryParams?.id === 'undefined');

  const onCancel = () => {
    navigation.goBack();
  };

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      key: 'memo',
      label: 'メモ',
    },
    {
      key: 'owner_name',
      label: '牧場オーナー',
    },
    {
      key: 'details',
      label: '詳細',
      type: 'farmDetails',
    },
  ];

  if (farm.isLoading) {
    return <Skeleton.Text />;
  }

  return (
    <ScreenWidthModal title="管理者用牧場追加・編集">
      <BaseScreenBuilder title="管理者用牧場追加・編集">
        <BaseFormBuilder
          //defaultValues={defaultValues}
          defaultValues={farm?.data}
          update={isPost ? post : put}
          emptyMessage="牧場がありません"
          onCancel={onCancel}
          errorMessage={errorMessages}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
          fields={fields}
          onSubmit={(sendingData) => {
            setErrorMessages((errorMessages) => []);
            const { name, memo, owner_name, details } = sendingData;

            if (isPost) {
              post({
                body: {
                  farm: {
                    name,
                    memo,
                    owner_name,
                    details,
                  },
                },
              })
                .unwrap()
                .then((resp) => navigation.goBack())
                .catch((error) => {
                  setErrorMessages(errorMessageBuilder(error, fields));
                  console.error('update error: ', error);
                });
            } else {
              put({
                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                id: queryParams?.id,
                body: {
                  farm: {
                    name,
                    memo,
                    owner_name,
                    details,
                  },
                },
              })
                .unwrap()
                .then((resp) => navigation.goBack())
                .catch((error) => {
                  setErrorMessages(errorMessageBuilder(error, fields));
                  console.error('update error: ', error);
                });
            }
          }}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
