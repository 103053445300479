import { useNavigation } from '@react-navigation/native';
import { HStack, VStack, Avatar, Text } from 'native-base';
import * as React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useGetAppointmentsReservableServicesQuery } from '~/api/uFeedApi';
import { ItemList } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AppointmentServiceListScreen: React.FC = () => {
  const navigation = useNavigation();
  const { isLoading, isError, isSuccess, data } = useGetAppointmentsReservableServicesQuery();

  return (
    <BaseScreenBuilder
      isLoading={isLoading}
      isError={isError}
      errorMessage="サービスの読み込みに問題が発生しました"
      isEmpty={isSuccess && data.length === 0}
      emptyMessage="利用可能なサービスがありません。サービス提供を行うユーザーをグループに招待してください。"
    >
      <VStack space={8}>
        <ItemList>
          {data?.map((d) => {
            return (
              <TouchableOpacity
                onPress={() =>
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('AppointmentServiceDetail', {
                    id: d?.id,
                  })
                }
                key={d.id?.toString()}
              >
                <HStack key={d?.name} space={2}>
                  <Avatar source={{ uri: d?.provider?.avatar }} size="md" />
                  <VStack space={1}>
                    <Text bold>{d?.name}</Text>
                    <Text numberOfLines={3}>{d?.description}</Text>
                    <Text>{d?.provider?.name}</Text>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            );
          })}
        </ItemList>
      </VStack>
    </BaseScreenBuilder>
  );
};
