import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { format } from 'date-fns';
import { VStack, HStack, Text, Badge, Pressable } from 'native-base';
import { useState } from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { ItemList } from '~/components/ItemList';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

interface Props {
  farmId: number;
  groupId: number;
}

export const ChatChannelList: React.FC<Props> = ({ farmId, groupId }) => {
  const { chatClient, chatUserId } = useStreamChatAuthContext();
  const [channels, setChannels] = useState([]);
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const group = useGetGroupsByGroupIdQuery({ groupId });

  useDidUpdate(
    () => {
      if (!group.data) {
        return;
      }
      const init = async () => {
        const chat_channel_id = group?.data?.chat_channel_id;
        const newChannels = await chatClient.queryChannels(
          {
            id: {
              $in: [chat_channel_id ? chat_channel_id : ''],
            },
          },
          [{ last_message_at: -1 }],
          {
            watch: false, // this is the default
            state: true,
            limit: 3,
          }
        );
        // @ts-expect-error TS(2345): Argument of type 'Channel<StreamChatGenerics>[]' i... Remove this comment to see the full error message
        setChannels(newChannels);
      };
      init();
    },
    [group.data],
    true
  );
  return (
    <ItemList>
      {channels.map((channel) => {
        // @ts-expect-error TS(2339): Property 'state' does not exist on type 'never'.
        const messages = channel?.state?.messages;
        const latestMessage = messages.length > 0 ? messages[messages.length - 1] : undefined;

        return (
          <Pressable
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'never'.
            key={channel?.data?.id}
            onPress={() => {
              Platform.OS === 'web'
                ? // @ts-expect-error TS(2339): Property 'data' does not exist on type 'never'.
                  linkTo(`/chat/${channel?.data?.id}`)
                : // @ts-expect-error TS(2769): No overload matches this call.
                  navigation.navigate({ name: 'ChatChannelMessages', params: { channelId: channel.id } });
            }}
            _pressed={{
              bg: 'gray.100',
            }}
          >
            <VStack>
              <HStack justifyContent="space-between">
                {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'never'. */}
                <Text bold>{channel?.data?.name}</Text>
                <HStack>
                  {/* @ts-expect-error TS(2339): Property 'state' does not exist on type 'never'. */}
                  {channel?.state?.unreadCount ? (
                    <Badge
                      colorScheme="danger"
                      rounded="full"
                      variant="solid"
                      _text={{
                        fontSize: '2xs',
                      }}
                    >
                      {/* @ts-expect-error TS(2339): Property 'state' does not exist on type 'never'. */}
                      {channel?.state?.unreadCount}
                    </Badge>
                  ) : null}
                </HStack>
              </HStack>
              <HStack justifyContent="space-between">
                {latestMessage ? (
                  <>
                    <Text numberOfLines={1} flexShrink={1} color="gray.500">
                      @{latestMessage?.user?.name}: {latestMessage?.text}
                    </Text>
                    <Text color="gray.400">{format(latestMessage?.created_at, 'yyyy-MM-dd')}</Text>
                  </>
                ) : (
                  <Text color="gray.400">メッセージがありません</Text>
                )}
              </HStack>
            </VStack>
          </Pressable>
        );
      })}
    </ItemList>
  );
};
