import { useRoute } from '@react-navigation/native';
import { Alert, Text, Skeleton } from 'native-base';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder';
import { EventTimeline } from '~/components/EventListCard/EventTimeline';
import { useEventsQuery } from '~/hooks/useEventsQuery';

// @ts-expect-error TS(2304): Cannot find name 'Props'.
export const EventListScreen: React.FC<Props> = () => {
  const { params } = useRoute();
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type 'Readonl... Remove this comment to see the full error message
  const { groupId, farmId } = params;
  const groupQuery = useGetGroupsByGroupIdQuery({
    groupId,
  });
  const events = useEventsQuery({
    groupId,
  });

  return (
    <BaseScreenBuilder title={groupQuery.isSuccess ? `${groupQuery.data?.name}のイベント` : ''}>
      {events?.isLoading ? (
        <Skeleton.Text height="12" borderRadius={10} />
      ) : events?.data.length > 0 ? (
        // @ts-expect-error TS(2322): Type 'FarmEvent[]' is not assignable to type '[]'.
        <EventTimeline groupId={groupId} events={events.data} farmId={farmId} />
      ) : (
        <Text>イベントがありません</Text>
      )}
      {events?.isError ? (
        <Alert status="error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'. */}
          <Text>エラーが発生しました: {events?.error?.error}</Text>
        </Alert>
      ) : null}
    </BaseScreenBuilder>
  );
};
