import { useNavigation } from '@react-navigation/native';
import { Box, StyledProps } from 'native-base';

import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

import { ChatChannelList } from './ChatChannelList';

interface Props {
  farmId: number;
  groupId: number;
  disableVirtializedList?: boolean;
}

export const ChatChannelSummary: React.FC<Props & StyledProps> = ({ farmId, groupId, ...remainingProps }) => {
  const { chatClient, chatUserId } = useStreamChatAuthContext();
  const navigation = useNavigation();

  return chatClient && chatClient.userID ? (
    <Box {...remainingProps}>
      <ChatChannelList farmId={farmId} groupId={groupId} />
    </Box>
  ) : null;
};
