import { useToast, HStack, Button, Text } from 'native-base';
import { useRef } from 'react';

const backgroundColor = 'black';
const borderColor = 'white';
const textColor = 'white';

export const useCustomToast = () => {
  const toast = useToast();
  const toastIdRef = useRef();
  const id = 'custom-toast';

  const show = (description: string, optionString?: string, onOptionPress?: () => void) => {
    if (toast.isActive(id)) {
      return;
    }
    const hasOption = optionString && onOptionPress;
    toastIdRef.current = toast.show({
      id,
      render: () => (
        <HStack
          paddingX={3}
          paddingY={hasOption ? 0 : 2}
          alignItems="center"
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          borderWidth={2}
          borderRadius={10}
        >
          <Text color={textColor}>{description}</Text>
          {hasOption ? (
            <Button variant="link" onPress={onOptionPress}>
              {optionString}
            </Button>
          ) : null}
        </HStack>
      ),
      placement: 'top',
    });
  };

  const showWithShare = (description: string, onSharePress: () => void) => {
    show(description, 'チャットで共有', onSharePress);
  };

  const showWithClose = (description: string) => {
    show(description, '閉じる', () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    });
  };

  return {
    showWithShare,
    showWithClose,
    show,
  };
};
