import { useNavigation, useRoute } from '@react-navigation/native';
import { Skeleton } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import {
  useGetCurrentUserGroupsQuery,
  useGetVisitsByVisitsIdQuery,
  usePutVisitsByVisitsIdMutation,
} from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { DateUtil } from '~/utils/DateUtils';

export const VisitsEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'visitsId' does not exist on type '{}'.
  const visitsId = queryParams.visitsId;
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams.groupId;
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetVisitsByVisitsIdQuery(queryParams);

  const [errorMessages, setErrorMessages] = React.useState([]);

  const [update, { isLoading: isUpdating, data, error: putError }] = usePutVisitsByVisitsIdMutation();

  const userGroups = useGetCurrentUserGroupsQuery();

  const [selectedGroupId, setSelectedGroupId] = useState();

  const onCancel = () => navigation.goBack();

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    const { memo, date } = sendingData;
    update({
      visitsId,
      body: {
        visit: {
          memo,
          date,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        navigation.goBack();
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  const max = DateUtil.toYYYYMMDD(new Date(), '-');

  const fields = [
    {
      key: 'memo',
      type: 'textarea',
      label: '内容',
      rules: {
        required: {
          value: true,
          message: 'メモを入力してください',
        },
      },
    },
    {
      key: 'date',
      label: '訪問日',
      type: 'date',
      rules: {
        required: {
          value: true,
          message: '訪問日を選択してください',
        },
        max,
      },
    },
  ];
  if (queryResult.isLoading) {
    return <Skeleton.Text />;
  }

  return (
    <ScreenWidthModal title="訪問履歴 編集">
      <BaseScreenBuilder title="訪問履歴 編集">
        <BaseFormBuilder
          // @ts-expect-error TS(2322): Type '({ error?: undefined; data?: undefined; fulf... Remove this comment to see the full error message
          queryResult={queryResult}
          isUpdating={isUpdating}
          error={putError || userGroups.error}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onFieldUpdate={(updated) => {
            if (updated.groupId !== updated[0]) {
              setSelectedGroupId(updated[0]);
            }
          }}
          // @ts-expect-error TS(2322): Type '({ key: string; type: string; label: string;... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
