import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetFeedDesignsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';

export const GroupFeedDesignListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  // @ts-expect-error TS(2339): Property 'queryParams' does not exist on type 'obj... Remove this comment to see the full error message
  const queryParams = params?.queryParams || {};
  const queryResult = useGetFeedDesignsQuery(queryParams);

  return (
    <BaseScreenBuilder
      title=""
      headerRightLabel="追加"
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      onHeaderRightPress={() => navigation.navigate('GroupFeedDesignCreate', {})}
    >
      <ListBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<void, Bas... Remove this comment to see the full error message
        queryResult={queryResult}
        onSelect={(item) =>
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('FeedDesignDetail', {
            queryParams: {},
          })
        }
        emptyMessage="飼料設計データがありません。「追加」ボタンをタップしてデータを追加してください。"
      />
    </BaseScreenBuilder>
  );
};
