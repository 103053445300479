import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform } from 'react-native';

import {
  useGetCurrentUserQuery,
  useGetAppliedFeedDesignsByIdQuery,
  useDeleteAppliedFeedDesignsByIdMutation,
  useGetGroupsByGroupIdQuery,
  useGetFeedDesignsByIdQuery,
} from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { Section } from '~/components/Section';
import { SectionList } from '~/components/SectionList';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';

export const AppliedFeedDesignDetailScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const currentUser = useGetCurrentUserQuery({});

  const { chatClient } = useStreamChatAuthContext();

  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const queryResult = useGetAppliedFeedDesignsByIdQuery({ id: queryParams?.id });
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams?.groupId;
  const group = useGetGroupsByGroupIdQuery({ groupId });
  const [chatChannelId, setChatChannelId] = useState('');
  const [deleteAppliedFeedDesign, deleteAppliedFeedDesignResult] = useDeleteAppliedFeedDesignsByIdMutation({
    // @ts-expect-error TS(2345): Argument of type '{ id: any; }' is not assignable ... Remove this comment to see the full error message
    id: queryParams?.id,
  });

  const [skip, setSkip] = React.useState(true);
  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  const feedDesign = useGetFeedDesignsByIdQuery({ id: queryResult.data?.feed_design_id }, { skip });
  const [canEdit, setCanEdit] = useState(false);
  const linkTo = useLinkTo();

  useDidUpdate(
    () => {
      if (currentUser.data && queryResult.data) {
        if (currentUser.data?.id == queryResult.data?.user?.id) {
          setCanEdit(true);
        }
      }
    },
    [currentUser.data, queryResult],
    true
  );
  useDidUpdate(
    () => {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      group.data && setChatChannelId(group.data?.chat_channel_id);
    },
    [group],
    true
  );

  useDidUpdate(
    () => {
      queryResult.data && setSkip(false);
    },
    [queryResult],
    true
  );

  return (
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'UseQueryHo... Remove this comment to see the full error message
    <BaseScreenBuilder title={feedDesign?.name}>
      <SectionList>
        <Section title="">
          <DetailsBuilder
            onEditPress={
              currentUser.data?.id === queryResult.data?.user?.id
                ? () =>
                    Platform.OS === 'web'
                      ? linkTo(
                          // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                          `/farms/${queryParams.farmId}/groups/${queryParams.groupId}/applied_feed_designs/${queryParams?.id}/create_edit`
                        )
                      : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        navigation.navigate('AppliedFeedDesignCreateEdit', {
                          ...queryParams,
                        })
                : undefined
            }
            // @ts-expect-error TS(2322): Type '(() => MutationActionCreatorResult<MutationD... Remove this comment to see the full error message
            onDeletePress={
              canEdit
                ? () => {
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    return deleteAppliedFeedDesign({ id: queryParams?.id });
                  }
                : undefined
            }
            onSharePress={async () => {
              const channel = chatClient?.channel('team', chatChannelId);
              // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
              const message = `${U_FEED_URL_BASE}/applied_feed_designs/${queryParams?.id}`;

              if (Platform.OS === 'web') {
                linkTo(`/chat/${channel.id}?message=${encodeURIComponent(message)}`);
              } else {
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('ChatChannelMessages', {
                  channelId: channel.id,
                  message,
                });
              }
            }}
            // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetApplie... Remove this comment to see the full error message
            queryResult={queryResult}
            emptyMessage="飼料設計データがありません"
            fields={[
              {
                key: 'user',
                label: '作成者',
                type: 'user',
              },
              {
                key: 'memo',
                label: 'メモ',
              },
              {
                key: 'date',
                label: '適用日付',
                type: 'date',
              },
            ]}
          >
            <Section title="飼料設計">
              <DetailsBuilder
                // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFeedDe... Remove this comment to see the full error message
                queryResult={feedDesign}
                emptyMessage="飼料設計データがありません"
                fields={[
                  {
                    key: 'name',
                    label: '名前',
                  },
                  {
                    key: 'memo',
                    label: 'メモ',
                  },
                  {
                    key: 'user',
                    label: '作成者',
                    type: 'user',
                  },
                  {
                    key: 'files',
                    label: '添付ファイル',
                    type: 'files',
                  },
                ]}
              />
            </Section>
          </DetailsBuilder>
        </Section>
      </SectionList>
    </BaseScreenBuilder>
  );
};
