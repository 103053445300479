import { FormControl, VStack, Button } from 'native-base';
import { useState } from 'react';

export const ConfirmWithComment = ({ onConfirmed }: any) => {
  const [doneComment, setDoneComment] = useState();
  const [isChecked, setIsChecked] = useState();

  return (
    <FormControl>
      <VStack space={4}>
        <Button
          onPress={() => {
            onConfirmed(isChecked, doneComment);
          }}
        >
          完了にする
        </Button>
      </VStack>
    </FormControl>
  );
};
