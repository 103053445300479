import { Portal } from '@gorhom/portal';
import { Pressable, Box } from 'native-base';
import { FC, useState, useEffect } from 'react';
import Svg, { Path } from 'react-native-svg';
import CustomEmojiPicker from './CustomEmojiPicker.web';

type Props = {
  onEmojiSelect: (emoji: string) => void;
  inputRef: React.MutableRefObject<any>;
};

const ChatCustomInputEmojiPickerWeb: FC<Props> = ({ onEmojiSelect, inputRef }) => {
  const [open, setOpen] = useState(false);
  const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    if (inputRef.current) {
      const { top, left } = inputRef.current.getBoundingClientRect();
      setInputPosition({ x: left + 150, y: top - 450 });
    }
  }, [inputRef]);

  return (
    <>
      <Pressable onPress={() => setOpen(true)} p={1}>
        {/* @ts-expect-error TS(2322): Type '{ children: Element; width: number; height: ... Remove this comment to see the full error message */}
        <Svg width={24} height={24} viewBox="-2 -2 30 30">
          <Path
            // @ts-expect-error TS(2322): Type '{ d: string; fill: string; }' is not assigna... Remove this comment to see the full error message
            d="M14 4.4C8.6 4.4 4.4 8.6 4.4 14c0 5.4 4.2 9.6 9.6 9.6c5.4 0 9.6-4.2 9.6-9.6c0-5.4-4.2-9.6-9.6-9.6zM2 14c0-6.6 5.4-12 12-12s12 5.4 12 12s-5.4 12-12 12s-12-5.4-12-12zM12.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM18.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM8.6 15.4c.6-.4 1.2-.2 1.6.2c.6.8 1.6 1.8 3 2c1.2.4 2.8.2 4.8-2c.4-.4 1.2-.6 1.6 0c.4.4.6 1.2 0 1.6c-2.2 2.6-4.8 3.4-7 3c-2-.4-3.6-1.8-4.4-3c-.4-.6-.2-1.2.4-1.8z"
            fill="#8D8D8D"
          />
        </Svg>
      </Pressable>
      {open && (
        <Portal hostName="EmojiPortalHost">
          <Box position="absolute" top={inputPosition.y} right={0} bottom={0} left={inputPosition.x} zIndex={3}>
            <Pressable onPress={() => setOpen(false)} position="absolute" top={0} right={0} bottom={0} left={0} />
            <Box position="relative" zIndex={2} w="fit-content">
              <CustomEmojiPicker
                onEmojiSelect={(emoji: any) => {
                  onEmojiSelect(emoji.native);
                  setOpen(false);
                }}
                disabledCustomEmojis
              />
            </Box>
          </Box>
        </Portal>
      )}
    </>
  );
};

export default ChatCustomInputEmojiPickerWeb;
