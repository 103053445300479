import { U_FEED_URL_BASE } from '@env';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';

import { usePostGroupsByGroupIdVisitsMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useCustomToast } from '~/hooks/useCustomToast';
import { useGroupChat } from '~/hooks/useGroupChat';
import { DateUtil } from '~/utils/DateUtils';

export const VisitsCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const groupId = queryParams.groupId;

  const [errorMessages, setErrorMessages] = React.useState([]);

  const [post, { isLoading: isUpdating, data, error: postError }] = usePostGroupsByGroupIdVisitsMutation();

  const toast = useCustomToast();
  // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
  const { share } = useGroupChat({ farmId: queryParams.farmId, groupId: queryParams.groupId });

  const showToast = async (resp: any, farmId: any, groupId: any) => {
    if (resp) {
      toast.showWithShare('訪問履歴を作成しました', () => {
        share(`訪問履歴を作成しました\n${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/visits/${resp.id}`);
      });
    }
    navigation.goBack();
  };
  const onCancel = () => navigation.goBack();

  const onSubmit = (sendingData: any) => {
    setErrorMessages([]);
    const { memo, date } = sendingData;
    post({
      groupId,
      body: {
        visit: {
          memo,
          date,
        },
      },
    })
      .unwrap()
      .then((resp) => {
        // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
        showToast(resp, queryParams.farmId, groupId);
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  const today = DateUtil.toYYYYMMDD(new Date(), '-');

  const defaultValues = { date: today };

  const fields = [
    {
      key: 'memo',
      type: 'textarea',
      label: '内容',
      rules: {
        required: {
          value: true,
          message: 'メモを入力してください',
        },
      },
    },
    {
      key: 'date',
      label: '訪問日',
      type: 'date',
      rules: {
        required: {
          value: true,
          message: '訪問日を選択してください',
        },
        max: today,
      },
    },
  ];

  return (
    <ScreenWidthModal title="訪問履歴 追加">
      <BaseScreenBuilder title="訪問履歴 追加">
        <BaseFormBuilder
          isUpdating={isUpdating}
          defaultValues={defaultValues}
          error={postError}
          onSubmit={onSubmit}
          onCancel={onCancel}
          // onFieldUpdate={(updated) => {
          //   if (updated.groupId !== updated[0]) {
          //     setSelectedGroupId(updated[0]);
          //   }
          // }}
          // @ts-expect-error TS(2322): Type '({ key: string; type: string; label: string;... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
