import { useMemo } from 'react';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
// @ts-expect-error TS(2305): Module '"~/lib/StreamChatReact"' has no exported m... Remove this comment to see the full error message
import { ChannelAvatarProps } from '~/lib/StreamChatReact';
import { AvatarWithOnline } from './AvatarWithOnline';

export const ChatCustomChannelAvatar: React.FC<ChannelAvatarProps> = ({ channel }) => {
  const { chatUserId } = useStreamChatContext();

  const user = useMemo(() => {
    const messages = channel?.state.messages;
    const lastMessage = messages.length ? messages[messages.length - 1] : undefined;
    const userId = channel?.data?.is_my_channel ? chatUserId : lastMessage?.user?.id;
    return {
      id: userId,
      name: channel.state.members[userId || '']?.user?.name,
    };
  }, [channel, channel?.state.messages, chatUserId]);

  return <AvatarWithOnline userId={user?.id || ''} name={user?.name || ''} size="xs" iconSize="4xl" />;
};
