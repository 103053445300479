import { ScrollView, FormControl, VStack, TextArea } from 'native-base';
import { Logic } from 'native-base-form-builder';
import { useController } from 'react-hook-form';

import { FormFieldLabel } from './FormFieldLabel';

export const FormTextArea: React.FC<React.ComponentProps<typeof Logic>> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  textInputProps,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  return (
    // @ts-expect-error TS(2322): Type 'string | FieldError | Merge<FieldError, Fiel... Remove this comment to see the full error message
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack>
        <FormFieldLabel label={label} isRequired={isRequired} />
        {/* @ts-expect-error TS(2741): Property 'autoCompleteType' is missing in type '{ ... Remove this comment to see the full error message */}
        <TextArea
          value={field.value}
          // @ts-expect-error TS(2339): Property 'value' does not exist on type 'number'.
          onChange={(e) => field.onChange(e.currentTarget.value)}
          onChangeText={(text) => field.onChange(text)}
          size="lg"
          variant="outline"
          borderColor="black"
          py="3"
          testID={textInputProps?.testID}
        />
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
