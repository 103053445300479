import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigation, useNavigationState } from '@react-navigation/native';
import { HStack, Text, Icon, Box, Image, Pressable } from 'native-base';
import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import { useNotificationBadge } from '~/hooks/useNotificationBadge';

import { HeaderMenuButton } from './HeaderMenuButton';

export const AppHeader: React.FC = () => {
  const navigation = useNavigation();
  const rootName = useNavigationState((state) => state.routeNames[state.index]);
  const linkTo = useLinkTo();

  const { shouldShowNotificationBadge } = useNotificationBadge();

  const HeaderItem = ({
    iconName,
    label,
    navigateTo,
    path,
    badge = false,
  }: {
    iconName: string;
    label: string;
    navigateTo: string;
    path: string;
    badge?: boolean;
  }) => {
    const isSelected = rootName === navigateTo;
    const color = isSelected ? 'black.800' : 'gray.400';
    return (
      <Pressable
        // @ts-expect-error TS(2769): No overload matches this call.
        onPress={() => (Platform.OS === 'web' ? linkTo(path) : navigation.navigate(navigateTo))}
        paddingX={3}
        borderRadius={30}
        paddingY={2}
        _hover={{
          backgroundColor: 'gray.100',
        }}
        alignSelf="center"
      >
        <HStack alignItems="center" space="1.5">
          <Icon as={Ionicons} name={iconName} size="md" color={color} />
          {badge && (
            <Box
              borderRadius={30}
              height={3}
              width={3}
              alignSelf={undefined}
              backgroundColor="#FF4949"
              top={-10}
              left={-12}
              margin={0}
              padding={0}
            />
          )}
          <Text fontSize="xs" bold color={color} left={badge ? -12 : 0}>
            {label}
          </Text>
        </HStack>
      </Pressable>
    );
  };

  return (
    <HStack
      justifyContent="space-between"
      backgroundColor="white"
      borderBottomWidth={1}
      borderColor="gray.200"
      height="62px"
    >
      <HStack space={10} alignItems="center" paddingX={4} backgroundColor="white">
        <HStack space={2} alignItems="center">
          <HeaderMenuButton navigation={navigation} />
          {/* @ts-expect-error TS(2786): 'TouchableOpacity' cannot be used as a JSX compone... Remove this comment to see the full error message */}
          <TouchableOpacity onPress={() => navigation.navigate('HomeNavigator')}>
            <Image
              source={require('../../assets/logo/chumly-logo-horizontal.png')}
              alt="Chumly"
              width={2498 * 0.06}
              height={444 * 0.06}
            />
          </TouchableOpacity>
        </HStack>
        <HStack>
          <HeaderItem iconName="home-outline" navigateTo="HomeNavigator" label="ホーム" path="/" />
          <HeaderItem iconName="chatbubble-outline" navigateTo="Chat" label="チャット" path="/chat" />
          <HeaderItem iconName="mail-outline" navigateTo="DMNavigator" label="ダイレクト・メッセージ" path="/dm" />
          <HeaderItem iconName="checkbox-outline" navigateTo="TaskSummaryNavigator" label="タスク" path="/tasks" />
        </HStack>
      </HStack>
      <HStack alignItems="center" paddingX={3} paddingY={4} backgroundColor="white">
        <HeaderItem iconName="bookmark-outline" navigateTo="BookmarkNavigator" label="ブックマーク" path="/bookmarks" />
        <HeaderItem iconName="search-outline" navigateTo="Search" label="検索" path="/search" />
        <HeaderItem
          iconName="notifications-outline"
          navigateTo="NotificationsNavigator"
          label="通知"
          path="/notifications"
          badge={shouldShowNotificationBadge}
        />
      </HStack>
    </HStack>
  );
};
