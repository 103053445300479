import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Box, Skeleton, Stack, VStack, HStack, StyledProps, Text, useTheme, Button, Icon, Hidden } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';

import { GraphAlertBox } from '../GraphAlertBox';

import { useGetGroupsByGroupIdActivitiesStatisticQuery } from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useActivitiesByRange } from '~/hooks/useActivitiesByRange';
import { DateUtil } from '~/utils/DateUtils';

import { DashboardPanel } from './DashboardPanel';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
}

export const ActivityDashboard: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    ...props
  }) => {
    const {
      setEndDate,
      setRange,
      activities,
      preActivities,
      range: currentRange,
      beginDate,
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    } = useActivitiesByRange(groupId, endDate, range);
    const activitiesStatistic = useGetGroupsByGroupIdActivitiesStatisticQuery(
      groupId
        ? {
            groupId,
          }
        : skipToken
    );
    const { colors } = useTheme();
    const { chatClient } = useStreamChatAuthContext();
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    if (activities?.isLoading || preActivities?.isLoading || activitiesStatistic?.isLoading) {
      return <Skeleton height={height} />;
    }

    if (activities?.isError || preActivities?.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            activities.refetch();
            preActivities.refetch();
          }}
        />
      );
    }

    if (!activities?.data || !preActivities?.data) {
      return <Skeleton height={height} />;
    }

    const lastActivity = activities.data.slice(-1)?.[0];

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const feedTotal = activities.data.slice(-1).reduce((acc, cur) => acc + cur.average_feed_min, 0);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const moveTotal = activities.data.slice(-1).reduce((acc, cur) => acc + cur.average_move_min, 0);

    const ruminationStandingTotal = activities.data
      .slice(-1)
      // @ts-expect-error TS(2551): Property 'average_rumination_standing_min' does no... Remove this comment to see the full error message
      .reduce((acc, cur) => acc + cur.average_rumination_standing_min, 0);

    const ruminationLyingTotal = activities.data
      .slice(-1)
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      .reduce((acc, cur) => acc + cur.average_rumination_lying_min, 0);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const standTotal = activities.data.slice(-1).reduce((acc, cur) => acc + cur.average_stand_min, 0);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const lieTotal = activities.data.slice(-1).reduce((acc, cur) => acc + cur.average_lie_min, 0);

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const preFeedMA = activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_feed_min, 0) / 5;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const preMoveMA = activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_move_min, 0) / 5;

    const preRuminationStandingMA =
      // @ts-expect-error TS(2551): Property 'average_rumination_standing_min' does no... Remove this comment to see the full error message
      activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_rumination_standing_min, 0) / 5;

    const preRuminationLyingMA =
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_rumination_lying_min, 0) / 5;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const preStandMA = activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_stand_min, 0) / 5;

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const preLieMA = activities.data.slice(-6, -1).reduce((acc, cur) => acc + cur.average_lie_min, 0) / 5;

    const total = moveTotal + feedTotal + ruminationStandingTotal + ruminationLyingTotal + standTotal + lieTotal;

    return (
      <>
        <Box paddingX="auto" paddingBottom={4} {...props}>
          <VStack>
            <HStack flexWrap="wrap" mr={{ base: -2, md: -5 }}>
              <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb={{ base: 2, md: 5 }} mr={{ base: 2, md: 5 }}>
                <DashboardPanel
                  label="採食"
                  prevMin={preFeedMA}
                  curMin={feedTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.feed}
                  isPreview={isPreview}
                />
                <DashboardPanel
                  label="動態"
                  prevMin={preMoveMA}
                  curMin={moveTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.move}
                  isPreview={isPreview}
                />
              </HStack>
              <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb={{ base: 2, md: 5 }} mr={{ base: 2, md: 5 }}>
                <DashboardPanel
                  label="起立(反芻)"
                  prevMin={preRuminationStandingMA}
                  curMin={ruminationStandingTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.ruminationStanding}
                  isPreview={isPreview}
                />
                <DashboardPanel
                  label="横臥(反芻)"
                  prevMin={preRuminationLyingMA}
                  curMin={ruminationLyingTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.ruminationLying}
                  labelTextColor="black"
                  isPreview={isPreview}
                />
              </HStack>
            </HStack>

            <HStack flexWrap="wrap" mr={{ base: -2, md: -5 }}>
              <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb={{ base: 2, md: 5 }} mr={{ base: 2, md: 5 }}>
                <DashboardPanel
                  label="起立(非活動)"
                  prevMin={preStandMA}
                  curMin={standTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.stand}
                  labelTextColor="black"
                  isPreview={isPreview}
                />
                <DashboardPanel
                  label="横臥(非活動)"
                  prevMin={preLieMA}
                  curMin={lieTotal}
                  // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                  labelColor={colors.um.activity.lie}
                  labelTextColor="black"
                  isPreview={isPreview}
                />
              </HStack>
              <Box flex={1} minW={320} mr={{ base: 2, md: 5 }} />
            </HStack>

            {!isPreview ? (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                mt={2}
                space={{ base: 1, md: 4 }}
              >
                <HStack space={1}>
                  <Text bold>合計時間 </Text>
                  <Text>{DateUtil.minToHours(total)}</Text>
                </HStack>
                <Hidden till="md">
                  <Box w="px" h={4} bg="black" />
                </Hidden>
                <HStack space={1}>
                  <Text bold>最終更新 </Text>
                  <Text>{activitiesStatistic?.data?.updated_at}</Text>
                </HStack>
              </Stack>
            ) : null}
          </VStack>
          {!isPreview ? (
            <HStack mt={2} justifyContent="flex-end">
              <Button
                variant="unstyled"
                onPress={async () => {
                  const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
                  const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/dashboards/activity/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?target=all`;

                  const channel = chatClient?.channel('team', chatChannelId);
                  if (Platform.OS === 'web') {
                    linkTo(`/chat/${channel?.id}?message=${encodeURIComponent(message)}`);
                  } else {
                    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('ChatChannelMessages', {
                      channelId: channel.id,
                      message,
                    });
                  }
                }}
                leftIcon={<Icon as={Ionicons} name="share-outline" size="md" />}
                testID="activity-dashboard-share-button"
              >
                <Text bold>チャットで共有</Text>
              </Button>
            </HStack>
          ) : null}
        </Box>
      </>
    );
  }
);
