import { useLinkTo, useNavigation } from '@react-navigation/native';
import { VStack, Text } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdGroupFeedDesignsQuery } from '~/api/uFeedApi';

import { EventTimelineItem } from './EventTimelineItem';

interface Props {
  events: [];
  groupId: number;
  farmId: number;
}

export const EventTimeline: React.FC<Props> = React.memo(({ events, groupId, farmId }) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const groupFeedDesigns = useGetGroupsByGroupIdGroupFeedDesignsQuery({
    groupId,
  });

  const getFeedDesign = (feedDesignId: number) => {
    const found = groupFeedDesigns.data?.find((data) => data.feed_design?.id === feedDesignId);
    return found?.feed_design || null;
  };

  const renderFeedDesign = (feedDesignId: number) => {
    const found = getFeedDesign(feedDesignId);
    if (!found) {
      return null;
    }

    return (
      <VStack paddingLeft={2}>
        <Text>{found?.name}</Text>
      </VStack>
    );
  };

  return (
    <VStack flex={1}>
      {events?.map((event, index) => (
        <EventTimelineItem
          // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
          key={event.type + event.id?.toString()}
          // @ts-expect-error TS(2339): Property 'date' does not exist on type 'never'.
          dateLabel={event.date
            .split('-')
            .splice(1)
            .map((num: any) => Number(num).toString())
            .join('/')}
          eventLabel={
            // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
            event.type === 'visits'
              ? '訪問'
              : // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
                event.type === 'additives'
                ? '添加剤'
                : // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
                  event.type === 'appliedFeedDesigns'
                  ? '飼料設計適用'
                  : 'その他'
          }
          onPress={
            // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
            event.type === 'visits'
              ? () =>
                  Platform.OS === 'web'
                    ? // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                      linkTo(`/farms/${farmId}/groups/${groupId}/visits/${event.id}`)
                    : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('VisitsDetail', {
                        farmId,
                        groupId,
                        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                        visitsId: event.id,
                      })
              : // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
                event.type === 'additives'
                ? () =>
                    Platform.OS === 'web'
                      ? // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                        linkTo(`/farms/${farmId}/groups/${groupId}/additives/${event.id}`)
                      : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        navigation.navigate('AdditiveDetail', {
                          farmId,
                          groupId,
                          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                          additiveId: event.id,
                        })
                : // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
                  event.type === 'appliedFeedDesigns'
                  ? () =>
                      Platform.OS === 'web'
                        ? // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                          linkTo(`/farms/${farmId}/groups/${groupId}/applied_feed_designs/${event.id}`)
                        : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                          navigation.navigate('AppliedFeedDesignDetail', {
                            farmId,
                            groupId,
                            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                            id: event.id,
                          })
                  : undefined
          }
          // @ts-expect-error TS(2339): Property 'user' does not exist on type 'never'.
          authorLabel={event.user?.name}
          // @ts-expect-error TS(2339): Property 'user' does not exist on type 'never'.
          avatarUrl={event.user?.avatar}
          // @ts-expect-error TS(2339): Property 'memo' does not exist on type 'never'.
          description={event.memo}
          isFirst={index === 0}
          isLast={index === events.length - 1}
        >
          {/* @ts-expect-error TS(2339): Property 'feedDesignId' does not exist on type 'ne... Remove this comment to see the full error message */}
          {event.feedDesignId ? renderFeedDesign(event.feedDesignId) : null}
        </EventTimelineItem>
      ))}
    </VStack>
  );
});
