import { ENV_FILE_NAME } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Text } from 'native-base';
import { Platform, TouchableOpacity } from 'react-native';
import NetworkLogger from 'react-native-network-logger';

import { renderHeaderLeft } from './index';

import { ScreenWidthAdjuster } from '~/components';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useNotificationBadge } from '~/hooks/useNotificationBadge';
import { streami18n } from '~/hooks/useStreamChat';
import { Chat } from '~/lib/StreamChatReact';
import { UmotionSwitchFarmScreen } from '~/packages/u-motion-api/screens';
import {
  AboutThisAppScreen,
  UserSettingsScreen,
  AboutUsScreen,
  ChatChannelCreateEditScreen,
  ChatChannelListScreen,
  ChatDMListScreen,
  ChatChannelMessagesScreen,
  ChatThreadMessagesScreen,
  ChatChannelOverviewEditScreen,
  ChatBookmarkListScreen,
  FarmListScreen,
  FarmDetailScreen,
  FeedDesignListScreen,
  FeedDesignDetailScreen,
  GroupListScreen,
  GroupDetailScreen,
  GroupCreateScreen,
  GroupTaskListScreen,
  HelpScreen,
  TaskListScreen,
  TaskSummaryScreen,
  VisitsListScreen,
  VisitsDetailScreen,
  AdditiveListScreen,
  AdditiveDetailScreen,
  AppliedFeedDesignListScreen,
  AppliedFeedDesignDetailScreen,
  AppliedFeedDesignCreateEditScreen,
  AdminAccountEditScreen,
  AdminAccountDetailScreen,
  AdminUserListScreen,
  AdminUserDetailScreen,
  AdminUserEditScreen,
  AdminFarmListScreen,
  AdminFarmDetailScreen,
  AdminFarmCreateEditScreen,
  AdminGroupListScreen,
  AdminGroupDetailScreen,
  AdminGroupUserListScreen,
  AdminGroupUserDetailScreen,
  AdminExternalGroupUserInviteScreen,
  FeedDesignCreateScreen,
  FeedDesignEditScreen,
  GroupEditScreen,
  GroupFeedDesignCreateScreen,
  GroupFeedDesignListScreen,
  GroupSummaryScreen,
  UserSettingsPasswordEditScreen,
  UserSettingsEditScreen,
  TaskDetailScreen,
  TaskCreateEditScreen,
  VisitsEditScreen,
  VisitsCreateScreen,
  AdditiveCreateScreen,
  AdditiveEditScreen,
  AppliedFeedDesignEditScreen,
  AdminUserCreateScreen,
  AdminGroupCreateEditScreen,
  AdminGroupUserCreateScreen,
  MapScreen,
  SelectScreen,
  MultiSelectScreen,
  EventListScreen,
  GroupUserDetailScreen,
  ChatMemberListScreen,
  ChatDMCreateEditScreen,
  CalendarScreen,
  ActivityDashboardScreen,
  MilkAmoutDashboardScreen,
  ActivityGraphsScreen,
  MilkAmountGraphsScreen,
  SearchScreen,
  MyAppointmentServiceListScreen,
  MyAppointmentServiceDetailScreen,
  MyAppointmentServiceCreateEditScreen,
  MyAppointmentListScreen,
  MyAppointmentDetailScreen,
  MyAppointmentScheduleListScreen,
  AppointmentServiceListScreen,
  AppointmentServiceDetailScreen,
  AppointmentScheduleApplyScreen,
  AppointmentListScreen,
  ChatMultiColumnsViewScreen,
} from '~/screens';

import { AppHeader } from './AppHeader';
import { HeaderMenuButton } from './HeaderMenuButton';
import { NotificationsNavigator } from './NotificationNavigator';

const BottomTab = createBottomTabNavigator();
const Stack = Platform.OS === 'android' ? createStackNavigator() : createNativeStackNavigator();

export const commonModalScreens = (
  <>
    <Stack.Screen name="AdminGroupUserCreate" component={AdminGroupUserCreateScreen} />
    <Stack.Screen name="AdminFarmCreateEdit" component={AdminFarmCreateEditScreen} />
    <Stack.Screen name="FeedDesignCreate" component={FeedDesignCreateScreen} />
    <Stack.Screen name="FeedDesignEdit" component={FeedDesignEditScreen} />
    <Stack.Screen name="GroupEdit" component={GroupEditScreen} />
    <Stack.Screen name="UserSettingsPasswordEdit" component={UserSettingsPasswordEditScreen} />
    <Stack.Screen name="UserSettingsEdit" component={UserSettingsEditScreen} />
    <Stack.Screen name="TaskCreateEdit" component={TaskCreateEditScreen} />
    <Stack.Screen name="VisitsEdit" component={VisitsEditScreen} />
    <Stack.Screen name="VisitsCreate" component={VisitsCreateScreen} />
    <Stack.Screen name="AdditiveCreate" component={AdditiveCreateScreen} />
    <Stack.Screen name="AdditiveEdit" component={AdditiveEditScreen} />
    <Stack.Screen name="AppliedFeedDesignEdit" component={AppliedFeedDesignEditScreen} />
    <Stack.Screen name="AppliedFeedDesignCreateEdit" component={AppliedFeedDesignCreateEditScreen} />
    <Stack.Screen name="AdminUserCreate" component={AdminUserCreateScreen} />
    <Stack.Screen name="AdminGroupCreateEdit" component={AdminGroupCreateEditScreen} />
    <Stack.Screen name="Map" component={MapScreen} />
    <Stack.Screen name="Select" component={SelectScreen} />
    <Stack.Screen name="MultiSelect" component={MultiSelectScreen} />
    <Stack.Screen name="Calendar" component={CalendarScreen} />
    <Stack.Screen
      name="ActivityDashboard"
      component={ActivityDashboardScreen}
      options={{
        title: '活動量ダッシュボード',
      }}
    />
    <Stack.Screen
      name="MilkAmoutDashboard"
      component={MilkAmoutDashboardScreen}
      options={{
        title: '搾乳量ダッシュボード',
      }}
    />
    <Stack.Screen
      name="ActivityGraphs"
      component={ActivityGraphsScreen}
      options={{
        title: '活動量グラフ',
      }}
    />
    <Stack.Screen
      name="MilkAmountGraphs"
      component={MilkAmountGraphsScreen}
      options={{
        title: '搾乳量グラフ',
      }}
    />
    <Stack.Screen
      name="UmotionSwitchFarmModal"
      component={UmotionSwitchFarmScreen}
      options={{
        title: '牧場切り替え',
      }}
    />
  </>
);

export const commonScreens = (
  <>
    <Stack.Screen
      name="UserSettings"
      component={UserSettingsScreen}
      options={{
        title: 'ユーザー設定',
      }}
    />
    <Stack.Screen
      name="AboutUs"
      component={AboutUsScreen}
      options={{
        title: '運営会社',
      }}
    />
    <Stack.Screen
      name="AdminFarmList"
      component={AdminFarmListScreen}
      options={{
        title: '牧場',
      }}
    />
    <Stack.Screen
      name="AdminFarmDetail"
      component={AdminFarmDetailScreen}
      options={{
        title: '牧場詳細',
      }}
    />
    <Stack.Screen
      name="FarmList"
      component={FarmListScreen}
      options={{
        title: '牧場',
      }}
    />
    <Stack.Screen
      name="FarmDetail"
      component={FarmDetailScreen}
      options={{
        title: '牧場詳細',
      }}
    />
    <Stack.Screen
      name="FeedDesignList"
      component={FeedDesignListScreen}
      options={{
        title: '飼料設計一覧',
      }}
    />
    <Stack.Screen
      name="FeedDesignDetail"
      component={FeedDesignDetailScreen}
      options={{
        title: '飼料設計',
      }}
    />
    <Stack.Screen
      name="GroupList"
      component={GroupListScreen}
      options={{
        title: 'グループ',
      }}
    />
    <Stack.Screen
      name="GroupDetail"
      component={GroupDetailScreen}
      options={{
        title: 'グループ詳細',
      }}
    />
    <Stack.Screen
      name="GroupCreate"
      component={GroupCreateScreen}
      options={{
        title: 'グループ追加',
      }}
    />
    <Stack.Screen
      name="GroupFeedDesignList"
      component={GroupFeedDesignListScreen}
      options={{
        title: 'グループ飼料設計一覧',
      }}
    />
    <Stack.Screen
      name="GroupFeedDesignCreate"
      component={GroupFeedDesignCreateScreen}
      options={{
        title: 'グループ飼料設計一覧',
      }}
    />
    <Stack.Screen
      name="GroupTaskList"
      component={GroupTaskListScreen}
      options={{
        title: 'タスク',
      }}
    />
    <Stack.Screen
      name="TaskList"
      component={TaskListScreen}
      options={{
        title: 'タスク',
      }}
    />
    <Stack.Screen
      name="TaskDetail"
      component={TaskDetailScreen}
      options={{
        title: 'タスク詳細',
      }}
    />
    <Stack.Screen
      name="EventList"
      component={EventListScreen}
      options={{
        title: 'イベント',
      }}
    />
    <Stack.Screen
      name="VisitsList"
      component={VisitsListScreen}
      options={{
        title: '訪問履歴',
      }}
    />
    <Stack.Screen
      name="VisitsDetail"
      component={VisitsDetailScreen}
      options={{
        title: '訪問履歴 詳細',
      }}
    />
    <Stack.Screen
      name="AdditiveList"
      component={AdditiveListScreen}
      options={{
        title: '添加剤追加 一覧',
      }}
    />
    <Stack.Screen
      name="AdditiveDetail"
      component={AdditiveDetailScreen}
      options={{
        title: '添加剤追加詳細',
      }}
    />
    <Stack.Screen
      name="AppliedFeedDesignList"
      component={AppliedFeedDesignListScreen}
      options={{
        title: '飼料設計適用 一覧',
      }}
    />
    <Stack.Screen
      name="AppliedFeedDesignDetail"
      component={AppliedFeedDesignDetailScreen}
      options={{
        title: '飼料設計適用 詳細',
      }}
    />
    <Stack.Screen
      name="AdminAccountDetail"
      component={AdminAccountDetailScreen}
      options={{
        title: '企業管理詳細画面',
      }}
    />
    <Stack.Screen
      name="AdminAccountEdit"
      component={AdminAccountEditScreen}
      options={{
        title: '企業管理編集画面',
      }}
    />
    <Stack.Screen
      name="AdminUserList"
      component={AdminUserListScreen}
      options={{
        title: '企業ユーザー一覧',
      }}
    />
    <Stack.Screen
      name="AdminUserDetail"
      component={AdminUserDetailScreen}
      options={{
        title: '企業ユーザー詳細',
      }}
    />
    <Stack.Screen
      name="AdminUserEdit"
      component={AdminUserEditScreen}
      options={{
        title: '企業ユーザー編集',
      }}
    />
    <Stack.Screen
      name="AdminGroupList"
      component={AdminGroupListScreen}
      options={{
        title: '管理者用グループ一覧',
      }}
    />
    <Stack.Screen
      name="AdminGroupDetail"
      component={AdminGroupDetailScreen}
      options={{
        title: '管理者用グループ詳細',
      }}
    />
    <Stack.Screen
      name="AdminGroupUserList"
      component={AdminGroupUserListScreen}
      options={{
        title: '管理者用グループ・メンバー一覧',
      }}
    />
    <Stack.Screen
      name="AdminGroupUserDetail"
      component={AdminGroupUserDetailScreen}
      options={{
        title: '管理者用グループ・メンバー詳細',
      }}
    />
    <Stack.Screen
      name="GroupUserDetail"
      component={GroupUserDetailScreen}
      options={{
        title: 'グループ・メンバー詳細',
      }}
    />
    <Stack.Screen name="AboutThisApp" component={AboutThisAppScreen} />
    <Stack.Screen
      name="Help"
      component={HelpScreen}
      options={{
        title: '使い方',
      }}
    />
    <Stack.Screen
      name="NetworkLog"
      component={NetworkLogger}
      options={{
        title: 'ネットワーク・ログ',
      }}
    />
    <Stack.Screen name="AdminExternalGroupUserInvite" component={AdminExternalGroupUserInviteScreen} />
    <Stack.Screen
      name="MyAppointmentServiceList"
      component={MyAppointmentServiceListScreen}
      options={{
        title: '提供サービス一覧',
      }}
    />
    <Stack.Screen
      name="MyAppointmentServiceDetail"
      component={MyAppointmentServiceDetailScreen}
      options={{
        title: '提供サービス詳細',
      }}
    />
    <Stack.Screen
      name="MyAppointmentServiceCreateEdit"
      component={MyAppointmentServiceCreateEditScreen}
      options={{
        title: 'サービス作成',
      }}
    />
    <Stack.Screen
      name="MyAppointmentList"
      component={MyAppointmentListScreen}
      options={{
        title: '予約済み提供サービス',
      }}
    />
    <Stack.Screen
      name="MyAppointmentDetail"
      component={MyAppointmentDetailScreen}
      options={{
        title: '予約済み提供サービス',
      }}
    />
    <Stack.Screen
      name="AppointmentScheduleApply"
      component={AppointmentScheduleApplyScreen}
      options={{
        title: 'サービス申し込み',
      }}
    />
    <Stack.Screen
      name="AppointmentList"
      component={AppointmentListScreen}
      options={{
        title: '予約済みスケジュール',
      }}
    />
    <Stack.Screen
      name="AppointmentServiceList"
      component={AppointmentServiceListScreen}
      options={{
        title: '利用可能なサービス',
      }}
    />
    <Stack.Screen
      name="AppointmentServiceDetail"
      component={AppointmentServiceDetailScreen}
      options={{
        title: 'サービス',
      }}
    />
    <Stack.Screen
      name="MyAppointmentScheduleList"
      component={MyAppointmentScheduleListScreen}
      options={{
        title: 'サービス提供スケジュール',
      }}
    />
  </>
);

export const HomeNavigator = () => {
  return (
    <>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <Stack.Navigator>
        {/* @ts-expect-error */}
        <Stack.Group
          screenOptions={({ navigation }) => ({
            headerBackTitle: '戻る',
            headerTitleStyle: {
              fontWeight: 'bold',
            },
            headerShown: true,
            headerStyle:
              Platform.OS === 'web'
                ? {
                  borderBottomWidth: 0,
                }
                : {},
            headerLeft: () => {
              return renderHeaderLeft(navigation);
            },
          })}
        >
          <Stack.Screen
            name="Home"
            component={GroupSummaryScreen}
            options={{
              title: 'ホーム',
              headerShown: Platform.OS !== 'web',
            }}
          />
          {commonScreens}
        </Stack.Group>
        <Stack.Group
          screenOptions={{
            presentation: 'modal',
            headerBackTitle: '閉じる',
            headerShown: Platform.OS !== 'web',
          }}
        >
          {commonModalScreens}
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

export const BookmarkStackNavigator = () => {
  return (
    <>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <ScreenWidthAdjuster>
        {/* @ts-expect-error */}
        <Stack.Navigator
          screenOptions={({ navigation }) => ({
            headerLeft: () => {
              return renderHeaderLeft(navigation);
            },
            title: 'ブックマーク',
            headerTitleStyle: {
              fontWeight: 'bold',
            },
            headerStyle:
              Platform.OS === 'web'
                ? {
                  borderBottomWidth: 0,
                }
                : {},
          })}
        >
          <Stack.Group>
            <Stack.Screen
              name="ChatBookmarkList"
              component={ChatBookmarkListScreen}
              options={{
                title: 'ブックマーク一覧',
                headerShown: Platform.OS !== 'web',
              }}
            />
            <Stack.Screen
              name="ChatChannelMessages"
              component={ChatChannelMessagesScreen}
              options={{
                title: 'チャット・チャンネル画面',
              }}
            />
            <Stack.Screen
              name="ChatThreadMessages"
              component={ChatThreadMessagesScreen}
              options={{
                title: 'チャット・スレッド画面',
              }}
            />
            {commonScreens}
          </Stack.Group>
          <Stack.Group screenOptions={{ presentation: 'modal', headerBackTitle: '閉じる' }}>
            {commonModalScreens}
          </Stack.Group>
        </Stack.Navigator>
      </ScreenWidthAdjuster>
    </>
  );
};

export const ChatStackNavigator = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();
  if (!chatClient) {
    return null;
  }

  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <Stack.Navigator
        // @ts-expect-error TS(2322): Type '({ navigation }: { route: RouteProp<ParamLis... Remove this comment to see the full error message
        screenOptions={({ navigation }) => ({
          headerLeft: () => {
            return renderHeaderLeft(navigation);
          },
          title: 'チャット',
          headerShown: Platform.OS !== 'web',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
          headerStyle:
            Platform.OS === 'web'
              ? {
                borderBottomWidth: 0,
              }
              : {},
          ...TransitionPresets.SlideFromRightIOS,
          transitionSpec: {
            open: { config: { duration: 80 } },
            close: { config: { duration: 80 } },
          },
          animation: 'simple_push',
          animationDuration: 80,
        })}
      >
        {chatStackGroup}
      </Stack.Navigator>
    </Chat>
  );
};

export const SearchNavigator = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();

  if (!chatClient) {
    return null;
  }
  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <ScreenWidthAdjuster>
        <Stack.Navigator>
          <Stack.Screen
            name="Search"
            component={SearchScreen}
            options={({ navigation }) => ({
              headerLeft: () => (Platform.OS === 'web' ? null : <HeaderMenuButton navigation={navigation} />),
              title: '検索',
              headerShown: Platform.OS !== 'web',
            })}
          />
          {chatStackGroup}
        </Stack.Navigator>
      </ScreenWidthAdjuster>
    </Chat>
  );
};

export const TaskSummaryNavigator = () => {
  return (
    <>
      {Platform.OS === 'web' ? <AppHeader /> : null}
      <ScreenWidthAdjuster>
        <Stack.Navigator initialRouteName="TaskSummaryScreen">
          {/* @ts-expect-error */}
          <Stack.Group
            screenOptions={({ navigation }) => ({
              headerBackTitle: '戻る',
              headerStyle:
                Platform.OS === 'web'
                  ? {
                    borderBottomWidth: 0,
                  }
                  : {},
              headerTitleStyle: {
                fontWeight: 'bold',
              },
            })}
          >
            <Stack.Screen
              name="MyTask"
              component={TaskSummaryScreen}
              options={({ navigation }) => ({
                title: 'マイタスク',
                headerLeft: () => (Platform.OS === 'web' ? null : <HeaderMenuButton navigation={navigation} />),
                headerShown: Platform.OS !== 'web',
              })}
            />
            <Stack.Screen
              name="ChatChannelMessages"
              component={ChatChannelMessagesScreen}
              options={{
                title: 'チャット・チャンネル画面',
              }}
            />
            <Stack.Screen
              name="ChatThreadMessages"
              component={ChatThreadMessagesScreen}
              options={{
                title: 'チャット・スレッド画面',
              }}
            />
            {commonScreens}
          </Stack.Group>
          <Stack.Group screenOptions={{ presentation: 'modal', headerBackTitle: '閉じる' }}>
            {commonModalScreens}
          </Stack.Group>
        </Stack.Navigator>
      </ScreenWidthAdjuster>
    </>
  );
};

export const BottomTabNavigator: React.FC = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();
  const { shouldShowNotificationBadge } = useNotificationBadge();

  if (!chatClient) {
    return null;
  }

  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      <BottomTab.Navigator
        screenOptions={({ navigation }) => ({
          tabBarStyle: {
            height: Platform.OS === 'ios' ? 98 : 74,
            paddingBottom: Platform.OS === 'ios' ? 40 : 12,
            elevation: 0,
          },
          tabBarItemStyle: {
            marginTop: 8,
            marginBottom: 0,
          },
          headerShown: false,
          lazy: true,
          tabBarHideOnKeyboard: Platform.OS === 'android',
        })}
        initialRouteName="ChatNavigator"
      >
        <BottomTab.Screen
          name="HomeNavigator"
          component={HomeNavigator}
          options={{
            title: 'ホーム',
            tabBarIcon: ({ focused, color, size }) => <Ionicons name="home-outline" color={color} size={size} />,
          }}
        />
        <BottomTab.Screen
          name="ChatNavigator"
          component={ChatStackNavigator}
          options={{
            title: 'チャット',
            tabBarIcon: ({ focused, color, size }) => <Ionicons name="chatbubble-outline" color={color} size={size} />,
          }}
        />

        <BottomTab.Screen
          name="SearchNavigator"
          component={SearchNavigator}
          options={{
            title: '検索',
            tabBarIcon: ({ focused, color, size }) => <Ionicons name="search-outline" color={color} size={size} />,
          }}
        />
        <BottomTab.Screen
          name="NotificationTab"
          component={NotificationsNavigator}
          options={{
            title: '通知',
            tabBarIcon: ({ focused, color, size }) => (
              <Ionicons name="notifications-outline" color={color} size={size} />
            ),
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
            tabBarBadge: shouldShowNotificationBadge ? '' : null,
            tabBarBadgeStyle: {
              top: Platform.OS === 'ios' ? 0 : 9,
              minWidth: 12,
              maxHeight: 12,
              borderRadius: 7,
              fontSize: 10,
              lineHeight: 13,
              alignSelf: undefined,
              backgroundColor: '#FF4949',
            },
          }}
        />
        <BottomTab.Screen
          name="TaskSummary"
          component={TaskSummaryNavigator}
          options={{
            title: 'マイタスク',
            tabBarIcon: ({ focused, color, size }) => <Ionicons name="checkbox-outline" color={color} size={size} />,
          }}
        />
        {ENV_FILE_NAME === '.env.preview' || ENV_FILE_NAME === '.env.development' ? (
          <BottomTab.Screen
            name="Debug"
            component={NetworkLogger}
            options={{
              title: 'デバッグログ',
              tabBarIcon: ({ focused, color, size }) => <Ionicons name="hammer-outline" color={color} size={size} />,
              headerShown: true,
            }}
          />
        ) : null}
      </BottomTab.Navigator>
    </Chat>
  );
};

const chatStackGroup = (
  <>
    {/* @ts-expect-error */}
    <Stack.Group
      screenOptions={({ navigation }) => {
        return Platform.OS === 'web'
          ? {
            headerBackTitle: '戻る',
            headerStyle: { borderBottomWidth: 0 },
          }
          : { headerBackTitle: '戻る' };
      }}
    >
      <Stack.Screen
        name="ChatChannelList"
        // @ts-expect-error TS(2322): Type 'FC<Props>' is not assignable to type 'Screen... Remove this comment to see the full error message
        component={ChatChannelListScreen}
        options={({ navigation }) => ({
          title: 'チャット',
        })}
      />
      <Stack.Screen
        name="ChatChannelMessages"
        component={ChatChannelMessagesScreen}
        options={() => ({
          title: 'チャット・チャンネル画面',
        })}
      />
      <Stack.Screen
        name="ChatThreadMessages"
        component={ChatThreadMessagesScreen}
        options={() => ({
          title: 'チャット・スレッド画面',
        })}
      />
      <Stack.Screen
        name="ChatMemberList"
        component={ChatMemberListScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen name="ChatChannelOverviewEdit" component={ChatChannelOverviewEditScreen} />
      {Platform.OS === 'web' && (
        <Stack.Screen
          name="ChatMultiColumnsView"
          component={ChatMultiColumnsViewScreen}
          options={{
            headerShown: false,
          }}
        />
      )}
      {Platform.OS !== 'web' && (
        <>
          <Stack.Screen
            name="ChatBookmarkList"
            component={ChatBookmarkListScreen}
            options={{
              title: 'ブックマーク一覧',
            }}
          />
          <Stack.Screen
            name="DirectMessageList"
            component={ChatDMListScreen}
            options={{
              title: 'ダイレクト・メッセージ',
              headerBackTitle: '戻る',
            }}
          />
        </>
      )}
      {commonScreens}
    </Stack.Group>
    <Stack.Group
      screenOptions={({ navigation }) => ({
        presentation: 'modal',
        headerLeft: () => {
          return (
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text color="blue.500" bold fontSize="lg">
                閉じる
              </Text>
            </TouchableOpacity>
          );
        },
      })}
    >
      {commonModalScreens}
      <Stack.Screen
        name="ChatChannelCreateEdit"
        component={ChatChannelCreateEditScreen}
        options={(_) => ({
          title: 'チャンネル',
          presentation: 'modal',
        })}
      />
      <Stack.Screen
        name="ChatDMCreateEdit"
        component={ChatDMCreateEditScreen}
        options={{
          title: 'ダイレクト・メッセージ',
        }}
      />
    </Stack.Group>
  </>
);
