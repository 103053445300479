import '~/css-loader';
import 'wdyr';
import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { ENV_FILE_NAME } from '@env';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { useFonts, BIZUDPGothic_400Regular, BIZUDPGothic_700Bold } from '@expo-google-fonts/biz-udpgothic';
import { PortalProvider, PortalHost } from '@gorhom/portal';
import {
  NavigationContainer,
  DefaultTheme as NavigationDefaultTheme,
  DarkTheme as NavigationDarkTheme,
} from '@react-navigation/native';
import { NativeBaseProvider } from 'native-base';
import * as React from 'react';
import { Platform, useColorScheme } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { startNetworkLogging } from 'react-native-network-logger';
import { SafeAreaProvider } from 'react-native-safe-area-context';
// @ts-expect-error TS(2305): Module '"react-native-screens"' has no exported member 'enableFreeze'.
import { enableFreeze } from 'react-native-screens';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';
import { useStreamChatTheme } from '~/hooks/useStreamChatTheme';
import { analytics } from '~/lib/ReactNativeFirebase';
import { Routes } from '~/routes';
import { linking } from '~/routes/linking';
import { settingsSelector } from '~/slices/settingsSlice';
import { store, persistor, useAppSelector } from '~/store';
import { getTheme } from '~/theme';
import { isRunningInExpoGo } from '~/utils/isRunningInExpoGo';

import '~/calendarLocaleConfig';
enableFreeze(true);

if (ENV_FILE_NAME === '.env.preview' || ENV_FILE_NAME === '.env.development') {
  startNetworkLogging({ ignoredHosts: ['clients3.google.com'] });
} else {
  Sentry.init({
    dsn: 'https://b47947a879994c57a0384cacdc8d17a3@o605768.ingest.sentry.io/4503987867746304',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });
}

function App() {
  const streamChatTheme = useStreamChatTheme();
  const colorMode = useColorScheme();
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const settings = useAppSelector(settingsSelector);
  const [fontsLoaded] = useFonts({
    BIZUDPGothic_400Regular,
    BIZUDPGothic_700Bold,
  });

  const theme = React.useMemo(() => {
    return getTheme(fontsLoaded && !!settings.enableUDFont);
  }, [fontsLoaded, settings.enableUDFont]);

  if (Platform.OS === 'web') {
    // http://localhost/chat/channel-id/message-id に対してWebとアプリで表示するscreenを振り分ける
    linking.config.screens.Chat = {
      screens: {
        // @ts-expect-error TS(2322) Type '{ ChatChannelList: string; ... }' is not assignable to type... Remove this comment to see the full error message
        ChatChannelList: 'chat/:channelId?/:messageId?',
        ChatMemberList: 'chat/memberlist/:channelId',
        ChatChannelOverviewEdit: 'chat/channel/:channelId/edit',
        ChatMultiColumnsView: 'multi',
        ChatChannelCreateEdit: 'chat/channel/create',
        ActivityDashboard: 'farms/:farmId/groups/:groupId/dashboards/activity/:year/:month/:date',
        ActivityGraphs: 'farms/:farmId/groups/:groupId/graphs/activity/:year/:month/:date',
        MilkAmoutDashboard: 'farms/:farmId/groups/:groupId/dashboards/milk_amount/:year/:month/:date',
        MilkAmountGraphs: 'farms/:farmId/groups/:groupId/graphs/milk_amount/:year/:month/:date',
      },
    };
  } else {
    linking.config.screens.ChatChannelMessages = 'chat/:channelId?/:messageId?';
  }

  useDidUpdate(() => {
    // @ts-expect-error TS(2367): This comparison appears to be unintentional because the types '".env.preview"' and '".env.development"' have no overlap.
    if (settings.enableNetworkLog || ENV_FILE_NAME !== '.env.preview' || ENV_FILE_NAME === '.env.development') {
      startNetworkLogging({ ignoredHosts: ['clients3.google.com'] });
    }
  }, [settings]);

  useDidUpdate(() => {
    if (Platform.OS === 'web') {
      if (fontsLoaded && settings.enableUDFont) {
        document.documentElement.style.setProperty('--str-root__font-family', 'BIZUDPGothic_400Regular');
      } else {
        document.documentElement.style.setProperty(
          '--str-root__font-family',
          'font-family -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif'
        );
      }
    }
  }, [fontsLoaded, settings.enableUDFont]);

  // @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
  const onStateChange = (state) => {
    const previousRouteName = routeNameRef.current;
    // @ts-expect-error TS(2339): Property 'getCurrentRoute' does not exist on type 'never'.
    const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;

    if (Platform.OS !== 'web') {
      if (previousRouteName !== currentRouteName && !isRunningInExpoGo) {
        // @ts-expect-error TS(2349): This expression is not callable. Type 'Analytics' has no call signatures.
        analytics()?.logScreenView({
          screen_name: currentRouteName,
          screen_class: currentRouteName,
        });
      }
    }

    routeNameRef.current = currentRouteName;
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ActionSheetProvider>
        <SafeAreaProvider>
          <NavigationContainer
            theme={colorMode === 'light' || true ? NavigationDefaultTheme : NavigationDarkTheme}
            // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignable to type... Remove this comment to see the full error message
            ref={navigationRef}
            // @ts-expect-error TS(2339): Property 'getCurrentRoute' does not exist on type 'never'.
            onReady={() => (routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name)}
            onStateChange={onStateChange}
            // @ts-expect-error TS(2741): Property 'prefixes' is missing in type... Remove this comment to see the full error message
            linking={linking}
          >
            <NativeBaseProvider theme={theme}>
              <PortalProvider>
                <PortalHost name="EmojiPortalHost" />
                {/* <ModalProvider> */}
                <Routes />
                {/* </ModalProvider> */}
              </PortalProvider>
            </NativeBaseProvider>
          </NavigationContainer>
        </SafeAreaProvider>
      </ActionSheetProvider>
    </GestureHandlerRootView>
  );
}

export default function ReduxApp() {
  // redux-persist の初期化ロード中はアプリをレンダリングしない

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>
  );
}
