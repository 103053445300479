import { Ionicons } from '@expo/vector-icons';
import { useIsFocused, useLinkTo, useNavigation } from '@react-navigation/native';
import { Icon, Fab, Actionsheet } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

interface Props {
  queryParams: { groupId: number; farmId: number };
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const HomeActionFab: React.FC<Props & React.PropsWithChildren> = ({ queryParams, isOpen, onOpen, onClose }) => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const linkTo = useLinkTo();
  return (
    isFocused && (
      <>
        <Fab
          position={Platform.OS === 'web' ? 'fixed' : 'absolute'}
          placement="bottom-right"
          bottom={5}
          right={5}
          size="lg"
          backgroundColor="#0EC9E5"
          icon={<Icon as={Ionicons} name="add-outline" backgroundColor="#0EC9E5" size="md" />}
          onPress={onOpen}
          renderInPortal={Platform.OS === 'web'}
        />
        <Actionsheet isOpen={isOpen} onClose={onClose}>
          <Actionsheet.Content>
            <Actionsheet.Item
              onPress={() => {
                Platform.OS === 'web'
                  ? linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/additives/create`)
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('AdditiveCreate', queryParams);
                onClose();
              }}
            >
              添加剤追加
            </Actionsheet.Item>
            <Actionsheet.Item
              onPress={() => {
                Platform.OS === 'web'
                  ? linkTo(
                      `/farms/${queryParams.farmId}/groups/${queryParams.groupId}/applied_feed_designs/${undefined}/create_edit`
                    )
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('AppliedFeedDesignCreateEdit', queryParams);
                onClose();
              }}
            >
              飼料設計適用
            </Actionsheet.Item>
            <Actionsheet.Item
              onPress={() => {
                Platform.OS === 'web'
                  ? linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/visits/create`)
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('VisitsCreate', queryParams);
                onClose();
              }}
            >
              訪問履歴
            </Actionsheet.Item>
            <Actionsheet.Item
              onPress={() => {
                Platform.OS === 'web'
                  ? linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${undefined}/create_edit`)
                  : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('TaskCreateEdit', queryParams);
                onClose();
              }}
            >
              タスク
            </Actionsheet.Item>
            <Actionsheet.Item onPress={onClose}>キャンセル</Actionsheet.Item>
          </Actionsheet.Content>
        </Actionsheet>
      </>
    )
  );
};
