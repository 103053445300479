import { useNavigation } from '@react-navigation/native';
import { ArrowBackIcon, Box, Center, HStack, Heading, Pressable } from 'native-base';
import { Modal, Platform } from 'react-native';

import { HeaderRight } from './HeaderRight';

type Props = {
  title?: string;
  canBack?: boolean;
  onHeaderRightPress?: () => void;
  headerRightLabel?: string;
};

export const ScreenWidthModal: React.FC<Props & React.PropsWithChildren> = ({
  title,
  canBack = true,
  children,
  onHeaderRightPress,
  headerRightLabel,
}) => {
  const navigation = useNavigation();

  if (Platform.OS !== 'web') {
    return children;
  }

  return (
    <Modal animationType="none" transparent onRequestClose={navigation.goBack}>
      <Center flex={1} position="relative" overflow="hidden" bg="rgba(0, 0, 0, .5)" py={50}>
        <Pressable onPress={navigation.goBack} position="absolute" top={0} left={0} w="full" h="full" />
        <Box w="full" h="full" maxW={800 + 48 * 2} borderRadius={10} backgroundColor="white" overflow="hidden">
          {title && (
            <HStack alignItems="center" justifyContent="space-between" px="40px" w="full" py="20px" position="relative">
              {canBack ? (
                <Pressable onPress={navigation.goBack} p={2} w={68}>
                  <ArrowBackIcon />
                </Pressable>
              ) : (
                <Box w={68} />
              )}
              <Heading flex={1} fontSize="xl" textAlign="center">
                {title}
              </Heading>
              {!!onHeaderRightPress && !!headerRightLabel ? (
                <HeaderRight label={headerRightLabel} onPress={onHeaderRightPress} />
              ) : (
                <Box w={68} />
              )}
            </HStack>
          )}
          {children}
        </Box>
      </Center>
    </Modal>
  );
};
