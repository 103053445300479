import { useNavigation, useRoute } from '@react-navigation/native';
import { Button, Divider, VStack, Spinner } from 'native-base';
import * as React from 'react';

import { useDeleteAppointmentsServicesByIdMutation } from '~/api/uFeedApi';
import { AppointmentServiceDetail } from '~/components/AppointmentServiceDetail';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const MyAppointmentServiceDetailScreen: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const [deleteService, deleteServiceStatus] = useDeleteAppointmentsServicesByIdMutation();

  const onDeletePress = () => {
    deleteService({
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
      id: route?.params?.id,
    })
      .unwrap()
      .then(() => navigation.goBack())
      .catch((error) => console.error('deleting appointment service by id failed', error));
  };

  const onEditPress = () => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate('MyAppointmentServiceCreateEdit', {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'object'.
      id: route?.params?.id,
    });
  };

  return (
    <BaseScreenBuilder>
      <VStack divider={<Divider />} space={8}>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <AppointmentServiceDetail id={route?.params.id} />
        <VStack space={4}>
          <Button onPress={onEditPress} variant="outline">
            編集
          </Button>

          {deleteServiceStatus.isLoading ? (
            <Button colorScheme="danger" disabled leftIcon={<Spinner />}>
              サービスを削除中
            </Button>
          ) : (
            <Button colorScheme="danger" onPress={onDeletePress}>
              サービスを削除する
            </Button>
          )}
        </VStack>
      </VStack>
    </BaseScreenBuilder>
  );
};
