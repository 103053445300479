import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, View, StyledProps, useColorModeValue, Alert, VStack, HStack, Text, Skeleton } from 'native-base';
import * as React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { ScreenWidthAdjuster } from '~/components';
import { HeaderRight } from '~/components/HeaderRight';

interface Props {
  title?: string;
  headerRightLabel?: string;
  onHeaderRightPress?: () => void;
  headerLeftLabel?: string;
  onHeaderLeftPress?: () => void;
  noScroll?: boolean;
  padding0?: boolean;
  stickyHeaderIndices?: number[];
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isEmpty?: boolean;
  emptyMessage?: string;
}

export const BaseScreenBuilder: React.FC<Props & StyledProps & React.PropsWithChildren> = ({
  headerRightLabel,
  onHeaderRightPress,
  headerLeftLabel,
  onHeaderLeftPress,
  children,
  title,
  noScroll = false,
  padding0 = false,
  stickyHeaderIndices,
  isLoading,
  isError,
  errorMessage,
  isEmpty,
  emptyMessage,
  ...props
}) => {
  const navigation = useNavigation();

  useDidUpdate(
    () => {
      if (title) {
        navigation?.setOptions?.({
          title,
        });
      }

      if (headerRightLabel && onHeaderRightPress) {
        navigation?.setOptions({
          headerRight: () => <HeaderRight label={headerRightLabel} onPress={onHeaderRightPress} />,
        });
      }

      if (headerLeftLabel && onHeaderLeftPress) {
        navigation?.setOptions({
          headerLeft: () => <HeaderRight label={headerLeftLabel} onPress={onHeaderLeftPress} />,
        });
      }
    },
    [title, headerRightLabel, headerLeftLabel, onHeaderRightPress, onHeaderLeftPress],
    true
  );

  const backgroundColor = useColorModeValue('white', 'rgb(18, 18, 18)');
  const padding = padding0 ? 0 : 4;

  if (isLoading) {
    return (
      <ScreenWidthAdjuster>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </ScreenWidthAdjuster>
    );
  }

  if (isError) {
    return (
      <ScreenWidthAdjuster>
        <View padding={5} backgroundColor="white" flex={1}>
          <Alert status="warning" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="flex-start" space={2} flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {errorMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </View>
      </ScreenWidthAdjuster>
    );
  }

  if (isEmpty) {
    return (
      <ScreenWidthAdjuster>
        <View padding={5} backgroundColor="white" flex={1}>
          <Alert status="info" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="flex-start" space={2} flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {emptyMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </View>
      </ScreenWidthAdjuster>
    );
  }

  return noScroll ? (
    <View paddingTop={padding} paddingBottom={0} paddingX={padding} backgroundColor={backgroundColor} {...props}>
      {children}
    </View>
  ) : (
    <ScreenWidthAdjuster>
      <KeyboardAwareScrollView extraScrollHeight={80} style={{ backgroundColor }} keyboardShouldPersistTaps="handled">
        <ScrollView
          //_contentContainerStyle={{ flexGrow: 0 }}
          padding={padding}
          backgroundColor={backgroundColor}
          {...props}
          keyboardShouldPersistTaps="handled"
          nestedScrollEnabled
        >
          <View marginBottom={30}>{children}</View>
        </ScrollView>
      </KeyboardAwareScrollView>
    </ScreenWidthAdjuster>
  );
};
