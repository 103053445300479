import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { isEqual } from '@react-hookz/deep-equal';
import { useFocusEffect } from '@react-navigation/native';
import { Box, Center, useColorModeValue, StatusBar, Skeleton, VStack, useDisclose } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';

import { GroupDetailScreen } from '../GroupDetailScreen';

import { useGetCurrentUserGroupsQuery, Group } from '~/api/uFeedApi';
import { HomeActionFab } from '~/components/HomeActionFab';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';

const initialLayout = {
  width: Dimensions.get('window').width,
};

const windowHeight = Dimensions.get('window').height;

interface Props {
  groups: Group[];
}

export const GroupSummaryScreen: React.FC = () => {
  const groupsQuery = useGetCurrentUserGroupsQuery();
  const [groups, setGroups] = useState([]);

  const backgroundColor = useColorModeValue('white', 'rgb(18, 18, 18)');

  useFocusEffect(
    React.useCallback(() => {
      groupsQuery.refetch().catch((error) => console.error('groupsQuery.refetch() failed; ', error));
    }, [])
  );

  useDidUpdate(
    () => {
      if (
        groupsQuery.data &&
        !isEqual(
          groupsQuery.data?.map((g) => g.id),
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          groups.map((g) => g.id)
        )
      ) {
        // @ts-expect-error TS(2345): Argument of type 'GetCurrentUserGroupsApiResponse'... Remove this comment to see the full error message
        setGroups(groupsQuery.data);
      }
    },
    [groupsQuery.data],
    true
  );

  return groupsQuery.isLoading ? (
    <Box height={windowHeight} backgroundColor={backgroundColor}>
      <VStack space={6} padding={4} flex={1}>
        {[...new Array(10)].map((item, index) => (
          <Skeleton.Text key={index.toString()} />
        ))}
      </VStack>
    </Box>
  ) : (
    <GroupSummaryScreenInner groups={groups} />
  );
};

const GroupSummaryScreenInner: React.FC<Props> = React.memo(({ groups }) => {
  const { isOpen, onOpen, onClose } = useDisclose();
  const { width } = useWindowDimensions();
  const settings = useAppSelector(settingsSelector);
  const [index, setIndex] = React.useState(0);
  const [routes, setRoutes] = React.useState<
    [
      {
        key: string;
        title: string;
      }?,
    ]
  >([]);

  const [queryParams, setQueryParams] = useState({});

  useDidUpdate(() => {
    const group = groups ? groups.find((group) => group.id === routes[index]?.key) : undefined;
    // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
    setQueryParams({ groupId: group?.id, farmId: group?.farm?.id });
  }, [index, routes, groups]);

  useDidUpdate(
    () => {
      if (groups) {
        const newRoute = [];

        newRoute.push(
          ...[...groups].sort(sortByFarmName).map((group) => ({
            key: group.id,
            // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
            title: `${group.farm?.name}: ${group.name}`,
          }))
        );

        // @ts-expect-error TS(2345): Argument of type '{ key: string; title: string; }[... Remove this comment to see the full error message
        setRoutes(newRoute);
      }
    },
    [groups],
    true
  );

  const renderScene = React.useCallback(
    (props: any) => {
      const { route } = props;

      const group = groups ? groups.find((group) => group.id === route.key) : undefined;
      if (!group) return null;

      return (
        <GroupDetailScreen
          groupId={route.key}
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          farmId={group.farm?.id}
          showTitle={false}
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          selected={route.key === routes[index].key}
        />
      );
    },
    [groups, routes, index, width]
  );

  const renderTabBar = React.useCallback(
    (props: any) => {
      return (
        <Center px={4}>
          <Box width="full" maxW={1440} backgroundColor="white">
            <TabBar
              {...props}
              index={index}
              setIndex={setIndex}
              indicatorStyle={{ backgroundColor: '#0EC9E5' }}
              style={{ backgroundColor: 'white', color: 'black' }}
              activeColor="black"
              inactiveColor="#bbb"
              scrollEnabled
              gap={8}
              tabStyle={{
                width: 'auto',
                paddingHorizontal: 0,
              }}
            />
          </Box>
        </Center>
      );
    },
    [index]
  );

  const sortByFarmName = (a: any, b: any) => {
    const nameA = a.farm?.name.toUpperCase();
    const nameB = b.farm?.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Box flex={1} backgroundColor={useColorModeValue('white', 'rgb(18, 18, 18)')}>
        {routes.length > 1 ? (
          <TabView
            navigationState={{
              index,
              // @ts-expect-error TS(2322): Type '[({ key: string; title: string; } | undefine... Remove this comment to see the full error message
              routes,
            }}
            onIndexChange={setIndex}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            initialLayout={initialLayout}
            style={{
              // @ts-expect-error TS(2339): Property 'currentHeight' does not exist on type 'F... Remove this comment to see the full error message
              marginTop: StatusBar.currentHeight,
            }}
            lazy
          />
        ) : routes.length === 1 ? (
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          <GroupDetailScreen farmId={groups[0].farm?.id} groupId={groups[0].id} renderFab={false} />
        ) : null}
      </Box>
      {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
      <HomeActionFab queryParams={queryParams} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
});
