import { U_FEED_URL_BASE } from '@env';
import getUrls from 'get-urls';
import { StreamMessage } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { generateNavigateParameterFromPath } from '~/utils/generateNavigateParameterFromPath';
import { OpenInUmotionButton } from './OpenInUmotionButton';
import { PreviewActivityChart } from './PreviewActivityChart';
import { PreviewActivityDashboard } from './PreviewActivityDashboard';
import { PreviewAdditive } from './PreviewAdditive';
import { PreviewAppliedFeedDesign } from './PreviewAppliedFeedDesign';
import { PreviewCattleParams } from './PreviewCattleParams';
import { PreviewEventSuggestion } from './PreviewEventSuggestion';
import { PreviewMilkAmountGraphs } from './PreviewMilkAmountGraphs';
import { PreviewMilkAmoutDashboard } from './PreviewMilkAmoutDashboard';
import { PreviewReminder } from './PreviewReminder';
import { PreviewTask } from './PreviewTask';
import { PreviewVisit } from './PreviewVisit';

interface Props {
  message: StreamMessage<DefaultStreamChatGenerics>;
}
export const Previews: React.FC<Props> = ({ message }) => {
  const urls = getUrls(message?.text?.replaceAll('\\', '') || '') || [];

  if (message.um_event_params) {
    return (
      <PreviewEventSuggestion
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        key={`preview-event-suggestion-${message.um_event_params.id}`}
        eventSuggestion={message.um_event_params}
      />
    );
  }

  if (message.um_cattle_params) {
    return <PreviewCattleParams key={`preview-cattle-params-${message.id}`} data={message.um_cattle_params} />;
  }
  const previews =
    message?.alert_type !== undefined
      ? // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'string'.
        [<OpenInUmotionButton key={message.id} mobileUrl={message?.cow_url_mobile} pcUrl={message?.cow_url_pc} />]
      : [...urls.keys()].map((url) => {
          if (url.match(U_FEED_URL_BASE)) {
            const parsed = generateNavigateParameterFromPath(url.split(']')[0].replace(U_FEED_URL_BASE, ''));

            // 念の為こちらにもそれぞれ key を付与
            switch (parsed.screenName) {
              case 'AppliedFeedDesignDetail':
                return (
                  <PreviewAppliedFeedDesign
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    key={`preview-applied-feed-design-${parsed.params?.id}`}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    id={parsed.params?.id}
                  />
                );
              case 'TaskDetail':
                return (
                  <PreviewTask
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    key={`preview-task-${parsed.params?.id}`}
                    // @ts-expect-error TS(2322): Type '{ key: string; groupId: any; id: any; }' is ... Remove this comment to see the full error message
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    id={parsed.params?.id}
                  />
                );
              case 'ReminderDetail':
                return (
                  <PreviewReminder
                    // @ts-expect-error TS(2339): Property 'reminderId' does not exist on type '{}'.
                    key={`preview-reminder-${parsed.params?.reminderId}`}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'reminderId' does not exist on type '{}'.
                    reminderId={parsed.params?.reminderId}
                  />
                );
              case 'AdditiveDetail':
                return (
                  <PreviewAdditive
                    // @ts-expect-error TS(2339): Property 'additiveId' does not exist on type '{}'.
                    key={`preview-additive-${parsed.params?.additiveId}`}
                    // @ts-expect-error TS(2322): Type '{ key: string; groupId: any; additiveId: any... Remove this comment to see the full error message
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'additiveId' does not exist on type '{}'.
                    additiveId={parsed.params?.additiveId}
                  />
                );
              case 'VisitsDetail':
                return (
                  <PreviewVisit
                    // @ts-expect-error TS(2339): Property 'visitId' does not exist on type '{}'.
                    key={`preview-visit-${parsed.params?.visitId}`}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'visitsId' does not exist on type '{}'.
                    visitsId={parsed.params?.visitsId}
                  />
                );
              case 'ActivityDashboard':
                return (
                  <PreviewActivityDashboard
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    key={`preview-dashboard-${parsed.params?.farmId}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                  />
                );
              case 'MilkAmoutDashboard':
                return (
                  <PreviewMilkAmoutDashboard
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    key={`preview-milk-amount-dashboard-${parsed.params?.farmId}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                  />
                );
              case 'MilkAmountGraphs':
                return (
                  <PreviewMilkAmountGraphs
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    key={`preview-milk-amount-graphs-${parsed.params?.farmId}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{}'.
                    range={parsed.params?.range}
                    // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                    type={parsed.params?.type}
                  />
                );
              case 'ActivityGraphs':
                return (
                  <PreviewActivityChart
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parseInt(parsed.params?.farmId)}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parseInt(parsed.params?.groupId)}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{}'.
                    range={parsed.params?.range}
                    // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                    type={parsed.params?.type}
                  />
                );
            }
          }
        });

  return previews;
};
