import { memo } from 'react';

import { MessageOptions, useMessageContext } from '~/lib/StreamChatReact';
// @ts-expect-error TS(2724): '"~/lib/StreamChatReact"' has no exported member n... Remove this comment to see the full error message
import type { DefaultStreamChatGenerics, MessageType } from '~/lib/StreamChatReact';

import type { ReactElement } from 'react';

type Props = {
  message: MessageType<DefaultStreamChatGenerics>;
};

const CustomMessageOptionsWithContext = ({ message }: Props): ReactElement | null => {
  return <MessageOptions displayReplies={!message.show_in_channel} />;
};

const MemorizedCustomMessageOptions = memo(
  CustomMessageOptionsWithContext,
  (prev, next) => prev.message.show_in_channel === next.message.show_in_channel
);

const CustomMessageOptions = (): ReactElement | null => {
  const { message } = useMessageContext();
  return <MemorizedCustomMessageOptions message={message} />;
};

export default CustomMessageOptions;
