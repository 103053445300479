import { VStack, StyledProps, Divider } from 'native-base';

import { GetAppointmentsSlotsApiResponse } from '~/api/uFeedApi';
import { AppointmentSlotListItem } from '~/components/AppointmentSlotListItem';

interface Props {
  slots: GetAppointmentsSlotsApiResponse;
}

const checkDivider = (startAt: string, prevStartAt: string) => {
  return startAt?.replace(/:\d\d/, '') !== prevStartAt?.replace(/:\d\d/, '');
};

export const AppointmentSlotList: React.FC<Props & StyledProps> = ({ slots }) => {
  return (
    <VStack flex={1} space={4} paddingY={6} paddingX={2} backgroundColor="white">
      {slots?.map((slot, index, arr) => {
        const shouldShowDivider = index === 0 ? false : checkDivider(slot?.start_at, arr[index - 1]?.start_at);
        return (
          // @ts-expect-error TS(2339): Property 'date' does not exist on type '{ id: numb... Remove this comment to see the full error message
          <VStack space={4} key={`${slot?.date}${slot?.start_at}`}>
            {shouldShowDivider ? <Divider /> : null}
            {/* @ts-expect-error TS(2741): Property 'date' is missing in type '{ id: number; ... Remove this comment to see the full error message */}
            <AppointmentSlotListItem slot={slot} />
          </VStack>
        );
      })}
    </VStack>
  );
};
