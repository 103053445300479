import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import * as Clipboard from 'expo-clipboard';
import React, { useRef } from 'react';
import { CustomMessageActionsListProps } from 'stream-chat-react';

import {
  type Bookmark,
  useGetCurrentUserBookmarksQuery,
  usePostCurrentUserBookmarksMutation,
  useDeleteCurrentUserBookmarksByIdMutation,
} from '~/api/uFeedApi';
import { useStreamChatContext } from '~/contexts/StreamChatContext';

type Props = CustomMessageActionsListProps & {
  isDM?: boolean;
};

export const CustomMessageActionsListWeb: React.FC<Props> = ({ customMessageActions, message, isDM }) => {
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [post] = usePostCurrentUserBookmarksMutation();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef(null);
  const bookmarksList: Bookmark[] = bookmarksRef.current ? bookmarksRef.current : [];
  const bookMarkID = bookmarksList.find((b) => b.message_id === message.id)?.id;
  const { appChannel } = useStreamChatContext();

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        // @ts-expect-error TS(2322): Type 'GetCurrentUserBookmarksApiResponse' is not a... Remove this comment to see the full error message
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data, message],
    true
  );

  const updateBookMark = () => {
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    } else {
      post({
        body: {
          user_bookmark: {
            message_id: message.id,
            bookmark_type: 'chat-message',
          },
        },
      });
    }
  };

  const copyLink = async () => {
    await Clipboard.setStringAsync(`${U_FEED_URL_BASE}/${isDM ? 'dm' : 'chat'}/${appChannel?.id}/${message.id}`);
  };

  const copyText = async () => {
    await Clipboard.setStringAsync(`${message.text}`);
  };

  const buttonClassName = 'str-chat__message-actions-list-item str-chat__message-actions-list-item-button';

  return (
    <>
      <button aria-selected="false" className={buttonClassName} onClick={updateBookMark} role="option">
        {bookMarkID ? 'ブックマーク解除' : 'ブックマークする'}
      </button>
      <button aria-selected="false" className={buttonClassName} onClick={copyLink} role="option">
        リンクをコピー
      </button>
      <button aria-selected="false" className={buttonClassName} onClick={copyText} role="option">
        テキストをコピー
      </button>
    </>
  );
};
