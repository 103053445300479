import { StyledProps, Divider } from 'native-base';
import { useCallback } from 'react';
import { FlatList, View, StyleSheet } from 'react-native';

import { GetAppointmentsSlotsApiResponse } from '~/api/uFeedApi';
import { MyAppointmentSlotListItem } from '~/components/MyAppointmentSlotListItem';

interface Props {
  slots: GetAppointmentsSlotsApiResponse;
}

const checkDivider = (startAt: string, prevStartAt: string) => {
  return startAt?.replace(/:\d\d/, '') !== prevStartAt?.replace(/:\d\d/, '');
};

export const MyAppointmentSlotList: React.FC<Props & StyledProps> = ({ slots, ...styledProps }) => {
  const renderItem = useCallback(
    ({ item, index }: any) => {
      const shouldShowSeparator = index === 0 ? false : checkDivider(item.start_at, slots[index - 1]?.start_at);

      return (
        <View style={styles.itemContainer}>
          {shouldShowSeparator ? <Divider marginBottom={4} /> : null}
          <MyAppointmentSlotListItem slot={item} />
        </View>
      );
    },
    [slots]
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={slots}
        renderItem={renderItem}
        // @ts-expect-error TS(2339): Property 'date' does not exist on type '{ id: numb... Remove this comment to see the full error message
        keyExtractor={(item) => `${item?.date}${item?.start_at}`}
        initialNumToRender={10}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 16,
  },
  itemContainer: {
    paddingVertical: 8,
  },
});
