import { VStack, Text } from 'native-base';
import React from 'react';

import { ActivityLineChart } from '../ActivityLineChart';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { DetailList } from '~/components/builder/DetailList';
import { DetailListItem } from '~/components/builder/DetailListItem';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  farmId: number;
  groupId: number;
  endDate: string;
  range?: number;
  type?: 'line' | 'stack';
}

export const PreviewActivityChart: React.FC<Props> = React.memo(
  ({ farmId, groupId, endDate, range = '30', type = 'line' }) => {
    const group = useGetGroupsByGroupIdQuery({
      groupId,
    });

    if (group.isLoading || group.isError) {
      return null;
    }

    return (
      <VStack
        marginTop={1}
        marginBottom={2}
        space={0}
        backgroundColor="gray.50"
        borderRadius={6}
        borderWidth={1}
        borderColor="gray.100"
        overflow="hidden"
      >
        <DetailList space={2} padding={2}>
          <Text marginBottom={2}>活動量グラフ</Text>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          <DetailListItem title="グループ" value={group.data.name} />
          <DetailListItem
            title="日付"
            value={`${endDate[0]}${endDate[1]}${endDate[2]}${endDate[3]}/${endDate[4]}${endDate[5]}/${endDate[6]}${endDate[7]}`}
          />
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          <DetailListItem title="グループ" value={group.data.name} />
          <DetailListItem title="期間" value={`${range}日`} />
          <DetailListItem
            title="グラフ種別"
            value={ActivityGraphType.find((graphType) => graphType.key == type)?.value}
          />
        </DetailList>
        {type == 'line' ? (
          <ActivityLineChart
            farmId={farmId}
            groupId={groupId}
            endDate={DateUtil.dateHourToDate(endDate)}
            // @ts-expect-error TS(2322): Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
            range={range}
            paddingX={4}
            isPreview
          />
        ) : null}
      </VStack>
    );
  }
);
const ActivityGraphType: { key: 'line' | 'stack'; value: string }[] = [
  { key: 'line', value: '変化量' },
  { key: 'stack', value: '内訳' },
];
