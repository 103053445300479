import { useNavigation } from '@react-navigation/native';
import { Button, VStack } from 'native-base';
import * as React from 'react';

import { useGetAppointmentsServicesQuery } from '~/api/uFeedApi';
import { ListItem, ItemList } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const MyAppointmentServiceListScreen: React.FC = () => {
  const navigation = useNavigation();
  const appointmentsServicesQuery = useGetAppointmentsServicesQuery();

  return (
    <>
      <BaseScreenBuilder
        emptyMessage="提供するサービスが登録されていません。「提供サービスを追加」をタップしてサービスを追加してください。"
        isEmpty={appointmentsServicesQuery.isSuccess && appointmentsServicesQuery.data.length === 0}
      >
        <VStack space={8}>
          <ItemList>
            {appointmentsServicesQuery.data?.map((d) => {
              return (
                <ListItem
                  key={d?.name}
                  title={d?.name}
                  arrow
                  description={d?.description + ` (${d?.provider?.name})`}
                  onPress={() =>
                    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    navigation.navigate('MyAppointmentServiceDetail', {
                      id: d?.id,
                    })
                  }
                />
              );
            })}
          </ItemList>
        </VStack>
      </BaseScreenBuilder>
      <VStack padding={4} backgroundColor="white">
        {/* @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
        <Button onPress={() => navigation.navigate('MyAppointmentServiceCreateEdit', {})}>提供サービスを追加</Button>
      </VStack>
    </>
  );
};
